import React, { FunctionComponent, useEffect, useState } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { setMeta } from './state/MetaSlice';
import { useAppDispatch } from './hooks/reduxHooks';
import { Layout } from './components/Layout';
import { useWidget } from '@rango-dev/widget-embedded';
import { ErrorBoundary } from 'react-error-boundary';
import Error from './pages/Error';
import { BaseRoutes } from './BaseRoutes';

const PageProgressBar: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [progress, setProgress] = useState<boolean>(false);
  const [prevLocation, setPrevLocation] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    setPrevLocation(location.pathname);
    setProgress(true);
    if (location.pathname === prevLocation) {
      setPrevLocation('');
    }
  }, [location]);

  useEffect(() => {
    setProgress(false);
  }, [prevLocation]);

  return <>{children}</>;
};

/**
 * All routes go here.
 * Don't forget to import the components above after adding new route.
 */
export const AppRouter: FunctionComponent = () => {
  const { meta } = useWidget();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setMeta({
        blockchains: meta.blockchains,
        tokens: meta.tokens,
        swappers: meta.swappers,
        loadingStatus: meta.loadingStatus,
      }),
    );
  }, [meta.loadingStatus]);

  return (
    <Router>
      <Layout>
        <PageProgressBar>
          <ErrorBoundary FallbackComponent={Error}>
            <BaseRoutes />
          </ErrorBoundary>
        </PageProgressBar>
      </Layout>
    </Router>
  );
};
