import { CSS, styled } from '@rango-dev/ui';

export const Footer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@sm': {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  alignItems: 'center',
});

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledImage = styled('img', {
  width: '184px',
  paddingBottom: '$25',
  '@sm': {
    padding: '$30 0',
  },
});

export const containerStyles: CSS = {
  padding: '$20',
  '@sm': {
    padding: '$30',
    height: '450px',
    width: '505px',
  },
  '& > div': {
    padding: '0',
  },
};
