import { Networks, WalletType } from '../Constants';
import { WalletBalance } from '../Blockchains';
import { EvmNetworksChainInfo } from '../../state/MetaSlice';
import { Providers } from '@rango-dev/wallets-shared';
import { getEvmProvider } from '@rango-dev/wallets-shared';

export async function changeProviderNetwork(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  ethProvider: any,
  evmNetwork: string | null,
  evmNetworksChainInfo: EvmNetworksChainInfo,
): Promise<void> {
  try {
    if (evmNetwork === Networks.ETHEREUM) {
      await ethProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
      });
    } else if (!!evmNetwork) {
      await ethProvider.request({
        method: 'wallet_addEthereumChain',
        params: [evmNetworksChainInfo[evmNetwork]],
      });
    }
  } catch (switchError) {
    console.log({ switchError });
  }
}

export async function changeEvmWalletNetwork(
  walletType: WalletType,
  evmNetwork: string | null,
  evmNetworksChainInfo: EvmNetworksChainInfo,
  providers: Providers,
): Promise<void> {
  const evmInstance = getEvmProvider(providers, walletType);
  if (evmInstance && evmNetwork) {
    await changeProviderNetwork(evmInstance, evmNetwork, evmNetworksChainInfo);
  }
}

export async function addCoinToEvmWallet(
  walletType: WalletType,
  walletBalance: WalletBalance,
  providers: Providers,
): Promise<void> {
  const evmInstance = getEvmProvider(providers, walletType);
  if (evmInstance && walletBalance) {
    try {
      const wasAdded = await evmInstance.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: walletBalance.address, // The address that the token is at.
            symbol: walletBalance.symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: walletBalance.decimal, // The number of decimals in the token
            image: walletBalance.logo || '', // A string url of the token logo
          },
        },
      });
      console.log(wasAdded);
    } catch (e) {
      console.log(e);
    }
  }
}
