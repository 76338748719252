import { TuneIcon } from '@rango-dev/ui';
import { SettingsPanel } from './Settings.Panel';
import { Popover } from '../Popover';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useState } from 'react';
import { HeaderIcon } from '../common';

export const Settings = () => {
  const { isMobile } = useWindowDimensions();
  const [openModal, setOpenModal] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);

  return !isMobile ? (
    <Popover
      align="end"
      open={openPopover}
      onOpenChange={(open) => setOpenPopover(open)}
      content={<SettingsPanel setOpenPopover={setOpenPopover} />}>
      <HeaderIcon
        id="mobile-header-settings-icon-btn"
        className="relative mr-2 flex h-full w-full cursor-pointer items-center justify-center rounded-full px-2.5">
        <TuneIcon size={24} color="black" />
      </HeaderIcon>
    </Popover>
  ) : (
    <>
      <HeaderIcon
        id="header-settings-icon-btn"
        className="relative mr-2 flex h-full w-full cursor-pointer items-center justify-center rounded-full px-2.5"
        onClick={() => setOpenModal(true)}>
        <TuneIcon size={18} color="black" />
      </HeaderIcon>

      <SettingsPanel isMobile={true} openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
};
