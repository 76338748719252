import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { updateLogosAndUsdPrices } from '../../state/WalletSlice';
import { WalletDetails } from '../WalletDetails';
import type { Wallet } from '../../utils/Constants';
import { getConnectedWalletTypes } from '../../utils/Constants';
import { useWidget } from '@rango-dev/widget-embedded';
import { setAccountBalanceForRequestedWallets } from './ConnectWallet.helpers';
import { WalletButton } from './ConnectWallet.WalletButton';
import { Modal } from '../Modal';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

function getModalStyles(isOpen: boolean) {
  return {
    container: {
      borderBottomLeftRadius: '$0',
      borderTopLeftRadius: '$primary',
      borderBottomRightRadius: '$0',
      borderTopRightRadius: '$primary',
      overflow: 'hidden',
      width: '100%',
      height: '95%',

      '@md': {
        borderRadius: '$primary',
        width: '450px',
        height: 'calc(100% - 20px)',
        marginTop: '$10',
        marginLeft: isOpen ? '-10px' : 0,
        boxShadow: '-10px 10px 30px 0px rgba(0, 0, 0, 0.05)',
      },
    },
    content: {
      padding: '0',
      background: 'transparent',
    },
  };
}

export const ConnectWallet: FunctionComponent = () => {
  const {
    wallets: { refetch, details, totalBalance, isLoading },
  } = useWidget();
  const { isMobile } = useWindowDimensions();

  // TODO: it's better to persist wallet usd value in redux and read it from there
  const wallet = useAppSelector(
    (state) => state.wallet.wallet,
    (prev, next) => {
      // inorder to prevent re-rendering when wallet reference is updated,
      // we compare the string representation of the wallet based on
      // connected blockchains, accounts and walletType
      const walletToString = (wallet: Wallet | null) =>
        wallet?.blockchains
          .map((b) => {
            return (
              b.name + '-' + b.accounts.map((a) => a.address || '' + '-' + a.walletType).join('=')
            );
          })
          .join('#');
      const prevStr = walletToString(prev);
      const nextStr = walletToString(next);
      return prevStr === nextStr;
    },
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useAppDispatch();
  const allTokens = useAppSelector((state) => state.meta.tokens);
  const [connectedWalletsTypes, setConnectedWalletsTypes] = useState<string[]>([]);

  useEffect(() => {
    dispatch(updateLogosAndUsdPrices({ allTokens }));
  }, [allTokens, dispatch, wallet]);

  useEffect(() => {
    const areSetsEqual = (a: Set<string>, b: Set<string>) =>
      a.size === b.size && Array.from(a).every((value) => b.has(value));

    if (!areSetsEqual(getConnectedWalletTypes(wallet), new Set(connectedWalletsTypes))) {
      setConnectedWalletsTypes(Array.from(getConnectedWalletTypes(wallet)));
    }
  }, [wallet, connectedWalletsTypes]);

  const loadWallets = useCallback(() => {
    const accounts =
      wallet?.blockchains?.flatMap((b) => b.accounts.map((a) => ({ name: b.name, account: a }))) ||
      [];

    if (accounts.length > 0) {
      const requestedWallets = Array.from(new Set(accounts.map((acc) => acc.account.walletType)));
      setAccountBalanceForRequestedWallets(
        details,
        wallet,
        connectedWalletsTypes,
        requestedWallets,
        allTokens,
        dispatch,
      );
    }
  }, [allTokens, dispatch, wallet, details]);
  useEffect(loadWallets, [loadWallets, details]);

  const ConnectButtonMemo = useMemo(() => {
    return (
      <WalletButton
        connectedWalletsTypes={connectedWalletsTypes}
        setDrawerOpen={setDrawerOpen}
        sumWalletInUsd={totalBalance}
        isBalanceLoading={isLoading}
      />
    );
  }, [connectedWalletsTypes, totalBalance, isLoading]);

  return (
    <>
      {ConnectButtonMemo}
      <Modal
        anchor={isMobile ? 'bottom' : 'right'}
        open={drawerOpen}
        hasCloseIcon={false}
        header={<></>}
        styles={getModalStyles(drawerOpen)}
        onClose={() => setDrawerOpen(false)}>
        <WalletDetails onClose={() => setDrawerOpen(false)} />
      </Modal>
    </>
  );
};
