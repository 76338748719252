import { darkTheme, styled } from '@rango-dev/ui';

export const MoreTitle = styled('div', {
  display: 'flex',
  cursor: 'pointer',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& ._typography , svg': {
    color: '$neutral700',
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },
});
export const MoreLinks = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  paddingLeft: '$4',
  maxHeight: '0px',
  overflow: 'hidden',
  transition: 'max-height 0.3s ease-in-out',
  variants: {
    open: {
      true: {
        maxHeight: '358px',
      },
    },
  },
});

export const Icon = styled('div', {
  transition: 'transform 0.3s ease-in-out',
  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)',
      },
    },
  },
});
