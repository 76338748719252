import { Divider, Image, ListItem, RankIcon, ScoreIcon } from '@rango-dev/ui';
import { forwardRef, ReactElement, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ItemTitle,
  ListItemValue,
  Score,
  ScoreItemValue,
  ScoreValue,
  StyledSkeleton,
} from './ProgressCard.styles';
import { PropTypes } from './ProgressCard.types';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

function ProgressCardComponent(props: PropTypes, ref: Ref<HTMLDivElement>): ReactElement {
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();

  const profileDetailsTitles = {
    txCount: t('Total Transactions'),
    interactedBlockchains: t('Interacted Chains'),
    totalVolume: t('Total Volume'),
    mostUsedToken: t('Favorite Token'),
    mostUsedBridge: t('Favorite Bridge'),
    mostUsedDex: t('Favorite DEX'),
    mostUsedBlockchain: t('Favorite Chain'),
  };

  return (
    <Container className="progress-card-container" ref={ref}>
      <Score>
        <div className="score-item">
          <ScoreIcon size={isMobile ? 18 : 24} />
          <Divider size={4} direction="vertical" />
          <ItemTitle>{t('Score')}</ItemTitle>
          {props.loading ? (
            <>
              <StyledSkeleton
                variant="rounded"
                width={isMobile ? 44 : 60}
                height={isMobile ? 14 : 30}
              />
              <Divider direction="vertical" size={isMobile ? 16 : 10} />
            </>
          ) : (
            <ScoreValue variant="headline" size={isMobile ? 'small' : 'large'} color="background">
              {props.score ? props.score : t('No Score')}
            </ScoreValue>
          )}
        </div>
        <div className="score-item">
          <RankIcon size={isMobile ? 16 : 24} />
          <Divider size={4} direction="vertical" />
          <ItemTitle>{t('Rank')}</ItemTitle>
          {props.loading ? (
            <>
              <StyledSkeleton
                variant="rounded"
                width={isMobile ? 44 : 60}
                height={isMobile ? 14 : 30}
              />
              <Divider direction="vertical" size={isMobile ? 16 : 10} />
            </>
          ) : (
            <ScoreValue variant="headline" size={isMobile ? 'small' : 'large'} color="background">
              {props.rank ? props.rank : t('No Rank')}
            </ScoreValue>
          )}
        </div>
      </Score>
      <Divider direction="vertical" size="4" />
      {Object.keys(profileDetailsTitles).map((key, index) => {
        const itemKey = key as keyof typeof profileDetailsTitles;
        const title = profileDetailsTitles[itemKey];
        const detail = props.loading ? undefined : props.details[itemKey];

        return (
          <ListItem
            hasDivider={index !== Object.keys(profileDetailsTitles).length - 1}
            key={index}
            start={<ItemTitle>{title}</ItemTitle>}
            end={
              <ListItemValue>
                {props.loading && (
                  <StyledSkeleton
                    variant="rounded"
                    width={isMobile ? 44 : 60}
                    height={isMobile ? 12 : 16}
                  />
                )}
                {!props.loading && (
                  <>
                    {typeof detail === 'object' && detail?.image && (
                      <Image src={detail.image} size={isMobile ? 12 : 16} type="circular" />
                    )}
                    <ScoreItemValue
                      variant={isMobile ? 'label' : 'title'}
                      size={isMobile ? 'medium' : 'medium'}
                      color="background">
                      {typeof detail === 'object' ? detail?.value ?? '----' : detail}
                    </ScoreItemValue>
                  </>
                )}
              </ListItemValue>
            }></ListItem>
        );
      })}
    </Container>
  );
}

export const ProgressCard = forwardRef(ProgressCardComponent);
