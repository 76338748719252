import { ProfileSummary } from '../../../types';
import {
  displayUserRank,
  displayUsdValue,
  displayHashtagContent,
} from '../ProfileSummary/ProfileSummary.helpers';
import { ProgressDataItemType, ProgressDataType } from './ShareProgress.types';

export const getProgressData = (profileSummary: ProfileSummary | null): ProgressDataType => {
  const loading = profileSummary === null;

  if (loading) {
    return { loading: true };
  }

  const items: ProgressDataItemType[] = [
    {
      title: '🎯 Score',
      value: displayUserRank(profileSummary.score),
    },
    {
      title: '🏆 Rank',
      value: displayUserRank(profileSummary.rank),
    },
    {
      title: '💰 Volume',
      value: displayUsdValue(profileSummary.totalVolume),
    },
    {
      title: '🔁 Swaps',
      value: profileSummary.txCount.toString(),
    },
  ];

  if (profileSummary.mostUsedDex) {
    items.push({
      title: '🦄 Favorite DEX',
      value: displayHashtagContent(profileSummary.mostUsedDex.group.toLowerCase()),
    });
  }

  if (profileSummary.mostUsedBridge) {
    items.push({
      title: '🏗️ Favorite Bridge',
      value: displayHashtagContent(profileSummary.mostUsedBridge.group.toLowerCase()),
    });
  }

  if (profileSummary.mostUsedBlockchain) {
    items.push({
      title: '💎 Favorite Chain',
      value: displayHashtagContent(profileSummary.mostUsedBlockchain.shortName),
    });
  }

  return {
    loading: false,
    items,
  };
};

export const getSocialProgressText = (progressData: ProgressDataType): string => {
  let socialText = '';
  if (!progressData.loading) {
    progressData.items.forEach((progressItem) => {
      socialText += `${progressItem.title}: ${progressItem.value} \n `;
    });
  }

  socialText = `Check my profile @RangoExchange \n ${socialText} \n #rango #rango_exchange @RangoExchange \n`;

  return socialText;
};
