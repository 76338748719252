import { Typography } from '@rango-dev/ui';
import { Badge } from './Notifications.styles';

export function UnreadNotificationsBadge({
  count,
  type,
}: {
  count: number;
  type: 'warning' | 'info';
}) {
  return count ? (
    <Badge
      className="absolute right-[-4px] top-[-4px] flex h-[1.125rem] w-[1.125rem] items-center justify-center rounded-full"
      type={type}>
      <Typography variant="body" size="xsmall" color="background">
        {count}
      </Typography>
    </Badge>
  ) : null;
}
