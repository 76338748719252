import { css, CSS, styled, Tabs, theme } from '@rango-dev/ui';

export const profileWalletsStyles: CSS = {
  paddingTop: '$25',
};

export const tabsStyles = css({
  height: 'auto',
});

export const AddedWallets = styled('ul', {
  overflowY: 'auto',
});

export const StyledTabs = styled(Tabs, {
  '& ._tab': {
    '&[data-active="false"]': {
      color: theme.colors.neutral600,
    },
  },
});
