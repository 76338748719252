import { ChainDailyData, SwapperDailyData } from '../../../../types';
import { DailyChartTabs } from './DailyChart.type';
import { t } from 'i18next';

export const barChartColors: string[] = [
  '#8B62FF',
  '#469BF5',
  '#29DABA',
  '#D629DA',
  '#4658F5',
  '#9DF546',
  '#F01DA8',
  '#FF8B66',
  '#44F1E6',
  '#29DA7A',
  '#F4C932',
];

export const createChartDataTabs = (data: ChainDailyData[] | SwapperDailyData[]) => {
  const tabs = [
    { id: DailyChartTabs.Volume, title: t('Volume') },
    { id: DailyChartTabs.NumOfSwaps, title: t('Num of Swaps') },
    { id: DailyChartTabs.NumOfEffectiveSwaps, title: t('Num of Effective Swaps') },
    { id: DailyChartTabs.EffectiveVolume, title: t('Effective Volume') },
  ];
  const dataItem = data[0];

  return tabs
    .filter(({ id }) => id in dataItem) // Filter out keys with undefined values
    .map(({ id, title }) => ({ id, title }));
};
