import { ReactElement } from 'react';
import { Container } from './ProfileUserDataCampaignTab.styles';
import { Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';

export function ProfileUserDataCampaignTab(): ReactElement {
  const { t } = useTranslation();
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { isMobile } = useWindowDimensions();

  return (
    <Container>
      <img
        src={
          isDarkMode
            ? '/imgs/common/empty-campaigns-state-dark.svg'
            : '/imgs/common/empty-campaigns-state-light.svg'
        }
        alt="No campaign found."
        width={isMobile ? 240 : 342}
        height={isMobile ? 200 : 285}
      />
      <Typography variant="body" size="medium" color="$neutral700">
        {t('Soon, you’ll be able to view the campaigns you previously joined.')}
      </Typography>
    </Container>
  );
}
