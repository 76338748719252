import { Button, darkTheme, styled } from '@rango-dev/ui';

export const FilterContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',

  '& ._typography': {
    [`.${darkTheme} &`]: {
      color: '$neutral700',
    },
  },

  '.filter-label': {
    marginBottom: '$2',
    paddingLeft: '$5',

    [`.${darkTheme} &`]: {
      color: '$neutral800',
    },
  },

  '.more-option': {
    width: '$36',
    height: '$36',
    padding: '$10',
    borderRadius: '$md',
    backgroundColor: '$neutral100',
    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral200',
    },

    '&:hover': {
      backgroundColor: '$secondary100',
      [`.${darkTheme} &`]: {
        backgroundColor: '$neutral400',
      },
    },
  },
});

export const WalletDetailsFilterItemStyle = styled('div', {
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',

  '& ._typography': {
    [`.${darkTheme} &`]: {
      color: '$neutral700',
    },
  },

  '.filter-label': {
    marginBottom: '$2',
    paddingLeft: '$5',

    [`.${darkTheme} &`]: {
      color: '$neutral800',
    },
  },

  '.body-filter': {
    width: '138px',
    height: '$32',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '$neutral300',
    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral400',
    },
    padding: '$5 $10',
    borderRadius: '$sm',

    '@md': {
      width: '173px',
    },

    '& ._typography': {
      [`.${darkTheme} &`]: {
        color: '$foreground',
      },
    },

    '&:hover': {
      backgroundColor: '$secondary100',
      [`.${darkTheme} &`]: {
        backgroundColor: '$neutral400',
      },
    },
  },
});

export const MoreOptionContainer = styled('div', {
  backgroundColor: '$background',
  borderRadius: '$sm',
  padding: '$20',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  '@md': {
    width: '240px',
    padding: '$10',
    boxShadow: '5px 5px 10px 0px rgba(0, 0, 0, 0.10)',
    [`.${darkTheme} &`]: {
      boxShadow: '0px 5px 20px 0px #161C38',
    },
  },

  '& ._typography': {
    [`.${darkTheme} &`]: {
      color: '$foreground',
    },
  },

  '.more-item': {
    padding: '$10 $0',
  },

  '.header': {
    width: '100%',
    display: 'none',
    justifyContent: 'space-between',
    '@sm': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
});

export const FilterItemContainer = styled('div', {
  backgroundColor: '$background',
  borderRadius: '$sm',
  padding: '$20',
  maxHeight: 'calc(100vh - 62px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  '@md': {
    width: '240px',
    padding: '$15',
    boxShadow: '5px 5px 10px 0px rgba(0, 0, 0, 0.10)',
    [`.${darkTheme} &`]: {
      boxShadow: '0px 5px 20px 0px #161C38',
    },
  },

  '.filter-body': {
    width: '100%',
    height: 'calc(100vh - 163px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '@md': {
      height: 'auto',
    },

    '&.body-wallet': {
      height: '230px',
      '@md': {
        height: 'auto',
      },
    },
  },
});

export const FilterItemHeader = styled('div', {
  width: '100%',
  display: 'flex',

  flexDirection: 'column-reverse',
  '@md': {
    flexDirection: 'column',
  },

  '.title-container': {
    display: 'none',
    '@md': {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  '.toggle-select-all': {
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },
});

export const InputContainer = styled('div', {
  width: '100%',

  '._text-field': {
    padding: '$10 $5',
    borderRadius: '$sm',
    alignItems: 'center',
    '._icon': {
      marginRight: '$5',
    },

    input: {
      fontSize: '10px',
    },
  },

  'input::placeholder, input::-webkit-input-placeholder': {
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },
});

export const CheckList = styled('ul', {
  width: '100%',
  maxHeight: 'calc(95vh - 164px)',

  '@md': {
    maxHeight: '230px',
  },
  overflowY: 'auto',
});

export const ChainImageContainer = styled('div', {
  height: '$20',
  display: 'flex',
  alignItems: 'center',
  paddingRight: '$5',

  '.image-container': {
    display: 'inline-block',
    width: '$20',
    height: '$20',
    borderRadius: '$lg',
    backgroundColor: '$neutral200',
    marginLeft: '-$10',
    overflow: 'hidden',

    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral500',
    },

    img: {
      borderRadius: '$lg',
    },

    '.more-item': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '$20',
      height: '$20',
    },
  },

  '.image-container:first-child': {
    marginLeft: '$0',
  },
});

export const blockchainFilterModalStyle = {
  container: {
    borderBottomLeftRadius: '$0',
    borderTopLeftRadius: '$primary',
    borderBottomRightRadius: '$0',
    borderTopRightRadius: '$primary',
    overflow: 'hidden',
    width: '100%',
    height: '95%',
  },
  content: {
    padding: '0',
    background: 'transparent',
    overflow: 'hidden',
  },
};

export const filterModalStyle = {
  container: {
    borderBottomLeftRadius: '$0',
    borderTopLeftRadius: '$primary',
    borderBottomRightRadius: '$0',
    borderTopRightRadius: '$primary',
    overflow: 'hidden',
    width: '100%',
  },
  content: {
    padding: '0',
    background: 'transparent',
  },
};

export const FilterActionStyle = styled('div', {
  display: 'block',
  width: '100%',
  '@md': {
    display: 'none',
  },
});

export const StyledButton = styled(Button, {
  [`.${darkTheme} &`]: {
    color: '$neutral900',
  },
});

export const ResetButton = styled(Button, {
  position: 'absolute',
});
