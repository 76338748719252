import { ReactElement } from 'react';
import { Divider, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../Modal';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { ModalContainer, modalStyles, Description } from './ShareProgress.styles';
import { ModalPropTypes } from './ShareProgress.types';
import { ShareProgressContent } from './ShareProgressContent';
import { ShareProgressSocial } from './ShareProgressSocial';

export function ShareProgressModal(props: ModalPropTypes): ReactElement {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();

  return (
    <Modal
      open={open}
      onClose={onClose}
      dismissible
      anchor={isMobile ? 'bottom' : 'center'}
      styles={modalStyles}>
      <ModalContainer>
        <Typography variant="headline" size="small">
          {t('Share Your Progress')}
        </Typography>
        <Divider size={4} />
        <Description variant="body" size="medium">
          {t('Share your current Rango rank and score directly on your X.')}
        </Description>
        <Divider size="16" />
        <ShareProgressContent />
        <Divider size="16" />
        <ShareProgressSocial />
        <Divider size="20" />
      </ModalContainer>
    </Modal>
  );
}
