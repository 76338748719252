import {
  WalletEventData,
  WalletEventTypes,
  WidgetEvents,
  widgetEventEmitter,
} from '@rango-dev/widget-embedded';

import { useEffect } from 'react';
import { GTMEvents } from '../constants/events';

export function useSubscribeToEvents(): void {
  // Handling connect and disconnect wallet events for Google Tag Manager
  const handleWalletEvent = (event: WalletEventData) => {
    window.dataLayer = window.dataLayer || [];
    if (event.type === WalletEventTypes.CONNECT) {
      window.dataLayer.push({
        event: GTMEvents.WALLET_CONNECTED,
        type: event.payload.walletType,
      });
    }

    if (event.type === WalletEventTypes.DISCONNECT) {
      window.dataLayer.push({
        event: GTMEvents.WALLET_DISCONNECTED,
        type: event.payload.walletType,
      });
    }
  };

  useEffect(() => {
    widgetEventEmitter.on(WidgetEvents.WalletEvent, handleWalletEvent);

    return () => {
      widgetEventEmitter.off(WidgetEvents.WalletEvent, handleWalletEvent);
    };
  }, [widgetEventEmitter]);
}
