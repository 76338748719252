import { useAppDispatch, useAppSelector } from './reduxHooks';
import { getConnectedWalletTypes, Networks } from '../utils/Constants';
import { useMemo } from 'react';
import {
  addExcludedBlockchains,
  addExcludedWallet,
  removeExcludedBlockchains,
  removeExcludedWallet,
  setExcludedBlockchains,
  setExcludedWallets,
} from '../state/WalletDetailsSlice';
import { sortedBlockchainSelector } from '../state/MetaSlice';

export function useWalletDetails() {
  const dispatch = useAppDispatch();
  const { excludedBlockchains, excludedWallets } = useAppSelector((state) => state.walletDetails);

  const wallet = useAppSelector((state) => state.wallet.wallet);
  const sortedBlockchain = useAppSelector(sortedBlockchainSelector);
  const sortedBlockchainNames = sortedBlockchain.map((blockchain) => blockchain.name);
  const validBlockchains = useMemo(
    () =>
      Array.from(
        new Set(wallet?.blockchains.map((blockchainItem) => blockchainItem.name as Networks)),
      )?.sort((a, b) => sortedBlockchainNames.indexOf(a) - sortedBlockchainNames.indexOf(b)),
    [wallet],
  );

  const connectedWallets = useMemo(() => Array.from(getConnectedWalletTypes(wallet)), [wallet]);

  const toggleExcludedAllWallet = () => {
    if (excludedWallets?.length === 0) {
      dispatch(setExcludedWallets(connectedWallets));
    } else {
      dispatch(setExcludedWallets([]));
    }
  };

  const toggleExcludedAllBlockchain = () => {
    if (excludedBlockchains?.length === 0) {
      dispatch(setExcludedBlockchains(validBlockchains));
    } else {
      dispatch(setExcludedBlockchains([]));
    }
  };

  const handleExcludedBlockchain = (blockchainItem: Networks) => {
    if (excludedBlockchains?.includes(blockchainItem)) {
      dispatch(removeExcludedBlockchains(blockchainItem));
    } else {
      dispatch(addExcludedBlockchains(blockchainItem));
    }
  };

  const handleExcludedWallet = (walletItem: string) => {
    if (excludedWallets?.includes(walletItem)) {
      dispatch(removeExcludedWallet(walletItem));
    } else {
      dispatch(addExcludedWallet(walletItem));
    }
  };

  return {
    toggleExcludedAllWallet,
    toggleExcludedAllBlockchain,
    handleExcludedBlockchain,
    handleExcludedWallet,
    validBlockchains,
    connectedWallets,
  };
}
