import { Divider, Typography } from '@rango-dev/ui';
import {
  Container,
  EffectiveValueSkeleton,
  Title,
  TotalValueSkeleton,
} from './ProfileSummaryItemWithEffectiveValue.styles';
import { PropTypes } from './ProfileSummaryItemWithEffectiveValue.types';
import { Tooltip } from '../Tooltip';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

export function ProfileSummaryItemWithEffectiveValue(props: PropTypes) {
  const { isMobile } = useWindowDimensions();

  return (
    <Container>
      <div className="total">
        <img width={48} height={48} src={props.icon} />
        <Divider direction="vertical" size={16} />
        <div className="title">
          <Title
            variant={isMobile ? 'label' : 'title'}
            size={isMobile ? 'large' : 'small'}
            color="neutral700">
            {props.total.title}
          </Title>
          <Divider size={4} direction="horizontal" />
          <Divider size={2} direction="horizontal" />
          <Tooltip description={props.total.description} />
        </div>
        <Divider direction="vertical" size={4} />
        {props.loading ? (
          <>
            <TotalValueSkeleton variant="rounded" width={178} height={30} />
            <Divider size={10} direction="vertical" />
          </>
        ) : (
          <Typography variant="headline" size="large">
            {props.total.value}
          </Typography>
        )}
      </div>
      <div className="effective">
        <div className="title">
          <Title variant="label" size="medium" color="neutral700">
            {props.effective.title}
          </Title>
          <Divider size={4} direction="horizontal" />
          <Divider size={2} direction="horizontal" />
          <Tooltip description={props.effective.description} />
        </div>
        {props.loading ? (
          <EffectiveValueSkeleton variant="rounded" width={70} height={20} />
        ) : (
          <Typography variant="title" size="medium">
            {props.effective.value}
          </Typography>
        )}
      </div>
    </Container>
  );
}
