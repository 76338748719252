import { Modal as RawModal, ModalPropTypes } from '@rango-dev/ui';
import { getContainer } from '../../utils/generalFunctions/common';
import { PropsWithChildren } from 'react';

export const Modal = (props: PropsWithChildren<ModalPropTypes>) => {
  return (
    <RawModal
      hasWatermark={false}
      container={getContainer()}
      {...props}
      styles={{
        ...props.styles,
        root: {
          borderRadius: '0px',
          zIndex: 999,
          position: 'fixed',
        },
        footer: {
          padding: '0',
        },
      }}>
      {props.children}
    </RawModal>
  );
};
