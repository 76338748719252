import { WalletBalance } from '../../utils/Blockchains';
import { BigNumber } from 'bignumber.js';
import { ZERO } from '../../utils/Constants';
import { WalletDetailItemType } from './WalletDetails.types';

export const MIN_USD_VALUE = 5;

export function getUsdValue(balance: WalletBalance): BigNumber | null {
  if (balance.usdPrice === null) return null;
  return new BigNumber(balance.amount || ZERO).multipliedBy(balance.usdPrice);
}

const negativeInf = new BigNumber('-1e40');

export const sortBalance = (a: WalletBalance, b: WalletBalance) => {
  const aUsd = getUsdValue(a);
  const bUsd = getUsdValue(b);
  return (bUsd || negativeInf).comparedTo(aUsd || negativeInf) || 0;
};

export const sortImg = (a: WalletBalance, b: WalletBalance) => {
  if (!!a.logo && !b.logo) return -1;
  if (!a.logo && !!b.logo) return 1;
  return 0;
};

export const compareEmptyWalletDetailItem = (a: WalletDetailItemType, b: WalletDetailItemType) => {
  if (
    (!a.account.balances || a.account.balances.length === 0) &&
    b.account.balances &&
    b.account.balances?.length > 0
  ) {
    return 1;
  }

  if (
    (!b.account.balances || b.account.balances.length === 0) &&
    a.account.balances &&
    a.account.balances?.length > 0
  ) {
    return -1;
  }

  return 0;
};
