import { ComponentProps } from 'react';
import { ProfileSummary } from '../../../types';
import { displayUserRank, displayUsdValue } from '../ProfileSummary/ProfileSummary.helpers';
import { ProgressCard } from '../ProgressCard';

export const getProgressCardProps = (
  profileSummary: ProfileSummary | null,
): ComponentProps<typeof ProgressCard> => {
  const loading = profileSummary === null;

  if (loading) {
    return { loading: true };
  }

  return {
    loading: false,
    rank: displayUserRank(profileSummary.rank),
    score: displayUserRank(profileSummary.score),
    details: {
      txCount: profileSummary.txCount.toString(),
      interactedBlockchains: profileSummary.interactedBlockchains.toString(),
      totalVolume: displayUsdValue(profileSummary.totalVolume),
      mostUsedToken: profileSummary.mostUsedToken
        ? {
            value: profileSummary.mostUsedToken.symbol,
            image: profileSummary.mostUsedToken.logo,
          }
        : null,
      mostUsedBridge: profileSummary.mostUsedBridge
        ? {
            value: profileSummary.mostUsedBridge.title,
            image: profileSummary.mostUsedBridge.logo,
          }
        : null,
      mostUsedDex: profileSummary.mostUsedDex
        ? {
            value: profileSummary.mostUsedDex.title,
            image: profileSummary.mostUsedDex.logo,
          }
        : null,
      mostUsedBlockchain: profileSummary.mostUsedBlockchain
        ? {
            value: profileSummary.mostUsedBlockchain.shortName,
            image: profileSummary.mostUsedBlockchain.logo,
          }
        : null,
    },
  };
};
