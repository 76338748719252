import { getContainer } from '../../utils/generalFunctions/common';
import { PropsWithChildren } from 'react';
import { ContentContainer } from './Popover.styles';
import { PropTypes } from './Popover.types';
import { Popover as RawPopover } from '@rango-dev/ui';

const POPOVER_SIDE_OFFSET = 5;

export const Popover = (props: PropsWithChildren<PropTypes>) => {
  const { content, children, align, hasArrow, sideOffset, onOpenChange, container, open } = props;
  return (
    <RawPopover
      align={align}
      content={<ContentContainer>{content}</ContentContainer>}
      container={container ?? getContainer()}
      hasArrow={hasArrow}
      open={open}
      onOpenChange={onOpenChange}
      styles={{
        arrowStyles: {
          fill: '$background',
        },
      }}
      sideOffset={sideOffset ?? POPOVER_SIDE_OFFSET}>
      {children}
    </RawPopover>
  );
};
