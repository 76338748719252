import { useEffect, useMemo, useState } from 'react';
import {
  ChainSelectRadioButton,
  ChainsConfirmButton,
  ChainsList,
  ChainsListContainer,
  ChainsRadioRoot,
  WalletChainSelectContainer,
} from './WalletsChainSelect.styles';
import { ChevronDownIcon, ChevronUpIcon, Image, Radio, Typography } from '@rango-dev/ui';
import { useAppSelector } from '../../hooks/reduxHooks';
import { sortedBlockchainSelector } from '../../state/MetaSlice';
import { ChainItem } from './ChainItem';
import { WalletsChainSelectProps } from './WalletsChainSelect.types';
import { ChainItemContainer } from './ChainItem.styles';
import { useTranslation } from 'react-i18next';

export const FILTERABLE_CHAIN_NAMES = [
  'ETH',
  'COSMOS',
  'TRON',
  'STARKNET',
  'SOLANA',
  'BTC',
  'LTC',
  'DOGE',
  'BCH',
];

export function WalletsChainSelect(props: WalletsChainSelectProps) {
  const { t } = useTranslation();

  const { isOpen, setIsOpen, selectedChain, handleSelectedChainChange, selectedBlockchains } =
    props;

  const [temporarySelectedChain, setTemporarySelectedChain] = useState<string | null>(null);

  const allBlockchains = useAppSelector(sortedBlockchainSelector);

  const filterableChains = useMemo(() => {
    return allBlockchains.filter((blockchain) =>
      selectedBlockchains.length
        ? selectedBlockchains.includes(blockchain.name)
        : FILTERABLE_CHAIN_NAMES.includes(blockchain.name),
    );
  }, [allBlockchains]);

  const clickEventHandler = (event: Event) => {
    const clickedElement = event.target as HTMLElement;

    if (
      !clickedElement.closest('.chains_list_container') &&
      !clickedElement.closest('.chains_select_container')
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', clickEventHandler);

    return () => {
      document.removeEventListener('mousedown', clickEventHandler);
    };
  }, []);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  const selectedBlockchainItem = useMemo(
    () =>
      !!selectedChain
        ? filterableChains.find((blockchain) => blockchain.name === selectedChain)
        : null,
    [selectedChain],
  );

  const handleChainSelect = (blockchainName: string | null) => {
    handleSelectedChainChange(blockchainName);
    setIsOpen(false);
  };

  return (
    <>
      <WalletChainSelectContainer onClick={toggleOpen} className="chains_select_container">
        <div className="flex items-center gap-2">
          {!!selectedBlockchainItem && <Image src={selectedBlockchainItem.logo} size={20} />}
          <Typography variant="label" size="medium">
            {!!selectedBlockchainItem ? selectedBlockchainItem.displayName : t('All Chains')}
          </Typography>
        </div>
        <div className="h-4 w-4 p-1">
          {isOpen ? (
            <ChevronUpIcon color="black" size={10} />
          ) : (
            <ChevronDownIcon color="black" size={10} />
          )}
        </div>
      </WalletChainSelectContainer>

      {isOpen && (
        <ChainsListContainer className="chains_list_container">
          <span className="hidden md:inline">
            <Typography variant="label" size="medium">
              {t('Filter by blockchain')}
            </Typography>
          </span>

          <ChainsList>
            <ChainItemContainer
              bordered={selectedChain === null}
              onClick={() => handleChainSelect(null)}>
              <Typography
                variant="label"
                size="medium"
                color={selectedChain === null ? 'info500' : undefined}>
                {t('All')}
              </Typography>
            </ChainItemContainer>
            {filterableChains.map((blockchain) => (
              <ChainItem
                key={blockchain.name}
                blockchain={blockchain}
                selected={selectedChain === blockchain.name}
                handleClick={() => handleChainSelect(blockchain.name)}
              />
            ))}
          </ChainsList>

          <ChainsRadioRoot value={temporarySelectedChain || 'all'}>
            <ChainSelectRadioButton
              id={'all'}
              title={t('All')}
              onClick={() => setTemporarySelectedChain(null)}
              end={<Radio value={'all'} />}
            />
            {filterableChains.map((blockchain) => (
              <ChainSelectRadioButton
                key={blockchain.name}
                id={blockchain.name}
                title={blockchain.name}
                onClick={() => setTemporarySelectedChain(blockchain.name)}
                start={<Image src={blockchain.logo} size={22} />}
                end={<Radio value={blockchain.name} />}
              />
            ))}
          </ChainsRadioRoot>
          <ChainsConfirmButton
            id="connect-wallet-modal-confirm-btn"
            type="primary"
            size="medium"
            onClick={() => handleChainSelect(temporarySelectedChain)}>
            {t('Confirm')}
          </ChainsConfirmButton>
        </ChainsListContainer>
      )}
    </>
  );
}
