import { styled, darkTheme, RadioRoot, Button, ListItemButton } from '@rango-dev/ui';

export const WalletChainSelectContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '$10',
  backgroundColor: '$neutral100',
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral300',
  },
  borderRadius: '$md',
  cursor: 'pointer',
  userSelect: 'none',
  width: 'calc(50% - 5px)',
  '@md': {
    minWidth: '160px',
    width: 'auto',
  },
});

export const ChainsListContainer = styled('div', {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  gap: '$15',
  padding: '$15',
  backgroundColor: '$background',
  zIndex: 1,
  textAlign: 'start',
  width: '100%',
  height: '100%',
  left: 0,
  overflowY: 'clip',
  '@md': {
    width: '610px',
    height: 'max-content',
    top: '$50',
    left: '$20',
    right: '$20',
    borderRadius: '$sm',
    boxShadow: '0px 5px 20px 0px #82828233',
    [`.${darkTheme} &`]: {
      boxShadow: '0px 5px 20px 0px #161C38',
    },
  },
});

export const ChainsList = styled('div', {
  display: 'none',
  '@md': {
    height: 'max-content',
    display: 'grid',
    gap: '$10',
    gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
  },
});

export const ChainsRadioRoot = styled(RadioRoot, {
  overflowY: 'auto',
  '@md': {
    display: 'none',
  },
});

export const ChainsConfirmButton = styled(Button, {
  width: '100%',
  '@md': {
    display: 'none',
  },
});

export const ChainSelectRadioButton = styled(ListItemButton, {
  height: '50px',
});
