import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_KEY, BASE_URL } from '../utils/Constants';
import { store } from '../state/Store';
import { logOutUser } from '../state/ProfileSlice';
import { BASE_PROFILE_URL } from '../api/Profile';
import { ResponseWithError } from '../types';
import { UserUnauthorizedError } from '../utils/Errors';

export const httpService = axios.create({ baseURL: BASE_URL, params: { apiKey: API_KEY } });

httpService.interceptors.response.use(
  (response: AxiosResponse<ResponseWithError>) => {
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response;
  },
  (error: AxiosError) => {
    if (error.config?.url?.includes(BASE_PROFILE_URL) && error.response?.status === 401) {
      store.dispatch(logOutUser());
      return Promise.reject(new UserUnauthorizedError());
    }
    return Promise.reject(error);
  },
);
