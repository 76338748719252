import { ListItem, styled } from '@rango-dev/ui';

export const CardContainer = styled('div', {
  backgroundColor: '$neutral100',
  borderRadius: '$xm',
  padding: '$25 $20',
  display: 'flex',
  flexDirection: 'column',
  gap: '$10',
  '@sm': {
    padding: '$25',
  },
});

export const CardFieldItemContainer = styled(ListItem, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$5 $10',
  gap: '$10',
  minHeight: '$36',
  '@sm': {
    minHeight: '$40',
    padding: '$10',
  },
  '& .label': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '$16',
      height: '$16',
    },
  },
  '& .item-end-container': {
    flexShrink: 'unset',
  },
});

export const CardFieldItemSkeletonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '$36',
  '@sm': {
    height: '$40',
  },
});
