import { Button, IconButton, darkTheme, styled } from '@rango-dev/ui';

export const StyledButton = styled(Button, {
  height: '40px',
  variants: {
    variant: {
      contained: {
        padding: '$15 $20',
      },
    },
  },
});

export const AddWalletButton = styled(Button, {
  variants: {
    variant: {
      contained: {
        /**
         * Sometimes these styles are applied to the element without using "!important".
         * To ensure they are consistently applied in all conditions, we use "!important" for now.
         * However, we should revisit and address this issue later.
         */
        width: '44px',
        borderTopLeftRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
        borderLeft: '1px solid !important',
      },
    },
  },
});

export const ConnectedWalletButton = styled(Button, {
  width: '145px',
  /**
   * Sometimes these styles are applied to the element without using "!important".
   * To ensure they are consistently applied in all conditions, we use "!important" for now.
   * However, we should revisit and address this issue later.
   */
  borderTopRightRadius: '0 !important',
  borderBottomRightRadius: '0 !important',
  variants: {
    variant: {
      contained: {
        width: '145px',
        padding: '$8 $10',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
});

export const ModalFlex = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  [`& ${IconButton}`]: {
    padding: '$5',
  },
});

export const MoreWallet = styled('div', {
  backgroundColor: '$background',
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral500',
  },
});

export const ConnectedBadge = styled('div', {
  position: 'absolute',
  background: '$secondary500',
  [`.${darkTheme} &`]: {
    $$color: '$colors$secondary250',
  },
  width: '$6',
  height: '$6',
  borderRadius: '$lg',
  right: '$6',
  top: '$8',
  border: '1px solid $surface100',
});

export const connectedWalletButtonTooltipContentStyle = {
  padding: '$5',
  height: '26px',
  backgroundColor: '$background !important',
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral100 !important',
  },
};

export const addMoreWalletsTooltipContentStyle = {
  backgroundColor: '$background !important',
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral100 !important',
  },
};
