import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Networks } from '../utils/Constants';

export type WalletDetailsOption = {
  hideSmallBalances: boolean;
  hideEmptyWallets: boolean;
  hideUnsupportedTokens: boolean;
};

export type WalletDetailsState = {
  excludedBlockchains: Networks[];
  excludedWallets: string[];
} & WalletDetailsOption;

export const WALLET_DETAILS_LOCAL_STORAGE_KEY = 'walletDetailsOptions';

const loadStateFromLocalStorage = (): WalletDetailsOption => {
  const state = localStorage.getItem(WALLET_DETAILS_LOCAL_STORAGE_KEY);
  if (state) {
    return JSON.parse(state);
  }
  return {
    hideSmallBalances: false,
    hideEmptyWallets: true,
    hideUnsupportedTokens: true,
  };
};

const initialPersist = loadStateFromLocalStorage();

const initialState: WalletDetailsState = {
  excludedBlockchains: [],
  excludedWallets: [],
  hideSmallBalances: initialPersist.hideSmallBalances,
  hideEmptyWallets: initialPersist.hideEmptyWallets,
  hideUnsupportedTokens: initialPersist.hideUnsupportedTokens,
};

export const walletDetailsSlice = createSlice({
  name: 'walletDetails',
  initialState,
  reducers: {
    setExcludedWallets: (state, action: PayloadAction<string[]>) => {
      state.excludedWallets = [...action.payload];
    },
    addExcludedWallet: (state, action: PayloadAction<string>) => {
      const prevExcludedWallets = state.excludedWallets ? [...state.excludedWallets] : [];
      state.excludedWallets = [...prevExcludedWallets, action.payload];
    },
    removeExcludedWallet: (state, action: PayloadAction<string>) => {
      const prevExcludedWallets = state.excludedWallets ? [...state.excludedWallets] : [];
      state.excludedWallets = prevExcludedWallets.filter(
        (walletItem) => walletItem !== action.payload,
      );
    },
    setExcludedBlockchains: (state, action: PayloadAction<Networks[]>) => {
      state.excludedBlockchains = [...action.payload];
    },
    addExcludedBlockchains: (state, action: PayloadAction<Networks>) => {
      const prevExcludedBlockchains = state.excludedBlockchains
        ? [...state.excludedBlockchains]
        : [];
      state.excludedBlockchains = [...prevExcludedBlockchains, action.payload];
    },
    removeExcludedBlockchains: (state, action: PayloadAction<Networks>) => {
      const prevExcludedBlockchains = state.excludedBlockchains
        ? [...state.excludedBlockchains]
        : [];
      state.excludedBlockchains = prevExcludedBlockchains.filter(
        (blockchainItem) => blockchainItem !== action.payload,
      );
    },
    setHideSmallBalances: (state, action: PayloadAction<boolean>) => {
      state.hideSmallBalances = action.payload;
    },
    setHideEmptyWallets: (state, action: PayloadAction<boolean>) => {
      state.hideEmptyWallets = action.payload;
    },
    setHideUnsupportedTokens: (state, action: PayloadAction<boolean>) => {
      state.hideUnsupportedTokens = action.payload;
    },
    resetFilters: (state) => {
      state.hideSmallBalances = false;
      state.hideEmptyWallets = true;
      state.hideUnsupportedTokens = true;
    },
  },
});

export const {
  setExcludedWallets,
  addExcludedWallet,
  removeExcludedWallet,
  setExcludedBlockchains,
  addExcludedBlockchains,
  removeExcludedBlockchains,
  setHideSmallBalances,
  setHideEmptyWallets,
  setHideUnsupportedTokens,
  resetFilters,
} = walletDetailsSlice.actions;

export const WalletDetailsReducer = walletDetailsSlice.reducer;
