import { useState } from 'react';

const DEFAULT_PAGE_SIZE = 8;

type Params<T> = {
  items: T[];
  pageSize?: number;
};
type Result<T> = {
  paginatedItems: T[];
  page: number;
  totalPages: number;
  setPage: (page: number) => void;
};

export default function usePagination<T>({
  items,
  pageSize = DEFAULT_PAGE_SIZE,
}: Params<T>): Result<T> {
  const [page, setPage] = useState(0);

  return {
    paginatedItems: items.slice(page * pageSize, (page + 1) * pageSize),
    page,
    totalPages: Math.ceil(items.length / pageSize),
    setPage,
  };
}
