export type FilterProps = {
  onClose?: () => void;
};

export type FilterContentProps = {
  title: string;
  handleToggleAll: () => void;
  excludedItems: string[];
  searchedFor: string;
  searchHandler: (searchPhrase: string) => void;
};

export type FilterActionProps = {
  onClose?: () => void;
};

export enum WalletDetailsFilterType {
  BLOCKCHAIN = 'blockchain',
  WALLET = 'wallet',
}

export type WalletDetailsFilterItemProps = {
  label: string;
  title: string;
  filterType: WalletDetailsFilterType;
  popoverAlign: 'start' | 'end';
  onClick: () => void;
};
