import { useEffect } from 'react';
import { useAppDispatch } from './reduxHooks';
import { WidgetTheme } from '@rango-dev/widget-embedded';
import { updateSettings } from '../state/SettingsSlice';
import { detectOSDarkMode } from '../utils/generalFunctions/common';

export const useThemeAutoSwitch = (theme: WidgetTheme['mode']) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (theme === 'auto') {
      const switchThemeListener = (event: MediaQueryListEvent) => {
        dispatch(
          updateSettings({
            isDarkMode: event.matches,
          }),
        );
      };

      // Update theme based on OS preference initially
      dispatch(
        updateSettings({
          isDarkMode: detectOSDarkMode(),
        }),
      );

      // Add event listener for changes in OS theme preference
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      mediaQuery.addEventListener('change', switchThemeListener);

      // Cleanup by removing event listener when component unmounts
      return () => {
        mediaQuery.removeEventListener('change', switchThemeListener);
      };
    }
  }, [theme, dispatch]);
};

export default useThemeAutoSwitch;
