import React, { memo, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/reduxHooks';
import type { WalletInfoWithExtra } from '@rango-dev/widget-embedded';
import {
  StatefulConnect,
  useStatefulConnect,
  useWalletList,
  useWallets,
} from '@rango-dev/widget-embedded';
import { DEFAULT_WALLET_INJECTION_ERROR } from '../../utils/Errors';
import { Typography } from '@rango-dev/ui';
import {
  ModalFooter,
  TextLink,
  connectWalletModalStyles,
  statefulModalStyles,
} from './ConnectWalletModal.styles';
import { Modal } from '../Modal';
import type { ConnectWalletModalProps, WalletMessage } from './ConnectWalletModal.types';
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from '../../constants/links';
import ConnectWalletModalContent from './ConnectWalletModalContent';
import { Namespace } from '@rango-dev/wallets-shared';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { ConnectWalletModalHeader } from './ConnectWalletModalHeader';
import { GTMEvents } from '../../constants/events';

const { DerivationPath, isOnDerivationPath, isOnNamespace, Namespaces } = StatefulConnect;

const ConnectWalletModal = (props: ConnectWalletModalProps) => {
  const {
    open,
    onClose,
    title: modalTitle,
    closeAfterSelect,
    onExit,
    disabled = false,
    blockchains = [],
    excludedWallets = [],
  } = props;
  const { t } = useTranslation();
  const isDarkMode = useAppSelector((state) => state.settings.isDarkMode);
  const { isMobile } = useWindowDimensions();

  const [errors, setErrors] = useState<WalletMessage[]>([]);
  const [selectedWallet, setSelectedWallet] = useState<WalletInfoWithExtra | null>(null);
  const [isWalletChainSelectOpen, setIsWalletChainSelectOpen] = useState(false);

  const { state } = useWallets();
  const { terminateConnectingWallets } = useWalletList();
  const { handleConnect, handleNamespace, handleDerivationPath, getState, resetState } =
    useStatefulConnect();

  const catchErrorsWhileConnect = (error: Error, wallet?: WalletInfoWithExtra) => {
    let message = error instanceof Error ? error.message : error;
    message ||= !!error ? error.toString() : error;
    message ||= DEFAULT_WALLET_INJECTION_ERROR;

    // Handling various wallet error events for Google Tag Manager
    if (message && wallet) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: GTMEvents.WALLET_FAILED,
        type: wallet.type,
        message,
      });
    }

    setErrors((prev) => [...prev, { open: true, message }]);
  };

  const handleClose = () => {
    terminateConnectingWallets();
    handleModalClose();
  };

  const handleWalletItemClick = (wallet: WalletInfoWithExtra) => {
    const afterConnected = () => {
      setSelectedWallet(wallet);
    };

    handleConnect(wallet, {
      disconnectIfConnected: true,
    })
      .then(afterConnected)
      .catch((e) => catchErrorsWhileConnect(e, wallet));
  };

  const handleConfirmNamespaces = (selectedNamespaces: Namespace[]) => {
    if (selectedWallet) {
      handleNamespace(selectedWallet, selectedNamespaces).catch(catchErrorsWhileConnect);
    }
  };

  const handleConfirmDerivationPath = (derivationPath: string) => {
    if (!derivationPath) {
      throw new Error(
        "Derivation path is empty. Please make sure you've filled the field correctly.",
      );
    }

    handleDerivationPath(derivationPath).catch(catchErrorsWhileConnect);
  };

  const handleModalClose = () => {
    onClose();
    handleCloseMessages(true);
  };

  const handleCloseMessages = (removeAll?: boolean, message?: string) => {
    const updatedMessages = errors.map((error) => {
      if (error.message === message || removeAll) {
        return { ...error, open: false };
      }
      return error;
    });
    setErrors(updatedMessages);
  };

  useEffect(() => {
    setErrors([]);
  }, [open]);

  const isInit = getState().status === 'init';
  const modalStyles = !isInit ? statefulModalStyles : connectWalletModalStyles;

  let title: string | undefined = modalTitle || t('Choose a wallet to connect');
  if (isMobile) {
    if (isWalletChainSelectOpen) {
      title = t('Filter by blockchain');
    } else {
      title = t('Connect Wallets');
    }
  }

  if (!isInit) {
    title = undefined;
  }

  const onBack = () => {
    if (getState().status === 'derivationPath') {
      resetState('derivation');
    } else {
      resetState();
    }
  };

  const handleExit = () => {
    resetState();
    onExit?.();
  };

  const shouldShowBackButton = !isInit || (isWalletChainSelectOpen && isMobile);

  const selectedWalletState = selectedWallet?.type ? state(selectedWallet?.type) : null;

  useEffect(() => {
    if (selectedWalletState?.connected && closeAfterSelect) {
      handleModalClose();
    }
  }, [selectedWallet?.type, selectedWalletState?.connected, closeAfterSelect]);

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        onExit={handleExit}
        anchor={isMobile ? 'bottom' : 'center'}
        styles={modalStyles}
        header={
          <ConnectWalletModalHeader
            title={title}
            onClose={handleClose}
            onBack={shouldShowBackButton ? onBack : undefined}
          />
        }
        footer={
          !isInit ? null : (
            <ModalFooter hideInMobileView={isWalletChainSelectOpen}>
              <Typography
                variant="title"
                size="small"
                color={isDarkMode ? '$colors$neutral900' : '$colors$neutral700'}>
                <Trans i18nKey="By connecting your wallet, you agree to Terms of Services and Privacy Policy">
                  By connecting your wallet, you agree to our
                  <TextLink className="text-d" href={TERMS_OF_SERVICE} target="_blank">
                    Terms of Services
                  </TextLink>
                  and
                  <TextLink href={PRIVACY_POLICY} target="_blank">
                    Privacy Policy
                  </TextLink>
                </Trans>
              </Typography>
            </ModalFooter>
          )
        }>
        {isInit && (
          <ConnectWalletModalContent
            disabled={disabled}
            walletMessages={errors}
            handleCloseMessages={handleCloseMessages}
            isWalletChainSelectOpen={isWalletChainSelectOpen}
            setIsWalletChainSelectOpen={setIsWalletChainSelectOpen}
            handleWalletItemClick={handleWalletItemClick}
            blockchains={blockchains}
            excludedWallets={excludedWallets}
          />
        )}
        {isOnNamespace(getState) && (
          <Namespaces onConfirm={handleConfirmNamespaces} value={getState().namespace} />
        )}
        {isOnDerivationPath(getState) && (
          <DerivationPath
            onConfirm={handleConfirmDerivationPath}
            value={getState().derivationPath}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

export default memo(ConnectWalletModal);
