import { Button, darkTheme, Modal, styled } from '@rango-dev/ui';
import { ComponentProps } from 'react';

export const Container = styled('div', {
  position: 'relative',
  paddingBottom: '$20',
  '& .modal-chart-header': {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    '@sm': {
      textAlign: 'left',
    },
  },

  '& .chart-content': {
    '& .chart-section': {
      flex: 1,
      height: 225,
      '@md': {
        width: 300,
        height: 400,
      },
    },
    '@md': {
      display: 'flex',
      alignItems: 'center',
    },
  },
});

export const modalStyles: ComponentProps<typeof Modal>['styles'] = {
  container: {
    backgroundColor: '$background',

    maxHeight: '75%',
    '@sm': {
      width: '80%',
    },
    '@md': {
      height: 'auto',
      minHeight: '250px',
      width: '70%',
    },
  },
  content: {
    '@sm': {
      borderRadius: '$primary',
    },
    padding: '0 $20',
    '@md': {
      padding: '0 $30',
    },
  },
};

export const LegendBox = styled('div', {
  borderRadius: '$xs',
  display: 'grid',
  gridTemplateColumns: 'auto auto auto',
  justifyContent: 'space-between',
  '& ._typography': {
    fontSize: '$10',
  },
  '@md': {
    width: 220,
    padding: '$10 $20',
    height: 400,
    display: 'block',
    backgroundColor: '$neutral100',
    '& ._typography': {
      fontSize: '$14',
    },
    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral300',
    },
  },

  '& .item-row': {
    display: 'flex',
    alignItems: 'center',
    padding: '$5 0',
    '@md': {
      padding: '$10 $4',
      height: 35,
      borderBottom: '1px solid $neutral300',
      '&:last-child': {
        borderBottom: 'none',
        paddingBottom: 0,
      },
      '&:first-child': {
        paddingTop: 0,
      },
    },
  },
});

export const Circle = styled('span', {
  width: '$10',
  height: '$10',
  borderRadius: '5px',
});

export const TabsContainer = styled('div', {
  maxWidth: '650px',
  '& ._text': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: '$regular',
  },
  '& ._tab': {
    [`.${darkTheme} &`]: {
      color: '$neutral800',
      '&[class*="isActive-true"]': {
        color: '$foreground',
      },
    },
  },
});

export const DailyChartButton = styled(Button, {
  '& span': {
    display: 'none',
    '@md': {
      display: 'unset',
    },
  },
  '& svg': {
    width: '$20',
    height: '$20',
    '@md': {
      width: '$16',
      height: '$16',
    },
  },

  '&:disabled': {
    [`.${darkTheme} &`]: {
      color: '$neutral800',
      '& svg': {
        color: '$neutral900',
      },
    },
  },
});
