import { Button, Divider, SearchIcon, TextField, Typography } from '@rango-dev/ui';
import { InputContainer, FilterItemHeader } from './WalletDetailsFilter.styles';
import { useTranslation } from 'react-i18next';
import { FilterContentProps } from './WalletDetailsFilter.types';

export function WalletDetailFilterHeader(props: FilterContentProps) {
  const { title, handleToggleAll, excludedItems, searchedFor, searchHandler } = props;
  const { t } = useTranslation();

  return (
    <FilterItemHeader>
      <div className="title-container">
        <div className="flex w-full items-center justify-between">
          <Typography variant="label" size="medium">
            {title}
          </Typography>
          <Button
            id={`wallet-details-modal-${
              excludedItems?.length === 0 ? 'deselect-all' : 'select-all'
            }-filter-btn`}
            className="toggle-select-all"
            variant="ghost"
            size="xxsmall"
            onClick={() => handleToggleAll()}>
            {excludedItems?.length === 0 ? t('Deselect all') : t('Select all')}
          </Button>
        </div>
        <Divider size="10" />
      </div>
      <InputContainer>
        <TextField
          value={searchedFor}
          placeholder={t('Search') || 'Search'}
          onChange={(e) => searchHandler(e.target.value)}
          prefix={<SearchIcon size={12} color="gray" />}
          fullWidth
          variant={'contained'}
        />
      </InputContainer>
    </FilterItemHeader>
  );
}
