import { RefreshIcon, Divider, Typography } from '@rango-dev/ui';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { Description, Container } from './ProfileProgress.styles';
import { PropTypes } from './ProfileProgress.types';
import { ProgressCard } from '../ProgressCard';
import { ShareProgress } from '../ShareProgress';
import { useTranslation } from 'react-i18next';
import { getProgressCardProps } from './ProfileProgress.helpers';

export function ProfileProgress(props: PropTypes) {
  const { profileSummary } = props;
  const { isMobile } = useWindowDimensions();
  const { t } = useTranslation();
  return (
    <Container>
      <Description>
        <RefreshIcon size={16} color="gray" />
        <Divider direction="horizontal" size={4} />
        <Typography variant={'body'} size={isMobile ? 'medium' : 'large'} color="neutral700">
          {t('Your score will be updated within an hour at most.')}
        </Typography>
      </Description>
      <ProgressCard {...getProgressCardProps(profileSummary)} />
      <ShareProgress loading={profileSummary === null} />
    </Container>
  );
}
