import { ReactElement } from 'react';
import { CardsContainer } from '../ProfileUserData.styles';
import { CardFieldItem } from '../ProfileUserDataCard';
import { Divider, Image, Skeleton, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import {
  ProfileUserDataChainCardProps,
  ProfileUserDataChainsTabProps,
} from './ProfileUserDataChainsTab.types';
import { ChainCardHeader, getChainCardStyle } from './ProfileUserDataChainsTab.styles';
import { CardContainer } from '../ProfileUserDataCard.styles';
import { ProfileUserDataEmptyState } from '../ProfileUserDataEmptyState';
import { numberToString } from '../../../../utils/Numbers';
import BigNumber from 'bignumber.js';
import { CHAIN_BACKGROUND_IMAGE_URL } from './ProfileUserDataChainsTab.constants';
import { Pagination } from '../../../Pagination';
import usePagination from '../../../../hooks/usePagination';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';

export function ProfileUserDataChainCard(props: ProfileUserDataChainCardProps): ReactElement {
  const { t } = useTranslation();

  if ('loading' in props) {
    return (
      <CardContainer>
        <ChainCardHeader>
          <Skeleton variant="circular" width={30} height={30} />
          <Skeleton variant="text" width={110} size="large" />
        </ChainCardHeader>
        <ul>
          {Array.from({ length: 6 }).map((_, index) => (
            <CardFieldItem key={index} loading={true} hasDivider={index !== 5} />
          ))}
        </ul>
      </CardContainer>
    );
  }

  const { blockchainData } = props;

  const fields = [
    {
      id: 'numOfSwaps',
      label: t('Swap Count'),
      value: blockchainData.swapCount,
    },
    {
      id: 'numOfBridge',
      label: t('Bridge Count'),
      value: blockchainData.bridgeCount,
    },
    {
      id: 'totalVolume',
      label: t('Total Volume'),
      value: '$' + numberToString(new BigNumber(blockchainData.volume)),
    },
    {
      id: 'popularToken',
      label: t('Your Popular Token'),
      value: blockchainData.favoriteToken?.symbol,
    },
    {
      id: 'favoriteBadge',
      label: t('Your Favorite Bridge'),
      value: blockchainData.favoriteBridge?.title,
    },
    {
      id: 'favoriteDex',
      label: t('Your Favorite DEX'),
      value: blockchainData.favoriteDex?.title,
    },
  ];

  return (
    <CardContainer
      css={getChainCardStyle(CHAIN_BACKGROUND_IMAGE_URL[blockchainData.blockchain.blockchain])}>
      <ChainCardHeader>
        <Image src={blockchainData.blockchain.logo} size={30} type="circular" />
        <Typography variant="title" size="large">
          {blockchainData.blockchain.displayName}
        </Typography>
      </ChainCardHeader>
      <ul>
        {fields.map((field, index) => (
          <CardFieldItem
            key={field.id}
            label={field.label}
            value={field?.value}
            hasDivider={index !== fields.length - 1}
          />
        ))}
      </ul>
    </CardContainer>
  );
}

export function ProfileUserDataChainsTab(props: ProfileUserDataChainsTabProps): ReactElement {
  const { profileChains, loading } = props;
  const { isMobile } = useWindowDimensions();
  const { paginatedItems, totalPages, page, setPage } = usePagination({
    items: profileChains ?? [],
  });

  if (loading) {
    return (
      <CardsContainer>
        {Array.from({ length: 8 }).map((_, index) => (
          <ProfileUserDataChainCard key={index} loading />
        ))}
      </CardsContainer>
    );
  }

  if (!profileChains?.length) {
    return <ProfileUserDataEmptyState error={props.error} />;
  }

  const itemToRender = isMobile ? profileChains : paginatedItems;

  return (
    <>
      <CardsContainer>
        {itemToRender.map((profileChainItem) => (
          <ProfileUserDataChainCard
            key={profileChainItem.blockchain.blockchain}
            blockchainData={profileChainItem}
          />
        ))}
      </CardsContainer>

      <Divider size={20} />
      <Pagination page={page} totalPages={totalPages} onPageChange={(page) => setPage(page)} />
    </>
  );
}
