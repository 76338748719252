import { ReactElement, useEffect, useRef } from 'react';
import { Container, DesktopContainer, SidebarContainer } from './Profile.styles';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { LogIn, ProfileSummary } from '../../components/Profile';
import { useTranslation } from 'react-i18next';
import { SimpleNotification } from '../../components/SimpleNotification';
import { ProfileUserData } from '../../components/Profile/ProfileUserData';
import { Campaigns } from '../../components/CampaignsSidebar/Campaigns';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { clearAuthError, resetMergeStatus } from '../../state/ProfileSlice';
import { ProfileMobileMode } from '../../components/Profile/ProfileMobileMode';
import { fetchProfileDetails, fetchProfileSummary } from '../../state/ProfileSlice';
import { Cancellable } from '../../types';

function Profile(): ReactElement {
  const isLoggedIn = useAppSelector((state) => state.profile.isLoggedIn);
  const profileAuth = useAppSelector((state) => state.profile.profileAuth);
  const profileSummary = useAppSelector((state) => state.profile.profileSummary);
  const profileDetails = useAppSelector((state) => state.profile.profileDetails);
  const authError = useAppSelector((state) => state.profile.profileAuth.error);
  const accountMerged = useAppSelector((state) => state.profile.profileAuth.accountMerged);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();
  const fetchProfileSummaryPromise = useRef<Cancellable | null>(null);
  const fetchProfileDetailsPromise = useRef<Cancellable | null>(null);

  useEffect(() => {
    if (isLoggedIn && !profileSummary.data) {
      fetchProfileSummaryPromise.current = dispatch(fetchProfileSummary());
    }
  }, [isLoggedIn, profileSummary.data?.id]);

  useEffect(() => {
    if (!profileAuth.loading && isLoggedIn && !profileDetails.data) {
      fetchProfileDetailsPromise.current = dispatch(fetchProfileDetails());
    }
  }, [profileAuth.loading, isLoggedIn, profileDetails.data?.id]);

  useEffect(() => {
    return () => {
      fetchProfileSummaryPromise.current?.abort();
      fetchProfileDetailsPromise.current?.abort();
    };
  }, []);

  return (
    <>
      <Container loggedIn={isLoggedIn}>
        <SimpleNotification
          message={t('An error occurred during login. Please try again.')}
          open={!!authError}
          setOpen={(open) => {
            if (!open) {
              dispatch(clearAuthError());
            }
          }}
          severity="error"
        />
        <SimpleNotification
          message={t('Something went wrong. Please try again.')}
          open={!!profileSummary.error || !!profileDetails.error}
          setOpen={() => {
            //
          }}
          severity="error"
        />
        <SimpleNotification
          message={t('Wallet added to your profile.')}
          open={accountMerged}
          setOpen={(open) => {
            if (!open) {
              dispatch(resetMergeStatus());
            }
          }}
          severity="success"
        />
        {isLoggedIn && !isMobile && (
          <SidebarContainer>
            <Campaigns />
          </SidebarContainer>
        )}
        {isLoggedIn && (
          <>
            {isMobile ? (
              <ProfileMobileMode />
            ) : (
              <DesktopContainer>
                <ProfileSummary />
                <ProfileUserData />
              </DesktopContainer>
            )}
          </>
        )}
        {!isLoggedIn && <LogIn />}
        {!isLoggedIn && !isMobile && (
          <SidebarContainer>
            <Campaigns />
          </SidebarContainer>
        )}
      </Container>
    </>
  );
}

export default Profile;
