import { circleStyles } from './NavLink.styles';
import { IndicatorPropTypes } from './NavLink.types';

export const Indicator = (props: IndicatorPropTypes) => {
  const { activePosition, isFirstRender } = props;

  return activePosition ? (
    <div
      className="absolute bottom-0 flex items-center justify-center"
      style={{
        transform: `translateX(${activePosition}px)`,
        transition: `transform ${!isFirstRender ? 0.3 : 0}s ease-in-out`,
      }}>
      <div className={`h-1 w-1 rounded-full ${circleStyles()}`} />
    </div>
  ) : null;
};
