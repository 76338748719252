import { darkTheme, styled } from '@rango-dev/ui';
import { ImageContainer } from '../WalletItem/WalletItem.styles';

export const ChainItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '$10',
  padding: '$10',
  backgroundColor: '$neutral100',
  borderRadius: '$sm',
  textAlign: 'center',
  cursor: 'pointer',
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral300',
  },
  variants: {
    bordered: {
      true: {
        borderWidth: '1px',
        borderColor: '$info500',
      },
    },
  },
  '&:hover': {
    backgroundColor: '$info100',
    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral100',
    },
  },
});

export const ChainItemImageContainer = styled(ImageContainer, {
  marginLeft: 'auto',
  marginRight: 'auto',
});
