import { useTranslation } from 'react-i18next';
import { Typography, Divider, MoreIcon } from '@rango-dev/ui';
import { MobileMenuContainer, MobileMenuItem, MoreButton } from './MobileMenu.styles';
import { WIDGET_BASE_ROUTE } from '../../constants/navigation';
import { Popover } from '../Popover';
import { PropTypes } from './MobileMenu.types';
import { useLocation } from 'react-router-dom';
import { MobileMenuMoreContent } from './MobileMenuMoreContent';
import { useRef } from 'react';

export const MobileMenu = (props: PropTypes) => {
  const { t } = useTranslation();
  const { links } = props;
  const { pathname } = useLocation();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const mobileLinks = links.filter((link) => !link.isAdditionalLink);

  return (
    <MobileMenuContainer ref={containerRef}>
      {mobileLinks.map((mobileLink) => {
        const isActive =
          pathname.includes(mobileLink.location) ||
          (pathname === '/' && mobileLink.location.includes(WIDGET_BASE_ROUTE));
        return (
          <MobileMenuItem
            id={mobileLink.id}
            isActive={isActive}
            key={mobileLink.title}
            to={mobileLink.location}>
            {mobileLink.icon}
            <Divider size={'2'} />
            <Typography variant="body" size="small" color="$neutral700">
              {mobileLink.title}
            </Typography>
          </MobileMenuItem>
        );
      })}
      <Popover
        sideOffset={0}
        hasArrow={false}
        content={<MobileMenuMoreContent links={links} />}
        align={'end'}
        container={containerRef.current ?? undefined}>
        <MoreButton id="mobile-menu-more-item-btn">
          <MoreIcon color="gray" size={24} />
          <Divider size={'2'} />
          <Typography variant="body" size="small" color="$neutral700">
            {t('More')}
          </Typography>
        </MoreButton>
      </Popover>
    </MobileMenuContainer>
  );
};
