import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';
import { createTheme } from '@rango-dev/ui';
import { customizedThemeTokens } from '@rango-dev/widget-embedded';
import { CUSTOM_WIDGET_THEME } from '../constants/theme.js';

const fullConfig = resolveConfig(tailwindConfig);

const getBreakpointValue = (value: any): number => {
  const screens: any = fullConfig?.theme?.screens;
  if (((screens && Object.keys(screens).length) || 0) > 0)
    return screens[value].slice(0, screens[value].indexOf('px'));
  return -1;
};

export const getCurrentBreakpoint = (): string => {
  let currentBreakpoint = '2xl';
  let biggestBreakpointValue = 999999;
  for (const breakpoint of Object.keys(
    fullConfig?.theme?.screens as Record<'sm' | 'md' | 'lg' | 'xl' | '2xl', string>,
  )) {
    const breakpointValue = getBreakpointValue(breakpoint);
    if (
      parseInt(breakpointValue?.toString() || '0') < biggestBreakpointValue &&
      window.innerWidth <= breakpointValue
    ) {
      biggestBreakpointValue = breakpointValue;
      currentBreakpoint = breakpoint;
    }
  }
  return currentBreakpoint;
};

export const isMobile = (): boolean => {
  return getCurrentBreakpoint() === 'sm';
};

export const getActiveThemeClassName = (isDarkMode: boolean) => {
  const { dark, light } = customizedThemeTokens(CUSTOM_WIDGET_THEME);

  const lightThemeClasses = ['light-theme-ui'];
  const darkThemeClasses = ['dark-theme-ui'];

  if (light) {
    const customizedLightTheme = createTheme(light.id, light.tokens);
    lightThemeClasses.push(customizedLightTheme.className);
  }
  if (dark) {
    const customizedDarkTheme = createTheme(dark.id, dark.tokens);
    darkThemeClasses.push(customizedDarkTheme.className);
  }

  return isDarkMode ? darkThemeClasses.join(' ') : lightThemeClasses.join(' ');
};
