import { ChevronDownIcon, Typography } from '@rango-dev/ui';
import { WalletImages } from './WalletImages';
import { BlockchainImages } from './BlockchainImages';
import { WalletDetailsFilterItemProps, WalletDetailsFilterType } from './WalletDetailsFilter.types';
import { WalletDetailsFilterItemStyle } from './WalletDetailsFilter.styles';
import { Popover } from '../../Popover';
import { ConnectedWalletFilter } from './ConnectedWalletFilter';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { BlockchainFilter } from './BlockchainFilter';

export function WalletDetailsFilterItem(props: WalletDetailsFilterItemProps) {
  const { label, filterType, title, popoverAlign, onClick } = props;
  const { isMobile } = useWindowDimensions();

  const getPopoverContent = () => {
    if (isMobile) {
      return null;
    }

    if (filterType === WalletDetailsFilterType.WALLET) {
      return <ConnectedWalletFilter />;
    }

    if (filterType === WalletDetailsFilterType.BLOCKCHAIN) {
      return <BlockchainFilter />;
    }

    return null;
  };

  return (
    <WalletDetailsFilterItemStyle>
      <Typography variant="body" size="xsmall" color="$neutral600" className="filter-label">
        {label}
      </Typography>

      <Popover sideOffset={5} hasArrow={false} content={getPopoverContent()} align={popoverAlign}>
        <button
          id={`wallet-details-modal-${
            filterType === WalletDetailsFilterType.BLOCKCHAIN ? 'blockchain' : 'wallet'
          }-filter-btn`}
          className="body-filter"
          onClick={() => onClick()}>
          <div className="flex items-center">
            {filterType === WalletDetailsFilterType.BLOCKCHAIN && <BlockchainImages />}
            {filterType === WalletDetailsFilterType.WALLET && <WalletImages />}
            <Typography variant="body" size="xsmall">
              {title}
            </Typography>
          </div>
          <ChevronDownIcon size={10} color="black" />
        </button>
      </Popover>
    </WalletDetailsFilterItemStyle>
  );
}
