import { Middleware } from '@reduxjs/toolkit';
import { RootActions, RootState } from './RootReducer';
import { loadState, saveState } from './helpers';
import { profileSliceInitialState } from './ProfileSlice';
import {
  setHideSmallBalances,
  setHideEmptyWallets,
  setHideUnsupportedTokens,
  WalletDetailsOption,
  resetFilters,
  WALLET_DETAILS_LOCAL_STORAGE_KEY,
} from './WalletDetailsSlice';

const isWalletDetailsPersistActions = (action: any) => {
  if (
    action.type === setHideSmallBalances.type ||
    action.type === setHideEmptyWallets.type ||
    action.type === setHideUnsupportedTokens.type ||
    action.type === resetFilters.type
  ) {
    return true;
  }

  return false;
};

// Middleware to persist only WalletDetailsOption to localStorage
export const walletDetailsMiddleware: Middleware<object, RootState> =
  (storeAPI) => (next) => (action) => {
    const result = next(action);
    if (isWalletDetailsPersistActions(action)) {
      const state = storeAPI.getState().walletDetails;
      const walletDetailsOptions: WalletDetailsOption = {
        hideSmallBalances: state.hideSmallBalances,
        hideEmptyWallets: state.hideEmptyWallets,
        hideUnsupportedTokens: state.hideUnsupportedTokens,
      };
      localStorage.setItem(WALLET_DETAILS_LOCAL_STORAGE_KEY, JSON.stringify(walletDetailsOptions));
    }

    return result;
  };

export const AUTH_PERSIST_KEY = 'isLoggedIn';

export const loadPersistedState = (): Partial<RootState> => {
  const initialState = { ...profileSliceInitialState };
  const authState = loadState<boolean>(AUTH_PERSIST_KEY);
  if (authState) {
    initialState.isLoggedIn = authState;
  }
  return { profile: initialState };
};

export const persistAuthState: Middleware<object, RootState> =
  (store) => (next) => (action: RootActions) => {
    const prevState = store.getState();
    const returnValue = next(action);
    const nextState = store.getState();

    if (prevState.profile.isLoggedIn !== nextState.profile.isLoggedIn) {
      const state = store.getState().profile.isLoggedIn;
      saveState(AUTH_PERSIST_KEY, state);
    }

    return returnValue;
  };
