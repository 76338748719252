import { Typography, WalletIcon } from '@rango-dev/ui';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedIcon, Container, ProfileWalletsButton } from './ProfileSummaryHeader.styles';
import { LogOut } from '../LogOut';
import { ProfileWalletsModal } from '../ProfileWalletsModal';
import { getAddedAccountsToProfile } from '../../../utils/Profile';
import { useProfile } from '../../../hooks/useProfile';
import { useAppSelector } from '../../../hooks/reduxHooks';

export function ProfileSummaryHeader(): ReactElement {
  const { data: profileSummary, loading } = useAppSelector((state) => state.profile.profileSummary);
  const [showMergeWalletModal, setShowMergeWalletModal] = useState(false);
  const { t } = useTranslation();

  const addedAccountsToProfile = profileSummary ? getAddedAccountsToProfile(profileSummary) : [];

  const { compatibleAccounts, readyToSign } = useProfile({
    addedAccounts: addedAccountsToProfile,
  });

  return (
    <Container>
      <ProfileWalletsModal
        accounts={[...compatibleAccounts, ...addedAccountsToProfile]}
        open={showMergeWalletModal}
        onClose={() => setShowMergeWalletModal(false)}
      />
      <div className="title">
        <Typography variant="headline" size="large">
          {t('Welcome back')} 👋
        </Typography>
        <LogOut />
      </div>
      <div>
        <ProfileWalletsButton
          id="profile-page-summary-profile-wallets-btn"
          variant="ghost"
          type="secondary"
          disabled={loading}
          onClick={() => setShowMergeWalletModal(true)}>
          <div className="profile-wallets-button-content">
            <div className="profile-wallets-icon">
              {!loading && readyToSign && <ConnectedIcon />}
              <WalletIcon size={18} color="black" />
            </div>
            {t('Profile wallets')}
          </div>
        </ProfileWalletsButton>
      </div>
    </Container>
  );
}
