import { css, darkTheme, styled } from '@rango-dev/ui';

export const backgroundStyles = css({
  background: `linear-gradient(
    183deg,
    rgba(28, 60, 241, 0.03) 12.95%,
    rgba(28, 60, 241, 0.03) 40.02%
  ),
  $neutral100`,
  [`.${darkTheme} &, &.dark-theme-ui`]: {
    background: `linear-gradient(
      183deg,
      rgba(28, 60, 241, 0.03) 3%,
      rgba(28, 60, 241, 0.03) 40.02%
    ),
    $neutral500`,
  },
});

export const LayoutContainer = styled('div', {
  width: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  height: '100dvh',
  overflow: 'auto !important',
  backgroundImage: 'url(/imgs/banners/footer.svg)',
  backgroundPosition: 'right bottom -10%',
  backgroundRepeat: 'no-repeat no-repeat',
  backgroundSize: 'unset',
  '@media (max-width: 1068px), (max-height: 600px)': {
    background: 'none',
  },
  [`.${darkTheme} &`]: {
    backgroundImage: 'url(/imgs/banners/footer-dark.svg)',
    '@media (max-width: 1068px), (max-height: 600px)': {
      background: 'none',
    },
  },

  variants: {
    hasLogo: {
      false: {
        background: 'none',
        height: 'auto',
        [`.${darkTheme} &`]: {
          background: 'none',
          height: 'auto',
        },
      },
      true: {},
    },
  },
});

export const BodyContainer = styled('div', {
  flexGrow: 1,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
});

export const HeaderContainer = styled('header', {
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'space-between',
  padding: '$30 $20 $30 $20',
  '@sm': {
    padding: '$32 $16 $16 $24',
  },
});

export const MainContainer = styled('main', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '$0 $20',

  '@sm': {
    padding: '$0',
  },
});
