import { Button, Checkbox, Divider, Typography } from '@rango-dev/ui';
import { MoreOptionContainer } from './WalletDetailsFilter.styles';
import { useTranslation } from 'react-i18next';
import { FilterProps } from './WalletDetailsFilter.types';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  setHideEmptyWallets,
  setHideSmallBalances,
  setHideUnsupportedTokens,
  resetFilters,
} from '../../../state/WalletDetailsSlice';
import { WalletDetailFilterAction } from './WalletDetailFilterAction';

export function MoreFilter(props: FilterProps) {
  const { t } = useTranslation();
  const { onClose } = props;
  const { hideEmptyWallets, hideSmallBalances, hideUnsupportedTokens } = useAppSelector(
    (state) => state.walletDetails,
  );
  const dispatch = useAppDispatch();

  return (
    <MoreOptionContainer>
      <div className="header">
        <Typography variant="label" size="medium">
          {t('More')}
        </Typography>
        <Divider size="10" />
        <Button
          onClick={() => dispatch(resetFilters())}
          variant="ghost"
          size="xxsmall"
          id="more-filter-reset-to-default-btn">
          {t('Reset to default')}
        </Button>
      </div>

      <div className="more-item">
        <Checkbox
          id="small-ba lance"
          checked={hideSmallBalances}
          onCheckedChange={(e) => dispatch(setHideSmallBalances(e as boolean))}
          label={
            <Typography variant="body" size="small" color="$neutral700">
              {t('Hide small balances')}
            </Typography>
          }
        />
      </div>
      <div className="more-item">
        <Checkbox
          id="hide-empty"
          checked={hideEmptyWallets}
          onCheckedChange={(e) => dispatch(setHideEmptyWallets(e as boolean))}
          label={
            <Typography variant="body" size="small" color="$neutral700">
              {t('Hide empty wallets')}
            </Typography>
          }
        />
      </div>
      <div className="more-item">
        <Checkbox
          id="hide-unsupported"
          checked={hideUnsupportedTokens}
          onCheckedChange={(e) => dispatch(setHideUnsupportedTokens(e as boolean))}
          label={
            <Typography variant="body" size="small" color="$neutral700">
              {t('Hide unsupported tokens')}
            </Typography>
          }
        />
      </div>
      <WalletDetailFilterAction onClose={onClose} />
    </MoreOptionContainer>
  );
}
