import { darkTheme, keyframes, styled } from '@rango-dev/ui';

export const WalletItemContainer = styled('div', {
  position: 'relative',
  borderRadius: '$sm',
  cursor: 'pointer',
  backgroundColor: '$neutral100',
  padding: '$10',
  marginRight: 'auto',
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'column',
  borderWidth: '1px',
  borderColor: 'transparent',
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral300',
  },
  variants: {
    bordered: {
      true: {
        borderColor: '$info500',
      },
    },
  },
  '&:hover': {
    backgroundColor: '$info100',
    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral100',
    },
  },
});

export const LogoContainer = styled('div', {
  position: 'relative',
});

export const ImageContainer = styled('div', {
  borderRadius: '100%',
  border: '1.5px transparent solid',
  img: {
    borderRadius: '100%',
  },
});

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

export const Spinner = styled('div', {
  position: 'absolute',
  border: '2px solid rgba(0, 0, 0, 0.1)',
  borderTop: '2px solid $info500',
  borderRadius: '$lg',
  width: '$35',
  height: '$35',
  animation: `${spin} 1s linear infinite`,
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
});
