import { NotificationsIcon, useToast } from '@rango-dev/ui';
import { UnreadNotificationsBadge } from './UnreadNotificationsBadge';
import { Notifications as NotificationContent } from '@rango-dev/ui';
import { EventSeverity, useWidget } from '@rango-dev/widget-embedded';
import { Popover } from '../Popover';
import { HeaderIcon } from '../common';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks/reduxHooks';
import { getBlockchainLogo } from '../../utils/Constants';
import { ReactElement } from 'react';
import { WIDGET_BASE_ROUTE } from '../../constants/navigation';
import { useTranslation } from 'react-i18next';

const AUTO_HIDE_NOTIFICATION_INFO_DURATION = 10_000;

export function Notifications(): ReactElement {
  const {
    notifications: { list, clearAll },
    meta: { findToken },
  } = useWidget();

  const hasSeverNotification = !!list.find(
    (notificationItem) => notificationItem.event.messageSeverity === EventSeverity.WARNING,
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { addToast } = useToast();
  const { t } = useTranslation();

  const blockchains = useAppSelector((state) => state.meta.blockchains);

  const handleClick = (requestId: string) => {
    const segments = pathname.split('/').filter(Boolean);
    if (segments.length >= 3 && segments[2] === requestId) {
      addToast({
        title: t('You’re already on the this swap page.'),
        type: 'info',
        position: 'center-top',
        autoHideDuration: AUTO_HIDE_NOTIFICATION_INFO_DURATION,
      });

      return;
    }
    navigate({
      pathname: `${WIDGET_BASE_ROUTE}/swaps/${requestId}`,
      search: `?${searchParams.toString()}`,
    });
  };

  return (
    <Popover
      align="end"
      content={
        <NotificationContent
          list={list}
          onClearAll={clearAll}
          onClickItem={handleClick}
          getTokenImage={(token) => findToken(token)?.image ?? ''}
          getBlockchainImage={(blockchain) => getBlockchainLogo(blockchain, blockchains)}
        />
      }>
      <HeaderIcon
        id="header-notification-icon-btn"
        className="relative mr-2 hidden h-full w-full cursor-pointer items-center justify-center rounded-full px-2.5 sm:flex">
        <NotificationsIcon size={22} color="black" />
        <UnreadNotificationsBadge
          count={list.length}
          type={hasSeverNotification ? 'warning' : 'info'}
        />
      </HeaderIcon>
    </Popover>
  );
}
