import { styled } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$20',
  textAlign: 'center',
  height: '402px',
  '@sm': {
    height: '500px',
  },
});
