import { useTranslation } from 'react-i18next';
import ConnectWalletModal from '../ConnectWalletModal';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import {
  UiEventData,
  UiEventTypes,
  useWallets,
  useWidget,
  widgetEventEmitter,
  WidgetEvents,
} from '@rango-dev/widget-embedded';
import { WalletIcon, AddIcon, Tooltip, Spinner, Typography } from '@rango-dev/ui';
import {
  AddWalletButton,
  ConnectedWalletButton,
  StyledButton,
  MoreWallet,
  ConnectedBadge,
  connectedWalletButtonTooltipContentStyle,
  addMoreWalletsTooltipContentStyle,
} from './ConnectWallet.styles';
import { numberToString } from '../../utils/Numbers';
import { getContainer } from '../../utils/generalFunctions/common';
import { WalletButtonProps } from './ConnectWallet.types';
import { type WalletType } from '../../utils/Constants';
import { useLocation } from 'react-router-dom';
import { GTMEvents } from '../../constants/events';
import { WIDGET_BASE_ROUTE } from '../../constants/navigation';
import { HeaderIcon } from '../common';

const WALLET_DISPLAY_LIMIT = 3;

export const WalletButton: FunctionComponent<WalletButtonProps> = (props) => {
  const { connectedWalletsTypes, setDrawerOpen, isBalanceLoading, sumWalletInUsd } = props;
  const allToken = useAppSelector((state) => state.meta.tokens);
  const { pathname } = useLocation();
  const { getWalletInfo } = useWallets();
  const { t } = useTranslation();
  const { isActiveTab } = useWidget();
  const [showConnectWalletsModal, setShowConnectWalletsModal] = useState(false);
  const shouldCheckIsActiveTab = pathname.includes(WIDGET_BASE_ROUTE);
  const disabled = shouldCheckIsActiveTab && !isActiveTab;

  const handleClick = () => {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: GTMEvents.CLICK_WALLET_CONNECT_TOP,
      });
    } catch (e) {
      // do nothing here
    }
    if (connectedWalletsTypes?.length) {
      setDrawerOpen(true);
    } else {
      setShowConnectWalletsModal(true);
    }
  };

  const closeConnectWalletsModal = () => setShowConnectWalletsModal(false);

  const handleOpenConnectWalletsEvent = (event: UiEventData) => {
    if (event.type === UiEventTypes.CLICK_CONNECT_WALLET) {
      event.payload.preventDefault();
      setShowConnectWalletsModal(true);
    }
  };

  useEffect(() => {
    widgetEventEmitter.on(WidgetEvents.UiEvent, handleOpenConnectWalletsEvent);

    return () => {
      widgetEventEmitter.off(WidgetEvents.UiEvent, handleOpenConnectWalletsEvent);
    };
  }, [widgetEventEmitter]);

  return (
    <div className="flex flex-col md:flex-row">
      <ConnectWalletModal
        open={showConnectWalletsModal}
        onClose={closeConnectWalletsModal}
        disabled={disabled}
      />
      <span className="sm:hidden">
        <HeaderIcon
          id="mobile-header-connect-wallet-btn"
          className="relative mr-2 flex h-full w-full cursor-pointer items-center justify-center rounded-full px-2.5"
          onClick={handleClick}>
          {!!connectedWalletsTypes?.length && <ConnectedBadge />}
          <WalletIcon size={16} color="black" />
        </HeaderIcon>
      </span>
      {!connectedWalletsTypes?.length && (
        <span className="hidden sm:flex md:min-w-40">
          <StyledButton
            id="header-connect-wallet-btn"
            disabled={disabled || allToken.length === 0}
            variant="contained"
            type="primary"
            size="medium"
            onClick={handleClick}
            prefix={<WalletIcon size={18} />}>
            {t('Connect Wallet')}
          </StyledButton>
        </span>
      )}
      {connectedWalletsTypes?.length > 0 && (
        <div className="hidden h-8 sm:flex sm:h-10 md:ml-2 md:min-w-40">
          <div className="flex w-full shadow-none sm:h-10">
            <ConnectedWalletButton
              id="header-connected-wallets-btn"
              type="primary"
              variant="contained"
              disabled={disabled}
              onClick={() => setDrawerOpen(true)}>
              <div className="flex w-full items-center justify-between">
                <div className="flex-0 z-10 flex shrink items-center">
                  <Tooltip
                    open={connectedWalletsTypes.length > WALLET_DISPLAY_LIMIT ? undefined : false}
                    container={getContainer()}
                    sideOffset={16}
                    styles={{
                      content: connectedWalletButtonTooltipContentStyle,
                    }}
                    content={connectedWalletsTypes.map((w, i) => (
                      <span
                        key={i}
                        className={clsx(
                          'inline-block h-4 w-4 rounded-full',
                          i !== 0 ? '-ml-[5px]' : '',
                        )}>
                        <img
                          src={getWalletInfo(w as WalletType).img}
                          className="rounded-full"
                          alt={w}
                        />
                      </span>
                    ))}
                    side="bottom">
                    {connectedWalletsTypes &&
                      connectedWalletsTypes.slice(0, WALLET_DISPLAY_LIMIT).map((w, i) => (
                        <span
                          key={i}
                          className={clsx(
                            'inline-block h-6 w-6 rounded-full',
                            i !== 0 ? '-ml-[0.8rem]' : '',
                          )}>
                          <img
                            src={getWalletInfo(w as WalletType).img}
                            className="rounded-full"
                            alt={w}
                          />
                        </span>
                      ))}
                    {connectedWalletsTypes?.length > WALLET_DISPLAY_LIMIT && (
                      <MoreWallet className="-ml-[0.8rem] flex h-6 w-6 items-center justify-center rounded-full">
                        <Typography variant="label" size="medium">
                          +{connectedWalletsTypes.length - WALLET_DISPLAY_LIMIT}
                        </Typography>
                      </MoreWallet>
                    )}
                  </Tooltip>
                </div>

                <div className="flex shrink-0 grow items-center justify-center">
                  {isBalanceLoading ? (
                    <Spinner />
                  ) : sumWalletInUsd ? (
                    `$${numberToString(sumWalletInUsd)}`
                  ) : null}
                </div>
              </div>
            </ConnectedWalletButton>
            <Tooltip
              content={t('Add more wallets')}
              side="bottom"
              container={getContainer()}
              styles={{
                content: { addMoreWalletsTooltipContentStyle },
              }}>
              <AddWalletButton
                id="header-add-wallet-icon-btn"
                type="primary"
                variant="contained"
                disabled={disabled}
                onClick={() => setShowConnectWalletsModal(true)}>
                <AddIcon size={24} />
              </AddWalletButton>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};
