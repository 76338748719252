import { Button, Divider, Typography } from '@rango-dev/ui';
import { FilterActionStyle } from './WalletDetailsFilter.styles';
import { useTranslation } from 'react-i18next';
import { FilterActionProps } from './WalletDetailsFilter.types';

export function WalletDetailFilterAction(props: FilterActionProps) {
  const { onClose } = props;
  const { t } = useTranslation();

  return (
    <FilterActionStyle>
      <Divider size="20" />
      <Button
        id="wallet-details-modal-done-filter-btn"
        onClick={onClose}
        variant="contained"
        fullWidth
        type="primary"
        size="large">
        <Typography className="done" variant="title" size="small" color="$background">
          {t('Done')}
        </Typography>
      </Button>
    </FilterActionStyle>
  );
}
