import { useState } from 'react';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  blockchainFilterModalStyle,
  FilterContainer,
  filterModalStyle,
  ResetButton,
  StyledButton,
} from './WalletDetailsFilter.styles';
import { Popover } from '../../Popover';
import { WalletDetailsFilterItem } from './WalletDetailsFilterItem';
import { WalletDetailsFilterType } from './WalletDetailsFilter.types';
import { ConnectedWalletFilter } from './ConnectedWalletFilter';
import { useTranslation } from 'react-i18next';
import { BlockchainFilter } from './BlockchainFilter';
import { Button, ChevronLeftIcon, IconButton, MoreIcon } from '@rango-dev/ui';
import { MoreFilter } from './MoreFilter';
import { Modal } from '../../Modal';
import { useWalletDetails } from '../../../hooks/useWalletDetails';
import { resetFilters } from '../../../state/WalletDetailsSlice';

export function WalletDetailsFilter() {
  const { isMobile } = useWindowDimensions();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { excludedBlockchains, excludedWallets } = useAppSelector((state) => state.walletDetails);

  const {
    toggleExcludedAllBlockchain,
    toggleExcludedAllWallet,
    connectedWallets,
    validBlockchains,
  } = useWalletDetails();

  const [showBlockchainFilterModal, setShowBlockchainFilterModal] = useState<boolean>(false);
  const [showWalletFilterModal, setShowWalletFilterModal] = useState<boolean>(false);
  const [showMoreFilterModal, setShowMoreFilterModal] = useState<boolean>(false);

  const getWalletFilterTitle = () => {
    if (excludedWallets?.length === 0) {
      return t('All Wallets');
    }

    const selectedWalletCount = connectedWallets?.length - excludedWallets?.length;

    if (isMobile) {
      return t(`{{selectedWalletCount}} Wallet${selectedWalletCount > 1 ? 's' : ''}`, {
        selectedWalletCount,
      });
    }

    return t('{{selectedWalletCount}}/{{connectedWalletCount}} Wallets', {
      selectedWalletCount,
      connectedWalletCount: connectedWallets.length,
    });
  };

  const blockchainFilterTitle = () => {
    if (excludedBlockchains?.length === 0) {
      return t('All chains');
    }

    const selectedBlockchainCount = validBlockchains?.length - excludedBlockchains?.length;

    if (isMobile) {
      return t(`{{selectedBlockchainCount}} Chain${selectedBlockchainCount > 1 ? 's' : ''}`, {
        selectedBlockchainCount,
      });
    }

    return t('{{selectedBlockchainCount}}/{{validBlockchainCount}} Chains', {
      selectedBlockchainCount,
      validBlockchainCount: validBlockchains.length,
    });
  };

  return (
    <>
      <FilterContainer>
        <WalletDetailsFilterItem
          filterType={WalletDetailsFilterType.WALLET}
          label={t('Filter by Wallets')}
          title={getWalletFilterTitle()}
          popoverAlign="start"
          onClick={() => setShowWalletFilterModal(true)}
        />

        <WalletDetailsFilterItem
          filterType={WalletDetailsFilterType.BLOCKCHAIN}
          label={t('Filter by Blockchain')}
          title={blockchainFilterTitle()}
          popoverAlign="end"
          onClick={() => setShowBlockchainFilterModal(true)}
        />
        <Popover
          sideOffset={5}
          hasArrow={false}
          content={isMobile ? null : <MoreFilter />}
          align="end">
          <button
            id="wallet-details-modal-more-filter-icon-btn"
            className="more-option"
            onClick={() => setShowMoreFilterModal(true)}>
            <MoreIcon size={16} color="black" />
          </button>
        </Popover>
      </FilterContainer>

      {isMobile && (
        <>
          <Modal
            anchor="bottom"
            open={showBlockchainFilterModal}
            hasCloseIcon={false}
            suffix={
              <StyledButton
                id={`wallet-details-blockchain-filter-modal-${
                  excludedBlockchains?.length === 0 ? 'deselect-all' : 'select-all'
                }-btn`}
                variant="ghost"
                size="xxsmall"
                onClick={() => toggleExcludedAllBlockchain()}>
                {excludedBlockchains?.length === 0 ? t('Deselect all') : t('Select all')}
              </StyledButton>
            }
            prefix={
              <IconButton
                aria-label="add"
                size="xsmall"
                onClick={() => setShowBlockchainFilterModal(false)}
                variant="ghost">
                <ChevronLeftIcon size={16} color="gray" />
              </IconButton>
            }
            title={t('Chains') || 'Chains'}
            styles={blockchainFilterModalStyle}
            onClose={() => setShowBlockchainFilterModal(false)}>
            <BlockchainFilter onClose={() => setShowBlockchainFilterModal(false)} />
          </Modal>

          <Modal
            anchor="bottom"
            open={showWalletFilterModal}
            hasCloseIcon={false}
            suffix={
              <StyledButton
                id={`wallet-details-wallet-filter-modal-${
                  excludedBlockchains?.length === 0 ? 'deselect-all' : 'select-all'
                }-btn`}
                variant="ghost"
                size="xxsmall"
                onClick={() => toggleExcludedAllWallet()}>
                {excludedWallets?.length === 0 ? t('Deselect all') : t('Select all')}
              </StyledButton>
            }
            prefix={
              <IconButton
                aria-label="add"
                size="xsmall"
                onClick={() => setShowWalletFilterModal(false)}
                variant="ghost">
                <ChevronLeftIcon size={16} color="gray" />
              </IconButton>
            }
            title={t('Wallets') || 'Wallets'}
            styles={filterModalStyle}
            onClose={() => setShowWalletFilterModal(false)}>
            <ConnectedWalletFilter onClose={() => setShowWalletFilterModal(false)} />
          </Modal>

          <Modal
            anchor="bottom"
            open={showMoreFilterModal}
            hasCloseIcon={false}
            prefix={
              <IconButton
                aria-label="add"
                size="xsmall"
                onClick={() => setShowMoreFilterModal(false)}
                variant="ghost">
                <ChevronLeftIcon size={16} color="gray" />
              </IconButton>
            }
            suffix={
              <ResetButton
                onClick={() => dispatch(resetFilters())}
                variant="ghost"
                size="xsmall"
                id="more-filter-reset-to-default-btn">
                {t('Reset to default')}
              </ResetButton>
            }
            title={t('More') || 'More'}
            styles={filterModalStyle}
            onClose={() => setShowMoreFilterModal(false)}>
            <MoreFilter onClose={() => setShowMoreFilterModal(false)} />
          </Modal>
        </>
      )}
    </>
  );
}
