import { Typography, Divider } from '@rango-dev/ui';
import { MoreContainer, MoreDivider, MoreItem, SocialContainer } from './MobileMenu.styles';
import { PropTypes } from './MobileMenu.types';
import { Socials } from '../SocialLinks';

export const MobileMenuMoreContent = (props: PropTypes) => {
  const { links } = props;

  const moreLinks = links.filter((link) => link.isAdditionalLink);

  return (
    <MoreContainer>
      {moreLinks.map((moreLink) => {
        const { location, title, icon, id } = moreLink;

        return (
          <>
            <MoreItem id={id} target="_blank" rel="noreferrer" key={title} to={location}>
              {icon}
              <Divider direction="horizontal" size={'4'} />
              <Typography variant="body" size="medium">
                {title}
              </Typography>
            </MoreItem>
          </>
        );
      })}

      <Divider size={'4'} />
      <MoreDivider />
      <Divider size={'10'} />
      <SocialContainer>
        <Socials />
      </SocialContainer>
    </MoreContainer>
  );
};
