import { darkTheme, styled } from '@rango-dev/ui';

export const Badge = styled('div', {
  variants: {
    type: {
      warning: {
        backgroundColor: '$error500',
      },
      info: {
        backgroundColor: '$secondary500',
        [`.${darkTheme} &`]: {
          backgroundColor: '$secondary250',
        },
      },
    },
  },
});
