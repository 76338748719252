import { useAppSelector } from '../../hooks/reduxHooks';
import { WalletDetailItemType, WalletDetailsProps } from './WalletDetails.types';
import { StyledDivider, WalletDetailsContainer } from './WalletDetails.styles';
import { WalletDetailsItem } from './WalletDetailsItem';
import { EmptyState } from './EmptyState';
import { WalletDetailsHeader } from './WalletDetailsHeader';
import { WalletDetailsFilter } from './WalletDetailsFilter';
import { compareEmptyWalletDetailItem, getUsdValue, MIN_USD_VALUE } from './WalletDetails.helpers';
import { useWalletDetailsItem } from '../../hooks/useWalletDetailsItem';
import { VirtualizedList } from '@rango-dev/ui';

export function WalletDetails(props: WalletDetailsProps) {
  const { onClose } = props;
  const { walletDetailItems } = useWalletDetailsItem();
  const {
    hideEmptyWallets,
    hideSmallBalances,
    hideUnsupportedTokens,
    excludedBlockchains,
    excludedWallets,
  } = useAppSelector((state) => state.walletDetails);

  const secondaryLoading = walletDetailItems.some(
    (item) => !item?.account?.loading || item?.account?.balances?.length,
  );

  const applyHideEmptyWalletFilter = (walletDetailItem: WalletDetailItemType) => {
    const { balances } = walletDetailItem.account;
    return !secondaryLoading || !hideEmptyWallets || balances?.length;
  };

  const applyHideSmallBalancesFilter = (walletDetailItem: WalletDetailItemType) => {
    const { balances } = walletDetailItem.account;
    return (
      !secondaryLoading ||
      !hideEmptyWallets ||
      !hideSmallBalances ||
      balances?.some((balanceItem) => getUsdValue(balanceItem)?.gte(MIN_USD_VALUE))
    );
  };

  const applyHideUnsupportedTokensFilter = (walletDetailItem: WalletDetailItemType) => {
    const { balances } = walletDetailItem.account;
    return (
      !secondaryLoading ||
      !hideEmptyWallets ||
      !hideUnsupportedTokens ||
      balances?.some((balanceItem) => balanceItem?.logo)
    );
  };

  const applyExcludedBlockchainsFilter = (walletDetailItem: WalletDetailItemType) => {
    const { name: blockChainName } = walletDetailItem;
    return !excludedBlockchains.includes(blockChainName);
  };

  const applyExcludedWalletsFilter = (walletDetailItem: WalletDetailItemType) => {
    const { walletType } = walletDetailItem;
    return walletType.some((walletTypeItem) => !excludedWallets.includes(walletTypeItem));
  };

  const applyMainFilters = (walletDetailItem: WalletDetailItemType) => {
    return (
      applyExcludedBlockchainsFilter(walletDetailItem) &&
      applyExcludedWalletsFilter(walletDetailItem)
    );
  };

  const applyEmptyWalletFilters = (walletDetailItem: WalletDetailItemType) => {
    return (
      applyHideEmptyWalletFilter(walletDetailItem) &&
      applyHideSmallBalancesFilter(walletDetailItem) &&
      applyHideUnsupportedTokensFilter(walletDetailItem)
    );
  };

  // Apply only the main filters including Wallet and Blockchain
  const mainFilteredItems = walletDetailItems.filter(applyMainFilters);

  // Apply the rest of the filters including empty wallets, small balances and unsupported tokens
  const finalFilteredItems = mainFilteredItems
    .filter(applyEmptyWalletFilters)
    .sort(compareEmptyWalletDetailItem);

  const emptyWalletsCount = mainFilteredItems.length - finalFilteredItems.length;

  // Determine if the empty state should be shown
  const showEmptyState = !(finalFilteredItems && finalFilteredItems.length > 0);

  return (
    <WalletDetailsContainer>
      <WalletDetailsHeader onClose={onClose} />
      <StyledDivider size={'2'} />

      <WalletDetailsFilter />

      <div className="blockchains-container">
        {!showEmptyState && (
          <VirtualizedList
            totalCount={finalFilteredItems.length}
            itemContent={(index) => {
              const walletDetailItem = finalFilteredItems[index];
              return (
                <WalletDetailsItem
                  walletDetailItem={walletDetailItem}
                  key={`${walletDetailItem.name}-${walletDetailItem.account.address}`}
                />
              );
            }}></VirtualizedList>
        )}

        {showEmptyState && <EmptyState emptyWalletsCount={emptyWalletsCount} />}
      </div>
    </WalletDetailsContainer>
  );
}
