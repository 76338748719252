import { useTranslation } from 'react-i18next';
import { PaginationButton, PaginationContainer, PaginationStatus } from './Pagination.styles';
import { PaginationProps } from './Pagination.types';
import { ChevronLeftIcon, ChevronRightIcon, FirstIcon, LastIcon, Typography } from '@rango-dev/ui';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export function Pagination(props: PaginationProps) {
  const { page, totalPages, onPageChange } = props;
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();
  const showPagination = !isMobile && totalPages > 1;

  if (!showPagination) {
    return null;
  }

  const isFirstPage = page === 0;
  const isLastPage = page === totalPages - 1;

  const handleFirstClick = () => onPageChange(0);
  const handlePreviousClick = () => onPageChange(page - 1);
  const handleNextClick = () => onPageChange(page + 1);
  const handleLastClick = () => onPageChange(totalPages - 1);

  return (
    <PaginationContainer>
      <PaginationButton disabled={isFirstPage} onClick={handleFirstClick}>
        <FirstIcon color={isFirstPage ? 'gray' : 'white'} />
      </PaginationButton>
      <PaginationButton disabled={isFirstPage} onClick={handlePreviousClick}>
        <ChevronLeftIcon color="black" />
      </PaginationButton>

      <PaginationStatus>
        <Typography variant="body" size="small">
          {t('Page {{page}} of {{total}}', { page: page + 1, total: totalPages })}
        </Typography>
      </PaginationStatus>

      <PaginationButton disabled={isLastPage} onClick={handleNextClick}>
        <ChevronRightIcon color="black" />
      </PaginationButton>
      <PaginationButton disabled={isLastPage} onClick={handleLastClick}>
        <LastIcon color="black" />
      </PaginationButton>
    </PaginationContainer>
  );
}
