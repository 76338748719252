import { Divider } from '@rango-dev/ui';
import { t } from 'i18next';
import { useState } from 'react';
import { LogOut } from '../LogOut';
import {
  Container,
  TabsContainer,
  Header,
  ProfileWalletsContainer,
  StyledTabs,
} from './ProfileMobileMode.styles';
import { ProfileSummaryMobile } from './ProfileSummaryMobile';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ProfileUserData } from '../ProfileUserData';
import { ProfileWallets } from '../ProfileWallets';
import { useProfile } from '../../../hooks/useProfile';
import { Info } from './Info';
import { getAddedAccountsToProfile } from '../../../utils/Profile';

export function ProfileMobileMode() {
  const profileSummary = useAppSelector((state) => state.profile.profileSummary);
  const tabs = {
    summary: { id: 'summary', title: t('Summary') },
    stats: { id: 'stats', title: t('Stats') },
    profileWallets: { id: 'profile-wallets', title: t('Profile Wallets') },
  };
  const [selectedTab, setSelectedTab] = useState(tabs.summary.id);

  const { compatibleAccounts } = useProfile({
    addedAccounts: profileSummary.data?.wallets,
  });

  const addedAccountsToProfile = profileSummary.data
    ? getAddedAccountsToProfile(profileSummary.data)
    : [];

  const renderContent = () => {
    switch (selectedTab) {
      case tabs.summary.id:
        return <ProfileSummaryMobile />;

      case tabs.stats.id:
        return <ProfileUserData />;
      case tabs.profileWallets.id:
        return (
          <ProfileWalletsContainer>
            <Info
              title={t('Your Profile Wallets')}
              description={t('Link your wallets to your profile to increase your score.')}
            />
            <Divider direction="vertical" size={24} />
            <ProfileWallets accounts={[...compatibleAccounts, ...addedAccountsToProfile]} />
          </ProfileWalletsContainer>
        );

      default:
        return <ProfileSummaryMobile />;
    }
  };
  return (
    <Container>
      <TabsContainer>
        <StyledTabs
          items={Object.values(tabs)}
          onChange={(item) => {
            if (typeof item.id === 'string') {
              setSelectedTab(item.id);
            }
          }}
          type="secondary"
          value={selectedTab}
        />
      </TabsContainer>
      <Header>
        <LogOut />
      </Header>
      {renderContent()}
    </Container>
  );
}
