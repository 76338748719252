import { Divider } from '@rango-dev/ui';
import { Info } from './Info';
import {
  InfoContainer,
  ProgressCardContainer,
  ProfileSummaryItemsContainer,
} from './ProfileSummaryMobile.styles';
import { ProfileProgress } from '../ProfileProgress';
import { ProfileSummaryItems } from '../ProfileSummaryItems';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { ProfileSummaryEmptyState } from '../ProfileSummary/ProfileSummaryEmptyState';
import { Campaigns } from '../../CampaignsSidebar/Campaigns';

export function ProfileSummaryMobile() {
  const {
    data: profileSummary,
    loading,
    error,
  } = useAppSelector((state) => state.profile.profileSummary);
  const showError = !loading && !!error;
  const { t } = useTranslation();

  return (
    <>
      <ProgressCardContainer>
        <InfoContainer>
          <Info title={t('Your Profile')} description={t('Check your score and ranking')} />
        </InfoContainer>
        <Divider direction="vertical" size={24} />
        {showError ? (
          <ProfileSummaryEmptyState />
        ) : (
          <ProfileProgress profileSummary={profileSummary} />
        )}
      </ProgressCardContainer>
      <Divider direction="vertical" size={20} />
      <ProfileSummaryItemsContainer>
        {!showError && <ProfileSummaryItems />}
      </ProfileSummaryItemsContainer>
      <Divider direction="vertical" size={10} />
      <Campaigns />
      <Divider direction="vertical" size={10} />
      <Divider direction="vertical" size={16} />
    </>
  );
}
