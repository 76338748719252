import { darkTheme, styled, Tabs } from '@rango-dev/ui';
export const Container = styled('div', {
  width: '100%',
  paddingBottom: '$20',
});

export const TabsContainer = styled('div', {
  paddingBottom: '$20',
});

export const Header = styled('div', {
  background: '$background',
  borderRadius: '$primary $primary 0 0',
  padding: '$10 $20',
  borderBottom: '0.5px solid $neutral300',
});

export const ProfileWalletsContainer = styled('div', {
  background: '$background',
  borderRadius: '0 0 $primary $primary',
  padding: '$20',
  position: 'relative',
});

export const StyledTabs = styled(Tabs, {
  '& ._tab': {
    fontWeight: '$regular',
    '&[data-active="false"]': {
      color: '$neutral600',
      [`.${darkTheme} &`]: {
        color: '$neutral800',
      },
    },
  },
});
