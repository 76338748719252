import { darkTheme, styled } from '@rango-dev/ui';

export const HeaderIcon = styled('div', {
  '@md': {
    backgroundColor: '$neutral100',
    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral300',
    },
    '&:hover': {
      backgroundColor: '$secondary200',
      [`.${darkTheme} &`]: {
        backgroundColor: '$neutral550',
      },
    },
  },

  width: '$32',
  height: '$32',

  '& svg': {
    minWidth: '$18',
    minHeight: '$18',
  },

  '@sm': {
    width: '44px',
    height: '44px',
  },
});
