export enum SettingStates {
  LANGUAGE = 'language',
  THEME = 'theme',
  EXPERIMENTAL_FEATURES = 'experimental features',
}

export type SettingItemsType = {
  title: string;
  id: string;
  name: SettingStates;
  showValue?: boolean;
  value?: string;
  icon: JSX.Element;
};

export type SettingItemProps = SettingItemsType & {
  onClick: (name: SettingStates) => void;
};
export interface SettingOverlayProps {
  selectedItem?: SettingItemsType;
  onBack: () => void;
  onClose?: () => void;
}
export interface SettingsPanelProps {
  isMobile?: boolean;
  openModal?: boolean;
  setOpenModal?: (open: boolean) => void;
  setOpenPopover?: (open: boolean) => void;
}

export interface SettingsModalPanelProps {
  selected: SettingStates | null;
  onItemClick: (name: SettingStates) => void;
  onClose: () => void;
  open?: boolean;
  setSelected: (value: SettingStates | null) => void;
  items: SettingItemsType[];
}
