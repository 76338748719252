import { styled, rangoDarkColors, Typography, theme, darkTheme, Skeleton } from '@rango-dev/ui';
import { CONTAINER_MAX_WIDTH } from './ProgressCard.constants';

export const Container = styled('div', {
  borderRadius: '$primary',
  padding: '$20 $20 $10 $20',
  backgroundSize: '100% 100%',
  backgroundPosition: '0px 0px',
  backgroundImage: `radial-gradient(50% 75% at 50% 100%, #001A8FFF 2%, ${rangoDarkColors.secondary850} 100%)`,
  minWidth: '250px',
  maxWidth: `${CONTAINER_MAX_WIDTH}px`,
  width: '100%',
  '@sm': {
    padding: '$30',
    minWidth: '387px',
  },
  '& li': {
    borderColor: '#2f3f72 !important',
    height: '31px',
    '@sm': {
      height: '42px',
    },
  },
});

export const Score = styled('div', {
  borderRadius: '$xm',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  border: '#0A7FC1 solid 1px',
  background: 'linear-gradient(0deg, #0559a7 0%, #529ceb 100%)',
  height: '99px',
  '@sm': {
    padding: '$25 0',
    height: '135px',
    alignItems: 'unset',
  },
  '& .score-item': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    flex: 1,
  },
  '& .score-item > svg': {
    $$color: '$colors$background',
    [`.${darkTheme} &`]: {
      $$color: '$colors$foreground',
    },
    color: '$$color',
  },
});

export const ListItemValue = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$4',
});

export const ItemTitle = styled('span', {
  color: theme.colors.background,
  fontSize: '$10',
  fontWeight: '$regular',
  lineHeight: '14px',
  '@sm': {
    fontSize: '$12',
    fontWeight: '$regular',
    lineHeight: '$16',
  },
});

export const ScoreValue = styled(Typography, {
  [`.${darkTheme} &`]: {
    color: '$foreground',
  },
});

export const ScoreItemValue = styled(Typography, {
  [`.${darkTheme} &`]: {
    color: '$foreground',
  },
});

export const StyledSkeleton = styled(Skeleton, {
  borderRadius: '999px !important',
  [`.${darkTheme} &`]: {
    backgroundImage:
      'linear-gradient(90deg, $colors$secondary200 20%, rgba(200, 226, 255, 0.20) 70%, $colors$secondary200 100%)',
  },
});
