import { Typography } from '@rango-dev/ui';
import { ChainImageContainer } from './WalletDetailsFilter.styles';

import { useWallets } from '@rango-dev/widget-embedded';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useWalletDetails } from '../../../hooks/useWalletDetails';

export function WalletImages() {
  const { getWalletInfo } = useWallets();
  const { excludedWallets } = useAppSelector((state) => state.walletDetails);
  const { connectedWallets } = useWalletDetails();
  const { isMobile } = useWindowDimensions();
  const LOGO_DISPLAY_LIMIT = isMobile ? 1 : 3;
  const selectedWallets = connectedWallets.filter(
    (walletItem) => !excludedWallets.includes(walletItem),
  );

  return (
    <ChainImageContainer>
      {selectedWallets &&
        selectedWallets.slice(0, LOGO_DISPLAY_LIMIT).map((walletItem) => {
          const walletInfoItem = getWalletInfo(walletItem);

          return (
            <span className="image-container" key={walletItem}>
              <img src={walletInfoItem?.img} alt={walletInfoItem?.name} />
            </span>
          );
        })}
      {selectedWallets && selectedWallets?.length > LOGO_DISPLAY_LIMIT && (
        <span className="image-container">
          <Typography className="more-item" variant="body" size="xsmall">
            +{selectedWallets.length - LOGO_DISPLAY_LIMIT}
          </Typography>
        </span>
      )}
    </ChainImageContainer>
  );
}
