import { BullhornIcon } from '@rango-dev/ui';
import { HeaderIcon } from '../common';

export const WhatsNew = () => (
  <HeaderIcon
    id="header-news-icon-btn"
    className="beamerTrigger relative mr-2 flex h-full w-full cursor-pointer items-center justify-center rounded-full px-2.5">
    <BullhornIcon size={20} color="black" />
  </HeaderIcon>
);
