import { Divider } from '@rango-dev/ui';
import { ReactElement, useState } from 'react';
import { Container, TabsContainer, ContentContainer, StyledTabs, HeaderContainer } from './ProfileUserData.styles';
import { useTranslation } from 'react-i18next';
import { getContainer } from '../../../utils/generalFunctions/common';
import { ProfileUserDataDexTab } from './ProfileUserDataDexTab';
import { ProfileUserDataCampaignTab } from './ProfileUserDataCampaignTab';
import { ProfileUserDataChainsTab } from './ProfileUserDataChainsTab';
import { ProfileUserDataBridgesTab } from './ProfileUserDataBridgesTab';
import { ProfileUserDataTabs } from './ProfileUserData.types';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { sortByTotalVolume } from './ProfileUserData.helpers';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { Info } from '../ProfileMobileMode/Info';
import { DailyChart } from './DailyChart';

export function ProfileUserData(): ReactElement {
  const {
    data: profileDetails,
    loading,
    error,
  } = useAppSelector((state) => state.profile.profileDetails);
  const { t } = useTranslation();
  const logInInProgress = useAppSelector((state) => state.profile.profileAuth.loading);

  const [selectedTab, setSelectedTab] = useState<ProfileUserDataTabs>(ProfileUserDataTabs.Chains);
  const { isMobile } = useWindowDimensions();

  const UserDataTabs = [
    { id: ProfileUserDataTabs.Chains, title: t('Chains') },
    { id: ProfileUserDataTabs.Bridges, title: t('Bridges') },
    { id: ProfileUserDataTabs.DEXes, title: t('DEXes') },
    { id: ProfileUserDataTabs.Campaigns, title: t('Campaigns') },
  ];

  const renderTabContent = () => {
    switch (selectedTab) {
      case ProfileUserDataTabs.Chains:
        return (
          <ProfileUserDataChainsTab
            profileChains={profileDetails?.chains.slice().sort(sortByTotalVolume)}
            loading={logInInProgress || loading}
            error={!!error}
          />
        );
      case ProfileUserDataTabs.Bridges:
        return (
          <ProfileUserDataBridgesTab
            profileBridges={profileDetails?.bridges.slice().sort(sortByTotalVolume)}
            loading={logInInProgress || loading}
            error={!!error}
          />
        );

      case ProfileUserDataTabs.DEXes:
        return (
          <ProfileUserDataDexTab
            profileDexes={profileDetails?.dexes.slice().sort(sortByTotalVolume)}
            loading={logInInProgress || loading}
            error={!!error}
          />
        );

      case ProfileUserDataTabs.Campaigns:
        return <ProfileUserDataCampaignTab />;

      default:
        return <ProfileUserDataChainsTab error={!!error} />;
    }
  };

  const getDailyData = () => {
    switch (selectedTab) {
      case ProfileUserDataTabs.Chains:
        return profileDetails?.chainsDaily;
      case ProfileUserDataTabs.Bridges:
        return profileDetails?.bridgesDaily;
      case ProfileUserDataTabs.DEXes:
        return profileDetails?.dexesDaily;
      case ProfileUserDataTabs.Campaigns:
        return undefined;
      default:
        return undefined;
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <Info
          title={t('Your Data')}
          description={t('View your stats in every chain, bridge, dex and campaign.')}
        />
        <DailyChart type={selectedTab} loading={loading} dailyData={getDailyData()} />
      </HeaderContainer>
      <TabsContainer>
        <StyledTabs
          container={getContainer()}
          items={UserDataTabs}
          value={selectedTab}
          onChange={(item) => setSelectedTab(item.id as ProfileUserDataTabs)}
          type={isMobile ? 'bordered' : 'secondary'}
          isMobile={isMobile}
          scrollable={isMobile}
          scrollButtons={false}
          borderRadius="small"
        />
      </TabsContainer>
      <ContentContainer>{renderTabContent()}</ContentContainer>
    </Container>
  );
}
