import { Divider, IconButton, darkTheme, styled } from '@rango-dev/ui';

export const WalletDetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$20',
  width: '100%',
  height: '100%',
  backgroundColor: '$background',
  padding: '$30 $25',
  overflow: 'hidden',

  '.blockchains-container': {
    display: 'flex',
    flexDirection: 'column',
    gap: '$20',
    height: '100%',
    overflowY: 'auto',

    'svg:hover': {
      color: '$secondary550',
      [`.${darkTheme} &`]: {
        color: '$secondary500',
      },
    },
  },
});

export const Header = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$20',

  '.total-amount': {
    height: '44px',
  },

  '.buy-crypto': {
    display: 'none',
    // display: 'flex',
    alignItems: 'center',
    padding: '$5 $10',
    borderRadius: '$primary',
    backgroundColor: '$secondary150',

    [`.${darkTheme} &`]: {
      backgroundColor: '$secondary800',

      '._typography, & svg': {
        color: '$secondary250',
      },
    },
  },

  '.icons-container': {
    '& svg': {
      [`.${darkTheme} &`]: {
        color: '$neutral800',
      },
    },
  },
});

export const CardHeader = styled('div', {
  backgroundColor: '$neutral200',
  borderRadius: '$xs',
  padding: '$5 $10',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral400',
  },

  '& svg': {
    [`.${darkTheme} &`]: {
      color: '$neutral800',
    },
  },

  '.border-divider': {
    width: '1px',
    height: '$20',
    backgroundColor: '$neutral400',
    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral900',
    },
    marginRight: '$8',
    marginLeft: '$5',
  },
});

export const CardToken = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 51px',
  padding: '$10',

  [`.${darkTheme} &`]: {
    '.subtitle': {
      color: '$neutral800',
    },
  },

  '.swap-button': {
    '& svg': {
      [`.${darkTheme} &`]: {
        color: '$neutral800',
      },
    },
  },
});

export const StyledCopyWalletButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  padding: '$2 $8',

  '& ._typography': {
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },

  'svg:hover': {
    color: '$secondary550',
    [`.${darkTheme} &`]: {
      color: '$secondary500',
    },
  },
});

export const StyledDivider = styled(Divider, {
  backgroundColor: '$neutral300',
});

export const NotFoundContainer = styled('div', {
  display: 'flex',
  padding: '$20',
  justifyContent: 'center',
  alignItems: 'center',

  '.error-icon-container': {
    width: '$16',
    height: '$16',
    borderRadius: '$lg',
    padding: '$2',
    backgroundColor: '$error300',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`.${darkTheme} &`]: {
      backgroundColor: '$error600',
    },

    svg: {
      color: '$error500 !important',
    },
  },
});

export const BlockchainSkeletonContainer = styled('div', {
  padding: '$15 $10',
  '.token-item': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '.info-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
});

export const EmptyStateContainer = styled('div', {
  height: '100%',
  width: '100%',
  padding: '$24',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '.image-container': {
    width: '100%',
    height: '246px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [`.${darkTheme} &`]: {
      height: '229px',
    },
  },

  '.description': {
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },

  '.text-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    button: {
      width: '220px',
      height: '32px',
      fontSize: '$14',
    },

    '@md': {
      button: {
        width: '280px',
        height: '40px',
      },
    },
  },
});

export const StyledRefreshButton = styled(IconButton, {
  padding: '$0 !important',
  variants: {
    loading: {
      true: {
        animation: 'spin 1s linear infinite',
      },
    },
  },
});

export const AddTokenContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  bottom: '-5px',
  right: '-5px',
  width: '12px',
  height: '12px',
  borderRadius: '6px',
  backgroundColor: '$background',
  '.icon-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    width: '11px',
    height: '11px',
    backgroundColor: '$neutral100',
    '& svg': {
      [`.${darkTheme} &`]: {
        color: '$neutral800',
      },
    },
  },
});
