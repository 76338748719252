import { ReactElement } from 'react';
import { CardFieldItemProps, Field } from './ProfileUserDataCard.types';
import { Divider, Skeleton, Typography } from '@rango-dev/ui';
import {
  CardContainer,
  CardFieldItemContainer,
  CardFieldItemSkeletonContainer,
} from './ProfileUserDataCard.styles';
import { Tooltip } from '../Tooltip';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useAppSelector } from '../../../hooks/reduxHooks';

export function CardFieldItem(props: CardFieldItemProps): ReactElement {
  const { isMobile } = useWindowDimensions();

  if ('loading' in props) {
    return (
      <CardFieldItemSkeletonContainer>
        <Skeleton variant="text" width={120} size="large" />
        <Skeleton variant="text" width={50} size="large" />
      </CardFieldItemSkeletonContainer>
    );
  }

  const { label, value, description, labelColor, hasDivider = true } = props;

  return (
    <CardFieldItemContainer
      hasDivider={hasDivider}
      title={
        <div className="label">
          <Typography
            variant={isMobile ? 'label' : 'title'}
            size={isMobile ? 'medium' : 'xmedium'}
            color={labelColor}>
            {label}
          </Typography>
          {description && (
            <>
              <Divider direction="horizontal" size={4} />
              <Divider direction="horizontal" size={2} />
              <Tooltip description={description} />
            </>
          )}
        </div>
      }
      end={
        <Typography align="right" variant="title" size="small">
          {value === undefined ? '-' : value}
        </Typography>
      }
    />
  );
}

export function ProfileUserDataCard(props: {
  header: ReactElement;
  fields: Field[];
}): ReactElement {
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { header, fields } = props;

  return (
    <CardContainer>
      {header}
      <div>
        {fields.map((field, index) => (
          <CardFieldItem
            key={field.id}
            label={field.label}
            value={field.value}
            labelColor={isDarkMode ? 'neutral900' : 'neutral700'}
            description={field.description}
            hasDivider={index !== fields.length - 1}
          />
        ))}
      </div>
    </CardContainer>
  );
}
