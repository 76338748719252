import { Button, darkTheme, ListItem, rangoDarkColors, styled, Typography } from '@rango-dev/ui';

export const Container = styled(ListItem, {
  height: '54px',
  '& ._description': {
    color: '$neutral700 !important',
    [`.${darkTheme} &`]: {
      color: '$neutral900 !important',
    },
  },
});

export const TitleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const BlockchainDisplayName = styled(Typography, {
  [`.${darkTheme} &`]: {
    color: '$neutral800 !important',
  },
});

export const StyledButton = styled(Button, {
  height: '20px',
  width: '71px',
  fontWeight: '$medium',
});

export const IconHighlight = styled('div', {
  width: '$20',
  height: '$20',
  borderRadius: '$lg',
  position: 'relative',
  backgroundColor: '$success300',
  '& svg': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

export const UnknownWallet = styled('div', {
  width: '$28',
  height: '$28',
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$neutral100',
  [`.${darkTheme} &`]: {
    backgroundColor: rangoDarkColors.neutral100,
    '& svg': {
      color: '$neutral800 !important',
    },
  },
});
