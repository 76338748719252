import { ReactElement } from 'react';
import { ProfileSummaryHeader } from '../ProfileSummaryHeader';
import { ProfileSummaryItems } from '../ProfileSummaryItems';
import { Container } from './ProfileSummary.styles';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ProfileProgress } from '../ProfileProgress';
import { ProfileSummaryEmptyState } from './ProfileSummaryEmptyState';

export function ProfileSummary(): ReactElement {
  const { data: profileSummary, error } = useAppSelector((state) => state.profile.profileSummary);

  return (
    <Container>
      <ProfileSummaryHeader />
      {error ? (
        <ProfileSummaryEmptyState />
      ) : (
        <div className="summary-content">
          <ProfileProgress profileSummary={profileSummary} />
          <div className="summary-items">
            <ProfileSummaryItems />
          </div>
        </div>
      )}
    </Container>
  );
}
