import {
  DiscordIcon,
  MediumIcon,
  TelegramIcon,
  XIcon,
  darkTheme,
  styled,
  theme,
} from '@rango-dev/ui';
import { FunctionComponent } from 'react';

const socialItems = [
  {
    name: 'discord',
    id: 'menu-social-discord-icon-link',
    link: 'https://discord.com/invite/q3EngGyTrZ',
    icon: <DiscordIcon size={32} color="black" />,
  },
  {
    name: 'medium',
    id: 'menu-social-medium-icon-link',
    link: 'https://medium.com/@rangoexchange',
    icon: <MediumIcon size={32} color="black" />,
  },
  {
    name: 'x',
    id: 'menu-social-x-icon-link',
    link: 'https://twitter.com/RangoExchange',
    icon: <XIcon size={32} color="black" />,
  },
  {
    name: 'telegram',
    id: 'menu-social-telegram-icon-link',
    link: 'https://t.me/rangoexchange',
    icon: <TelegramIcon size={32} color="black" />,
  },
];

const SocialLink = styled('a', {
  '&:hover': {
    '& svg': {
      color: '$info500',
      [`.${darkTheme} &`]: {
        color: theme.colors.secondary500,
      },
    },
  },
});

export const Socials: FunctionComponent = ({}) => {
  return (
    <>
      {socialItems.map(({ name, link, icon, id }, index) => (
        <SocialLink
          id={id}
          aria-label={name}
          key={index}
          href={link}
          target="_blank"
          rel="nofollow noreferrer">
          {icon}
        </SocialLink>
      ))}
    </>
  );
};
