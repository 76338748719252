import { Divider, SearchIcon, TextField, Typography } from '@rango-dev/ui';
import { ConnectWalletModalContentProps } from './ConnectWalletModalContent.types';
import { WalletsChainSelect } from './WalletsChainSelect';
import { SimpleNotification } from '../SimpleNotification';
import { WalletItem } from '../WalletItem';
import { ReactElement, useMemo, useState } from 'react';
import { useWalletList, useWallets } from '@rango-dev/widget-embedded';
import { Networks } from '../../utils/Constants';
import {
  ModalContent,
  NoResultImage,
  NoWalletFoundContainer,
  SearchWalletContainer,
  WalletListContainer,
} from './ConnectWalletModalContent.styles';
import { walletAndSupportedChainsNames } from '../../utils/wallets/general';
import { useTranslation } from 'react-i18next';

const AUTO_HIDE_WALLET_ERROR_DURATION = 10_000;

const ConnectWalletModalContent = (props: ConnectWalletModalContentProps): ReactElement => {
  const {
    walletMessages,
    disabled,
    handleCloseMessages,
    isWalletChainSelectOpen,
    setIsWalletChainSelectOpen,
    handleWalletItemClick,
    blockchains,
    excludedWallets,
  } = props;

  const { getWalletInfo } = useWallets();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [selectedChain, setSelectedChain] = useState<string | null>(null);

  const { list } = useWalletList({});

  const displayedWallets = useMemo(() => {
    let wallets = list;

    if (blockchains.length) {
      wallets = wallets.filter((w) => {
        const { supportedChains } = getWalletInfo(w.type);
        const supportedChainNames: Networks[] | null =
          walletAndSupportedChainsNames(supportedChains);
        let supportsAtLeastOneBlockchain = false;
        for (const blockchain of blockchains) {
          if (supportedChainNames?.includes(blockchain as Networks)) {
            supportsAtLeastOneBlockchain = true;
            break;
          }
        }

        return supportsAtLeastOneBlockchain;
      });
    }

    if (excludedWallets.length) {
      wallets = wallets.filter((wallet) => !excludedWallets.includes(wallet.type));
    }

    if (searchValue)
      wallets = wallets.filter((wallet) =>
        wallet.title.toLowerCase().includes(searchValue.toLowerCase()),
      );
    if (selectedChain) {
      wallets = wallets.filter((wallet) => {
        const { supportedChains } = getWalletInfo(wallet.type);
        const supportedChainNames: string[] | null = walletAndSupportedChainsNames(supportedChains);

        return supportedChainNames && supportedChainNames.includes(selectedChain);
      });
    }

    return wallets;
  }, [getWalletInfo, blockchains, searchValue, selectedChain]);

  return (
    <ModalContent>
      <div className="static flex w-full items-stretch gap-[10px]">
        <SearchWalletContainer>
          <TextField
            variant="ghost"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t('Search Wallet')}
            prefix={
              <div className="h-6 w-6 p-1">
                <SearchIcon color="black" size={16} />
              </div>
            }
          />
        </SearchWalletContainer>
        <WalletsChainSelect
          isOpen={isWalletChainSelectOpen}
          setIsOpen={setIsWalletChainSelectOpen}
          selectedChain={selectedChain}
          handleSelectedChainChange={(value) => setSelectedChain(value)}
          selectedBlockchains={blockchains}
        />
      </div>

      {walletMessages.map((error, index) => (
        <SimpleNotification
          key={index}
          message={error.message}
          open={error.open}
          setOpen={() => handleCloseMessages(false, error.message)}
          severity="error"
          autoHideDuration={AUTO_HIDE_WALLET_ERROR_DURATION}
        />
      ))}

      {displayedWallets.length > 0 ? (
        <WalletListContainer>
          {displayedWallets.map((wallet, index: number) => (
            <WalletItem
              key={`${index}-${wallet.type}`}
              wallet={wallet}
              handleClick={handleWalletItemClick}
              disabled={disabled}
            />
          ))}
        </WalletListContainer>
      ) : (
        <NoWalletFoundContainer>
          <NoResultImage
            src="/imgs/general/no-result-found.svg"
            width="190px"
            alt="No Wallet Found"
          />
          <Divider size="10" />
          <Typography variant="title" size="medium" color="info500">
            {t('No Wallet Found')}
          </Typography>
          <Divider size="10" />
          <Typography variant="body" size="medium" style={{ maxWidth: '350px' }}>
            {t(
              'There is no wallet found based on selected chain or search text, please try another chain or search query.',
            )}
          </Typography>
        </NoWalletFoundContainer>
      )}
    </ModalContent>
  );
};

export default ConnectWalletModalContent;
