import { ButtonPropTypes, ChevronRightIcon } from '@rango-dev/ui';
import { ProfileAccount } from '../../../types';
import { ProfileWallet } from '../ProfileWallet';
import {
  EmptyStateContainer,
  Footer,
  StyledButton,
  StyledTypography,
  WalletList,
} from './ConnectedWallets.styles';
import { ConnectedWalletsEmptyState } from './ConnectedWalletsEmptyState';
import { PropTypes } from './ProfileWallets.types';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import ConnectWalletModal from '../../ConnectWalletModal';
import { useState } from 'react';
import {
  BLOCKCHAINS_THAT_SUPPORT_PROFILE,
  WALLETS_WITHOUT_SIGN_MESSAGE_METHOD,
} from '../../../hooks/useProfile/useProfile.constants';

export function ConnectedWallets(props: PropTypes) {
  const { accounts, styles } = props;
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();
  const [showConnectWalletsModal, setShowConnectWalletsModal] = useState(false);
  const toggleConnectWalletsModal = () => setShowConnectWalletsModal((prevState) => !prevState);

  const connectedAccounts = accounts.filter(
    (account): account is ProfileAccount => account.walletType !== null,
  );

  const connectWalletButtonText = t('Connect more wallets');

  const mobileButtonProps: ButtonPropTypes = {
    variant: 'contained',
    size: 'medium',
    type: 'primary',
    fullWidth: true,
  };

  const desktopButtonProps: ButtonPropTypes = {
    variant: 'ghost',
    size: 'xsmall',
    fullWidth: false,
    suffix: <ChevronRightIcon size={10} />,
  };

  return (
    <>
      <ConnectWalletModal
        open={showConnectWalletsModal}
        onClose={toggleConnectWalletsModal}
        blockchains={BLOCKCHAINS_THAT_SUPPORT_PROFILE}
        excludedWallets={WALLETS_WITHOUT_SIGN_MESSAGE_METHOD}
        title={t('Choose wallet for your profile')}
        closeAfterSelect={true}
      />
      {connectedAccounts.length ? (
        <WalletList css={styles}>
          {accounts.map((account, index) => {
            const { walletType } = account;
            if (!walletType) {
              return null;
            }
            return <ProfileWallet key={index} account={account} />;
          })}
        </WalletList>
      ) : (
        <EmptyStateContainer>
          <ConnectedWalletsEmptyState />
        </EmptyStateContainer>
      )}
      <Footer>
        <StyledButton
          isMobile={isMobile}
          onClick={toggleConnectWalletsModal}
          {...(isMobile ? mobileButtonProps : desktopButtonProps)}>
          {isMobile ? (
            connectWalletButtonText
          ) : (
            <StyledTypography variant="label" size="medium">
              {connectWalletButtonText}
            </StyledTypography>
          )}
        </StyledButton>
      </Footer>
    </>
  );
}
