import { CSS, styled } from '@rango-dev/ui';

export const ChainCardHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$5',
});

export function getChainCardStyle(imageUrl: string): CSS {
  return {
    backgroundImage: imageUrl,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'calc(100% + 60px)',
    backgroundPositionY: '-30px',
    '@sm': {
      backgroundPositionX: 'calc(100% + 30px)',
    },
  };
}
