import { Checkbox, Divider, Image, ListItemButton, Typography } from '@rango-dev/ui';
import { CheckList, FilterItemContainer } from './WalletDetailsFilter.styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { containsText } from '../../../utils/generalFunctions/common';
import { FilterProps } from './WalletDetailsFilter.types';
import { useWallets } from '@rango-dev/widget-embedded';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useWalletDetails } from '../../../hooks/useWalletDetails';
import { WalletDetailFilterHeader } from './WalletDetailFilterHeader';
import { WalletDetailFilterAction } from './WalletDetailFilterAction';

export function ConnectedWalletFilter(props: FilterProps) {
  const { onClose } = props;

  const { t } = useTranslation();
  const { handleExcludedWallet, toggleExcludedAllWallet, connectedWallets } = useWalletDetails();
  const [searchedFor, setSearchedFor] = useState<string>('');
  const { getWalletInfo } = useWallets();

  const { excludedWallets } = useAppSelector((state) => state.walletDetails);

  const originalConnectWallet = connectedWallets.map((walletItem) => {
    const walletInfo = getWalletInfo(walletItem);
    return { walletType: walletItem, ...walletInfo };
  });

  let filteredList = originalConnectWallet;
  if (searchedFor) {
    filteredList = originalConnectWallet.filter((walletItem) =>
      containsText(walletItem.name, searchedFor),
    );
  }

  const items = filteredList.map((walletItem) => {
    const { name, img, walletType } = walletItem;
    return {
      start: <Image src={img} size={20} type="circular" />,
      end: <Checkbox checked={!excludedWallets?.includes(walletType)} />,
      onClick: () => handleExcludedWallet(walletType),
      title: (
        <Typography variant="body" size="medium">
          {name}
        </Typography>
      ),
      id: name,
    };
  });

  return (
    <FilterItemContainer>
      <WalletDetailFilterHeader
        title={t('Your Wallets')}
        handleToggleAll={toggleExcludedAllWallet}
        excludedItems={excludedWallets}
        searchHandler={setSearchedFor}
        searchedFor={searchedFor}
      />

      <Divider size="10" />

      <div className="filter-body body-wallet">
        <CheckList>
          {items.map((item) => {
            return <ListItemButton key={item.id} {...item} />;
          })}
        </CheckList>

        <WalletDetailFilterAction onClose={onClose} />
      </div>
    </FilterItemContainer>
  );
}
