import { ChartIcon } from '@rango-dev/ui';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DailyChartModal } from './DailyChartModal';
import { PropTypes } from './DailyChart.type';
import { DailyChartButton } from './DailyChart.styles';

export function DailyChart(props: PropTypes): ReactElement {
  const { t } = useTranslation();
  const { dailyData, loading, type } = props;
  const [showDailyChartModal, setShowDailyChartModal] = useState(false);
  const isDailyDataEmpty = !dailyData || !dailyData.length;
  const disabled = loading || isDailyDataEmpty;

  return (
    <div>
      <DailyChartButton
        id="profile-page-preview-daily-chart-btn"
        variant="ghost"
        type="secondary"
        disabled={disabled}
        onClick={() => setShowDailyChartModal(true)}
        prefix={<ChartIcon color={'black'} />}>
        {t('Preview Daily chart')}
      </DailyChartButton>
      {!isDailyDataEmpty && (
        <DailyChartModal
          type={type}
          dailyData={dailyData}
          open={showDailyChartModal}
          onClose={() => setShowDailyChartModal(false)}
        />
      )}
    </div>
  );
}
