import { Typography, Divider, Image, CompleteIcon, WalletIcon } from '@rango-dev/ui';
import { t } from 'i18next';
import { ReactElement, useEffect, useRef } from 'react';
import { PropTypes } from './ProfileWallet.types';
import { useWallets } from '@rango-dev/widget-embedded';
import { getConciseAddress } from '../../../utils/Constants';
import { supportedBlockchainsDisplayName } from '../ProfileWalletsModal/ProfileWalletsModal.constants';
import {
  BlockchainDisplayName,
  Container,
  IconHighlight,
  StyledButton,
  TitleContainer,
  UnknownWallet,
} from './ProfileWallet.styles';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { singMessage } from '../../../state/ProfileSlice';
import { Cancellable } from '../../../types';

export function ProfileWallet(props: PropTypes): ReactElement {
  const {
    account: { address, blockchain, walletType },
  } = props;
  const isUserLoggedIn = useAppSelector((state) => state.profile.isLoggedIn);
  const selectedAccount = useAppSelector((state) => state.profile.profileAuth.account);
  const dispatch = useAppDispatch();
  const { getWalletInfo, getSigners } = useWallets();
  const signMessagePromise = useRef<Cancellable | null>(null);
  let image: string | null = null;
  let name: string | null = null;

  if (walletType) {
    const walletInfo = getWalletInfo(walletType);
    image = walletInfo.img;
    name = walletInfo.name;
  }
  const conciseAddress = getConciseAddress(props.account.address, 8);
  const blockchainDisplayName = supportedBlockchainsDisplayName[props.account.blockchain];

  const loading =
    selectedAccount?.address === address &&
    selectedAccount?.blockchain === blockchain &&
    selectedAccount?.walletType === walletType;

  const disabled = !!selectedAccount && !loading;

  useEffect(() => {
    return () => signMessagePromise.current?.abort();
  }, []);

  return (
    <Container
      hasDivider
      title={
        <TitleContainer>
          {name && (
            <>
              <Typography variant="title" size="xmedium" color="foreground">
                {name}
              </Typography>
              <Divider direction="horizontal" size="4" />
            </>
          )}
          <BlockchainDisplayName variant="body" size="xsmall" color="neutral600">
            {blockchainDisplayName ?? props.account.blockchain}
          </BlockchainDisplayName>
        </TitleContainer>
      }
      description={conciseAddress ?? props.account.address}
      start={
        image ? (
          <Image src={image} size={28} type="circular" />
        ) : (
          <UnknownWallet>
            <WalletIcon size={16} color="gray" />
          </UnknownWallet>
        )
      }
      end={
        !walletType ? (
          <IconHighlight>
            <CompleteIcon color="success" size={13} />
          </IconHighlight>
        ) : (
          <StyledButton
            id={`profile-page-wallet-${isUserLoggedIn ? 'add-to-profile' : 'login'}-btn`}
            variant="contained"
            size="xxsmall"
            type="warning"
            disabled={disabled}
            loading={loading}
            onClick={() => {
              if (walletType) {
                signMessagePromise.current = dispatch(
                  singMessage({ account: { address, blockchain, walletType }, getSigners }),
                );
              }
            }}>
            {isUserLoggedIn ? t('Add To Profile') : t('Login')}
          </StyledButton>
        )
      }
    />
  );
}
