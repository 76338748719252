import { Divider, Typography } from '@rango-dev/ui';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { Container } from './ConnectedWalletsEmptyState.styles';

export function ConnectedWalletsEmptyState() {
  const { t } = useTranslation();
  const { isDarkMode } = useAppSelector((state) => state.settings);

  return (
    <Container>
      <img
        src={isDarkMode ? '/imgs/profile/no-wallet-dark.svg' : '/imgs/profile/no-wallet-light.svg'}
        alt="No campaign found."
        width={112}
        height={94}
      />
      <Divider size={10} direction="vertical" />
      <Typography variant="body" size="medium" color={isDarkMode ? 'neutral900' : 'neutral700'}>
        {t('All connected wallets are already linked to your profile.')}
      </Typography>
    </Container>
  );
}
