import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getI18n } from 'react-i18next';
import { LanguageType } from '../utils/types';
import { WidgetTheme } from '@rango-dev/widget-embedded';
import { detectOSDarkMode } from '../utils/generalFunctions/common';

export type SettingsState = {
  theme: WidgetTheme['mode'];
  isDarkMode: boolean;
  language: LanguageType['value'];
};

const initializeState = () => {
  const storedTheme = localStorage.getItem('theme') || 'light';
  const osThemeDark = detectOSDarkMode();

  return {
    theme: storedTheme as WidgetTheme['mode'],
    isDarkMode: storedTheme === 'dark' || (storedTheme === 'auto' && osThemeDark),
    language: (getI18n().language || 'en') as LanguageType['value'],
  };
};

const initialState = initializeState();

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<SettingsState['theme']>) => {
      const newTheme = action.payload || 'light';
      localStorage.setItem('theme', newTheme);
      state.theme = newTheme;
      state.isDarkMode = newTheme === 'dark' || (newTheme === 'auto' && detectOSDarkMode());
    },
    updateSettings: (state, action: PayloadAction<Partial<SettingsState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateLanguage: (state, action: PayloadAction<SettingsState['language']>) => {
      state.language = action.payload;
      getI18n().changeLanguage(action.payload);
    },
  },
});

export const { changeTheme, updateLanguage, updateSettings } = settingsSlice.actions;
export const SettingsReducer = settingsSlice.reducer;
