import { Button, Divider, styled, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../hooks/reduxHooks';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

const ReloadButton = styled(Button, {
  width: '280px',
  '@md': {
    width: '228px',
  },
});

const Error = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { isMobile } = useWindowDimensions();

  const handleReloadClick = () => window.location.reload();

  return (
    <div className="flex flex-col items-center justify-center py-[75px] md:py-[110px]">
      <img
        src={isDarkMode ? '/imgs/general/error-dark.png' : '/imgs/general/error-light.png'}
        className="h-[162px] w-[200px] md:h-[388px] md:w-[478px]"
      />
      <Divider size={10} />
      <Divider size={40} />
      <Typography variant={isMobile ? 'title' : 'display'} size="medium">
        {t('Sorry, something went wrong')}
      </Typography>
      <Divider size={10} />
      <Typography
        variant={isMobile ? 'body' : 'headline'}
        size={isMobile ? 'medium' : 'xsmall'}
        color={isDarkMode ? '$neutral900' : '$neutral700'}
        style={{ fontWeight: 400 }}>
        {t(
          'An unexpected error has occurred. If reloading the page does not fix it, please contact Rango support.',
        )}
      </Typography>
      {isMobile ? (
        <Divider size={20} />
      ) : (
        <>
          <Divider size={10} />
          <Divider size={40} />
        </>
      )}
      <ReloadButton
        id="error-page-reload-btn"
        variant="contained"
        type="primary"
        size={isMobile ? 'medium' : 'large'}
        onClick={handleReloadClick}>
        {t('Reload')}
      </ReloadButton>
    </div>
  );
};

export default Error;
