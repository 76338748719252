import { useEffect, useState } from 'react';
import { EXPANDED_PANEL_ID } from '../constants/theme';

export const useCheckWidgetExpanded = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const element = document.getElementById(EXPANDED_PANEL_ID) as HTMLDivElement;

  useEffect(() => {
    if (!element) return;
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const height = entry.contentRect.height;
        if (height === 0) {
          setIsExpanded(false);
        } else if (height === 700) {
          setIsExpanded(true);
        }
      }
    });
    resizeObserver.observe(element);
  }, [element]);

  return { isExpanded };
};
