import { Divider } from '@rango-dev/ui';
import { ReactElement } from 'react';
import { PropTypes } from './ProfileSummaryItem.types';
import {
  Container,
  ItemValue,
  StyledSkeleton,
  StyledTypography,
} from './ProfileSummaryItem.styles';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

export function ProfileSummaryItem(props: PropTypes): ReactElement {
  const { title, icon } = props;
  const { isMobile } = useWindowDimensions();

  const itemTitle = (
    <StyledTypography
      variant={isMobile ? 'label' : 'title'}
      size={isMobile ? 'medium' : 'small'}
      color="neutral700">
      {title}
    </StyledTypography>
  );

  const itemValue = (
    <>
      {props.loading ? (
        <>
          <Divider direction="vertical" size={4} />
          <StyledSkeleton variant="rounded" width={151} height={25} />
        </>
      ) : (
        <ItemValue variant="title" size="large">
          {props.value}
        </ItemValue>
      )}
    </>
  );

  return (
    <Container>
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', width: '100%' }}>
        <img src={icon} width={48} height={48} />
        <Divider size={isMobile ? 10 : 16} direction="horizontal" />
        <div className="item-details">
          {!isMobile && itemTitle}
          {isMobile && itemValue}
          <Divider size={2} direction="vertical" />
          {!isMobile && itemValue}
        </div>
      </div>
      {isMobile && (
        <>
          <Divider direction="vertical" size={10} />
          {itemTitle}
        </>
      )}
    </Container>
  );
}
