import { ProfileSummary } from '../../../types';
import { displayUsdValue } from '../ProfileSummary/ProfileSummary.helpers';
import { SummaryItems, SummaryItemsWithEffectiveValue } from './ProfileSummaryItems.types';

export function getIconLocation(name: string): string {
  return `/imgs/profile/${name}`;
}

export function getItems(profileSummary: ProfileSummary): SummaryItems {
  return {
    interactedBlockchains: profileSummary.interactedBlockchains.toString(),
    invitedFriends: profileSummary.invitedFriends.toString(),
    referralVolume: displayUsdValue(profileSummary.referralVolume),
    referralIncome: displayUsdValue(profileSummary.referralIncome),
  };
}

export function getItemsWithEffectiveValue(
  profileSummary: ProfileSummary,
): SummaryItemsWithEffectiveValue {
  return {
    volume: {
      total: displayUsdValue(profileSummary.totalVolume),
      effective: displayUsdValue(profileSummary.totalVolumeEffective),
    },
    txCount: {
      total: profileSummary.txCount.toString(),
      effective: profileSummary.txCountEffective.toString(),
    },
  };
}
