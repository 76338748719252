export const DEFAULT_WALLET_INJECTION_ERROR = 'Failed to connect to wallet';

export class UserUnauthorizedError extends Error {
  constructor() {
    super('User unauthorized');

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UserUnauthorizedError);
    }

    this.name = 'UserUnauthorizedError';
  }
}

export function throwIfAborted(signal: AbortSignal) {
  if (signal.aborted) throw new Error('Operation canceled');
}

export function getErrorMessage(error: unknown) {
  return error instanceof Error ? error.message : 'Something went wrong. Please try again.';
}
