import { Button, Divider, Typography } from '@rango-dev/ui';
import { PropTypes } from './LogOutModal.types';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import { Container, containerStyles, Footer, StyledImage } from './LogOutModal.styles';
import { Modal } from '../../Modal';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { Description } from '../LogIn/LogIn.styles';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

export function LogOutModal(props: PropTypes): ReactElement {
  const { open, onClose, onLogOut, logOutInProgress } = props;
  const { t } = useTranslation();
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { isMobile } = useWindowDimensions();

  return (
    <Modal
      anchor={isMobile ? 'bottom' : 'center'}
      styles={{ container: containerStyles }}
      open={open}
      onClose={onClose}
      dismissible
      footer={
        <Footer>
          <Button
            id="profile-page-sign-out-modal-yes-btn"
            fullWidth
            variant="contained"
            type="primary"
            size="medium"
            onClick={onLogOut}
            disabled={logOutInProgress}
            loading={logOutInProgress}>
            {t("Yes, I'm sure")}
          </Button>
          <Divider direction={isMobile ? 'vertical' : 'horizontal'} size="16" />
          <Button
            id="profile-page-sign-out-modal-no-btn"
            fullWidth
            variant="outlined"
            type="primary"
            size="medium"
            onClick={onClose}
            disabled={logOutInProgress}>
            {t("No, I'll stay")}
          </Button>
        </Footer>
      }>
      <Container>
        <StyledImage
          width={isMobile ? 164 : 184}
          height={isMobile ? 144 : 162}
          src={isDarkMode ? 'imgs/profile/logout-dark.svg' : 'imgs/profile/logout-light.svg'}
        />
        <Typography variant="headline" size={isMobile ? 'xsmall' : 'small'}>
          {t('Are you sure you want to Logout?')}
        </Typography>
        <Description variant="body" size={isMobile ? 'small' : 'medium'} color="neutral700">
          {t(
            "We ensure your data's safety. Whenever you reconnect your wallet, you will have secure access to your information.",
          )}
        </Description>
        {isMobile && <Divider size={24} direction="vertical" />}
      </Container>
    </Modal>
  );
}
