import { ReactElement } from 'react';
import { CardsContainer } from '../ProfileUserData.styles';
import { Divider } from '@rango-dev/ui';
import { ProfileUserDataEmptyState } from '../ProfileUserDataEmptyState';
import usePagination from '../../../../hooks/usePagination';
import { Pagination } from '../../../Pagination';
import { ProfileUserDataProtocolCard } from '../PorfileUserDataProtocolCard';
import { ProfileUserDataBridgesTabProps } from './ProfileUserDataBridgesTab.types';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';

export function ProfileUserDataBridgesTab(props: ProfileUserDataBridgesTabProps): ReactElement {
  const { profileBridges, loading } = props;
  const { isMobile } = useWindowDimensions();
  const { paginatedItems, totalPages, page, setPage } = usePagination({
    items: profileBridges ?? [],
  });

  if (loading) {
    return (
      <CardsContainer>
        {Array.from({ length: 8 }).map((_, index) => (
          <ProfileUserDataProtocolCard type="bridge" key={index} loading />
        ))}
      </CardsContainer>
    );
  }

  if (!profileBridges?.length) {
    return <ProfileUserDataEmptyState error={props.error} />;
  }

  const itemToRender = isMobile ? profileBridges : paginatedItems;

  return (
    <>
      <CardsContainer>
        {itemToRender.map((profileBridgeItem, index) => (
          <ProfileUserDataProtocolCard type="bridge" key={index} data={profileBridgeItem} />
        ))}
      </CardsContainer>
      <Divider size={20} />
      <Pagination page={page} totalPages={totalPages} onPageChange={(page) => setPage(page)} />
    </>
  );
}
