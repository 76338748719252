import { ChevronLeftIcon, CloseIcon, Header, IconButton, Typography } from '@rango-dev/ui';
import { LargeModalHeader, Prefix, SmallModalHeader, Suffix } from './ConnectWalletModal.styles';

interface PropTypes {
  title?: string;
  onBack?: () => void;
  onClose: () => void;
}

function ConnectWalletModalHeader(props: PropTypes) {
  const { title, onClose, onBack } = props;

  const renderCloseButton = () => (
    <Prefix>
      <IconButton onClick={onClose} variant="ghost">
        <CloseIcon color="black" size={14} />
      </IconButton>
    </Prefix>
  );

  const renderBackButton = () => (
    <Suffix>
      <IconButton onClick={onBack} variant="ghost">
        <ChevronLeftIcon color="black" size={14} />
      </IconButton>
    </Suffix>
  );

  const shouldRenderBackButton = !!onBack;

  return (
    <>
      <LargeModalHeader>
        {shouldRenderBackButton ? renderBackButton() : <Prefix />}
        {!!title ? (
          <Typography variant="headline" size="small">
            {title}
          </Typography>
        ) : (
          <div />
        )}
        {renderCloseButton()}
      </LargeModalHeader>
      <SmallModalHeader>
        {shouldRenderBackButton && renderBackButton()}
        {!!title && (
          <Typography variant="title" size="medium">
            {title}
          </Typography>
        )}
        {renderCloseButton()}
      </SmallModalHeader>
    </>
  );
}

export { ConnectWalletModalHeader };
