export const CHAIN_BACKGROUND_IMAGE_URL: Record<string, string> = {
  ARBITRUM: 'url(/imgs/chainBackgrounds/ARBITRUM.svg)',
  AVAX_CCHAIN: 'url(/imgs/chainBackgrounds/AVAX_CCHAIN.svg)',
  BASE: 'url(/imgs/chainBackgrounds/BASE.svg)',
  BLAST: 'url(/imgs/chainBackgrounds/BLAST.svg)',
  BSC: 'url(/imgs/chainBackgrounds/BSC.svg)',
  ETH: 'url(/imgs/chainBackgrounds/ETH.svg)',
  LINEA: 'url(/imgs/chainBackgrounds/LINEA.svg)',
  MODE: 'url(/imgs/chainBackgrounds/MODE.svg)',
  OPTIMISM: 'url(/imgs/chainBackgrounds/OPTIMISM.svg)',
  OSMOSIS: 'url(/imgs/chainBackgrounds/OSMOSIS.svg)',
  POLYGON: 'url(/imgs/chainBackgrounds/POLYGON.svg)',
  SCROLL: 'url(/imgs/chainBackgrounds/SCROLL.svg)',
  SOLANA: 'url(/imgs/chainBackgrounds/SOLANA.svg)',
  STARKNET: 'url(/imgs/chainBackgrounds/STARKNET.svg)',
  ZKSYNC: 'url(/imgs/chainBackgrounds/ZKSYNC.svg)',
};
