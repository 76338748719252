import { Divider, DoneIcon, StatsIcon, Typography } from '@rango-dev/ui';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ProfileWalletsModal } from '../ProfileWalletsModal';
import {
  ButtonContent,
  Container,
  Content,
  Description,
  MobileSideBarContainer,
  Step,
  StepNumber,
  Steps,
  StepTitle,
  StyledButton,
} from './LogIn.styles';
import { useProfile } from '../../../hooks/useProfile';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { StepSeparator } from './StepSeparator';
import {
  BLOCKCHAINS_THAT_SUPPORT_PROFILE,
  WALLETS_WITHOUT_SIGN_MESSAGE_METHOD,
} from '../../../hooks/useProfile/useProfile.constants';
import ConnectWalletModal from '../../ConnectWalletModal';
import { Campaigns } from '../../CampaignsSidebar/Campaigns';

export function LogIn(): ReactElement {
  const loadingMetaStatus = useAppSelector((state) => state.meta.loadingStatus);
  const { t } = useTranslation();
  const { compatibleAccounts, readyToSign } = useProfile();
  const [showProfileWalletsModal, setShowProfileWalletsModal] = useState(false);
  const [showConnectWalletsModal, setShowConnectWalletsModal] = useState(false);
  const toggleConnectWalletsModal = () => setShowConnectWalletsModal((prevState) => !prevState);
  const { isMobile } = useWindowDimensions();

  const signWalletDisabled = !readyToSign;

  const handleConnectWalletsModalExit = () => {
    if (readyToSign) {
      setShowProfileWalletsModal(true);
    }
  };

  useEffect(() => {
    if (signWalletDisabled && showProfileWalletsModal) {
      setShowProfileWalletsModal(false);
    }
  }, [signWalletDisabled]);

  return (
    <>
      <Container>
        <ConnectWalletModal
          open={showConnectWalletsModal}
          onClose={toggleConnectWalletsModal}
          blockchains={BLOCKCHAINS_THAT_SUPPORT_PROFILE}
          excludedWallets={WALLETS_WITHOUT_SIGN_MESSAGE_METHOD}
          title={t('Choose wallet for your profile')}
          closeAfterSelect={true}
          onExit={handleConnectWalletsModalExit}
        />
        <Content>
          <StatsIcon size={isMobile ? 24 : 27} color="secondary" />
          <Divider direction="vertical" size="30" />
          <Divider direction="vertical" size="4" />
          <Typography variant="headline" size={isMobile ? 'xsmall' : 'small'}>
            {t('Sign wallet to see your stats!')}
          </Typography>
          <Description variant="body" size="small">
            {t('Track your score and stats in your profile')}
          </Description>
          <Steps>
            <Step>
              <StepNumber done={readyToSign}>
                {readyToSign ? (
                  <DoneIcon size={16} color="white" />
                ) : (
                  <Typography variant="title" size="xmedium" color="secondary">
                    1
                  </Typography>
                )}
              </StepNumber>
              <StepTitle variant="label" size="medium" color="secondary">
                {t('Connect Wallet')}
              </StepTitle>
            </Step>
            <div className="step-separator-container">
              <StepSeparator disabled={signWalletDisabled} />
            </div>
            <Step>
              <StepNumber disabled={signWalletDisabled}>
                <Typography variant="title" size="xmedium" color="secondary">
                  2
                </Typography>
              </StepNumber>
              <StepTitle
                variant="label"
                size="medium"
                color="secondary"
                disabled={signWalletDisabled}>
                {t('Sign Wallet')}
              </StepTitle>
            </Step>
          </Steps>
          <StyledButton
            id={`profile-page-${readyToSign ? 'sign' : 'connect'}-wallet-btn`}
            variant="contained"
            type="primary"
            size="medium"
            fullWidth={isMobile}
            disabled={loadingMetaStatus !== 'success'}
            onClick={() => {
              if (!readyToSign) {
                setShowConnectWalletsModal(true);
              } else {
                setShowProfileWalletsModal(true);
              }
            }}>
            <ButtonContent>
              {signWalletDisabled ? t('Connect Your Wallet') : t('Sign Your Wallet')}
            </ButtonContent>
          </StyledButton>
          <ProfileWalletsModal
            open={showProfileWalletsModal}
            onClose={() => setShowProfileWalletsModal(false)}
            accounts={compatibleAccounts}
          />
        </Content>
      </Container>
      {isMobile && (
        <MobileSideBarContainer>
          <Campaigns />
        </MobileSideBarContainer>
      )}
    </>
  );
}
