import { ModalHeader, styled } from '@rango-dev/ui';

export const SmallModalHeader = styled(ModalHeader, {
  padding: '$25 $25 0',

  textAlign: 'start',
  '@md': {
    display: 'none',
  },
});

export const LargeModalHeader = styled(ModalHeader, {
  padding: '$25 $25 0',
  textAlign: 'start',
  display: 'none',
  alignItems: 'center',
  position: 'relative',

  '@md': {
    display: 'flex',
  },
});

export const Prefix = styled('div', {
  minWidth: 30,
});

export const Suffix = styled('div', {
  minWidth: 30,
});

export const ModalFooter = styled('div', {
  padding: '0 $15 $15 $15',
  variants: {
    hideInMobileView: {
      true: {
        display: 'none',
        '@md': {
          display: 'block',
        },
      },
    },
  },
});

export const TextLink = styled('a', {
  color: '$info',
  marginRight: '$5',
  marginLeft: '$5',
});

export const connectWalletModalStyles = {
  container: {
    borderRadius: '$primary $primary 0px 0px',
    height: '660px',
    maxHeight: '100%',
    '@md': {
      borderRadius: '$primary',
      width: '660px',
    },
    transition: 'width .45s, height .45s, transform .45s ease-in-out, top .45s ease-in-out',
  },
  content: {
    overflowY: 'clip',
    position: 'relative',
    padding: 0,
  },
};

export const statefulModalStyles = {
  container: {
    height: 'fit-content',
    overflow: 'clip',
    transition: 'width .45s, height .45s, transform .45s ease-in-out, top .45s ease-in-out',
    borderRadius: '$primary $primary 0px 0px',
    '@md': {
      width: '390px',
      borderRadius: '$primary',
    },
  },
  content: {
    padding: '0 25px 25px',
    textAlign: 'start',

    ul: {
      // This is can make bug in future if `Namespace` change the default value for margin, we should reset `margin` for ul in `Namespace`.
      margin: '1em 0',
    },
  },
};
