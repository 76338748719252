import { Typography } from '@rango-dev/ui';
import { ChainImageContainer } from './WalletDetailsFilter.styles';
import { getBlockchainLogo } from '../../../utils/Constants';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useWalletDetails } from '../../../hooks/useWalletDetails';

export function BlockchainImages() {
  const allBlockchains = useAppSelector((state) => state.meta.blockchains);
  const { excludedBlockchains } = useAppSelector((state) => state.walletDetails);
  const { validBlockchains } = useWalletDetails();
  const { isMobile } = useWindowDimensions();
  const LOGO_DISPLAY_LIMIT = isMobile ? 1 : 3;
  const selectedBlockchains = validBlockchains.filter(
    (blockchainItem) => !excludedBlockchains.includes(blockchainItem),
  );

  return (
    <ChainImageContainer>
      {selectedBlockchains &&
        selectedBlockchains.slice(0, LOGO_DISPLAY_LIMIT).map((blockchainItem) => {
          const logo = getBlockchainLogo(blockchainItem, allBlockchains);
          return (
            <span className="image-container" key={blockchainItem}>
              <img src={logo} alt={blockchainItem} />
            </span>
          );
        })}
      {selectedBlockchains && selectedBlockchains?.length > LOGO_DISPLAY_LIMIT && (
        <span className="image-container">
          <Typography className="more-item" variant="body" size="xsmall">
            +{selectedBlockchains?.length - LOGO_DISPLAY_LIMIT}
          </Typography>
        </span>
      )}
    </ChainImageContainer>
  );
}
