import { BlockchainSkeletonContainer } from './WalletDetails.styles';
import React from 'react';
import { Divider, Skeleton } from '@rango-dev/ui';

const NUMBER_OF_TOKEN_SKELETON = 3;

export function BlockchainSkeleton() {
  return (
    <BlockchainSkeletonContainer>
      {Array.from({ length: NUMBER_OF_TOKEN_SKELETON }).map((_, index) => (
        <React.Fragment key={`token_${index}`}>
          <div className="token-item">
            <div className="info-container">
              <Skeleton variant="circular" width={30} height={30} />
              <Divider size="10" direction="horizontal" />
              <div>
                <Skeleton variant="rounded" width={87} height={16} />
                <Divider size="2" />
                <Skeleton variant="rounded" width={58} height={16} />
              </div>
            </div>

            <div className="amount-container">
              <Skeleton variant="rounded" width={54} height={16} />
              <Divider size="2" />
              <Skeleton variant="rounded" width={39} height={16} />
            </div>
            <Skeleton variant="rounded" width={60} height={20} />
          </div>
          {index !== NUMBER_OF_TOKEN_SKELETON - 1 && <Divider size={'24'} />}
        </React.Fragment>
      ))}
    </BlockchainSkeletonContainer>
  );
}
