import axios from 'axios';
import { UserUnauthorizedError } from '../utils/Errors';

export function loadState<T>(key: string): T | undefined {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error('Could not load state', err);
    return undefined;
  }
}

export function saveState(key: string, state: unknown): void {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    console.error('Could not save state', err);
  }
}

export function shouldIgnoreError(error: unknown) {
  return error instanceof UserUnauthorizedError || axios.isCancel(error);
}
