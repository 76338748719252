import { PropsWithChildren } from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { Typography, TypographyPropTypes } from '@rango-dev/ui';
import { BannerText } from './Text.styles';

export const NeutralText = (props: PropsWithChildren<TypographyPropTypes>) => {
  const isDarkMode = useAppSelector((state) => state.settings.isDarkMode);
  const textColor = isDarkMode ? 'foreground' : 'neutral700';

  return (
    <BannerText {...props} color={textColor}>
      {props.children}
    </BannerText>
  );
};

export const RedText = (props: PropsWithChildren<TypographyPropTypes>) => {
  return (
    <Typography color="$error500" {...props}>
      {props.children}
    </Typography>
  );
};

export const BlueText = (props: PropsWithChildren<TypographyPropTypes>) => {
  return (
    <Typography color="$secondary500" {...props}>
      {props.children}
    </Typography>
  );
};
