import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../../translations/en.json';
import translationFR from '../../translations/fr.json';
import translationES from '../../translations/es.json';
import translationJA from '../../translations/ja.json';
import translationPT from '../../translations/pt.json';
import translationZH_CN from '../../translations/zh-CN.json';
import translationRU from '../../translations/ru.json';
import translationDE from '../../translations/de.json';
import translationUK from '../../translations/uk.json';
import translationSV from '../../translations/sv.json';
import translationFI from '../../translations/fi.json';
import translationNL from '../../translations/nl.json';
import translationEL from '../../translations/el.json';
import translationIT from '../../translations/it.json';
import translationPL from '../../translations/pl.json';
import translationAF from '../../translations/af.json';
import translationAR from '../../translations/ar.json';
import translationBN from '../../translations/bn.json';
import translationCA from '../../translations/ca.json';
import translationDA from '../../translations/da.json';
import translationHI from '../../translations/hi.json';
import translationHU from '../../translations/hu.json';
import translationID from '../../translations/id.json';
import translationKO from '../../translations/ko.json';
import translationLT from '../../translations/lt.json';
import translationMS from '../../translations/ms.json';
import translationFIL from '../../translations/fil.json';
import translationSR from '../../translations/sr.json';
import translationSK from '../../translations/sk.json';
import translationSW from '../../translations/sw.json';
import translationTH from '../../translations/th.json';
import translationTR from '../../translations/tr.json';
import translationUR from '../../translations/ur.json';
import translationVI from '../../translations/vi.json';
import translationZH_TW from '../../translations/zh-TW.json';
import { DEFAULT_LANGUAGE } from '../constants/i18n';

const resources = {
  af: { translation: translationAF },
  ar: { translation: translationAR },
  bn: { translation: translationBN },
  ca: { translation: translationCA },
  da: { translation: translationDA },
  de: { translation: translationDE },
  el: { translation: translationEL },
  en: { translation: translationEN },
  es: { translation: translationES },
  fi: { translation: translationFI },
  fil: { translation: translationFIL },
  fr: { translation: translationFR },
  hi: { translation: translationHI },
  hu: { translation: translationHU },
  id: { translation: translationID },
  it: { translation: translationIT },
  ja: { translation: translationJA },
  ko: { translation: translationKO },
  lt: { translation: translationLT },
  ms: { translation: translationMS },
  nl: { translation: translationNL },
  pl: { translation: translationPL },
  pt: { translation: translationPT },
  ru: { translation: translationRU },
  sk: { translation: translationSK },
  sr: { translation: translationSR },
  sv: { translation: translationSV },
  sw: { translation: translationSW },
  th: { translation: translationTH },
  tr: { translation: translationTR },
  uk: { translation: translationUK },
  ur: { translation: translationUR },
  vi: { translation: translationVI },
  'zh-CN': { translation: translationZH_CN },
  'zh-TW': { translation: translationZH_TW },
};

i18n
  // detect user language
  .use(new LanguageDetector(null, { lookupLocalStorage: 'rango-i18n' }))
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: DEFAULT_LANGUAGE,
    supportedLngs: Object.keys(resources),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnEmptyString: false,
    returnNull: false,
  });

export default i18n;
