import { Button, darkTheme, styled, Typography, Modal, IconButton } from '@rango-dev/ui';
import { ComponentProps } from 'react';

export const Container = styled('div', {
  '& .share-profile': {
    paddingLeft: '$20',
    paddingRight: '$20',
  },
  minWidth: '250px',
  maxWidth: '387px',
  width: '100%',
  '@sm': {
    minWidth: '387px',
  },
});

export const StyledButton = styled(Button, {
  [`.${darkTheme} &`]: {
    '&:disabled': {
      backgroundColor: '$neutral700',
    },
  },
});

export const StyledTypography = styled(Typography, {
  textAlign: 'left',
  '@sm': {
    fontSize: '$16 !important',
    fontWeight: '$regular !important',
    lineHeight: '$24 !important',
  },
});

export const modalStyles: ComponentProps<typeof Modal>['styles'] = {
  container: {
    backgroundColor: '$background',
    '@sm': {
      width: '80%',
    },
    '@md': {
      width: '660px',
    },
  },
  content: {
    borderRadius: '$primary',
    padding: '0 $20',
    '@md': {
      padding: '0 $30',
    },
  },
};

export const ModalContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const Description = styled(Typography, {
  color: '$neutral700 !important',
  [`.${darkTheme} &`]: {
    color: '$neutral900 !important',
  },
});

export const ShareProgressContentStyle = styled('div', {
  padding: '$15',
  backgroundColor: '$neutral300',
  borderRadius: '$primary',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  position: 'relative',

  '.user-link': {
    wordWrap: 'break-word',
    width: '100%',
    textAlign: 'left',
  },

  '@sm': {
    borderRadius: '$sm',
  },

  '.footer-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    width: '100%',
    height: '48px',

    '.rango-hashtag': {
      textAlign: 'left',
    },

    '@sm': {
      height: 'auto',
    },
  },
});

export const ShareProgressItem = styled('div', {
  display: 'flex',
});

export const ShareProgressSocialStyle = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',

  '.share-title': {
    padding: '$10 $5',
    width: '100%',
    textAlign: 'left',
    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },
});

export const ShareSocialItemStyle = styled('div', {
  width: '100%',
  display: 'flex',
  padding: '$10 $5',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.social-info': {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },

  '.social-content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },

  '.social-description': {
    display: 'none',

    '@sm': {
      display: 'inline-block',
    },

    [`.${darkTheme} &`]: {
      color: '$neutral900',
    },
  },
});

export const SocialIconStyle = styled('span', {
  width: '48px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '24px',

  '&.twitter': {
    border: '1px solid #000',
    backgroundColor: '#FFF',
    '._icon': {
      color: '#000',
    },
  },

  '&.telegram': {
    backgroundColor: '#40B3E0',
    '._icon': {
      color: '#fff',
      [`.${darkTheme} &`]: {
        color: '#101327',
      },
    },
  },
});

export const SocialLinkStyle = styled('a', {
  display: 'flex',
  alignItems: 'center',
  padding: '$8 $16',
  color: '$secondary',
  border: '1px solid $secondary',
  height: '36px',
  borderRadius: '99px',

  [`.${darkTheme} &`]: {
    color: '$secondary250',
    border: '1px solid $secondary250',

    '._icon': {
      color: '$secondary250',
    },
  },
});

export const SocialDivider = styled('div', {
  height: '1px',
  width: '100%',
  backgroundColor: '$neutral300',
});

export const CopyIconButtonContainer = styled('div', {
  position: 'absolute',
  bottom: '$15',
  right: '$15',
  width: '$48',
  height: '$48',

  '@sm': {
    position: 'static',
    bottom: 'unset',
    right: 'unset',
  },

  '._icon-button': {
    width: '$48',
    height: '$48',
    borderRadius: '24px',
    backgroundColor: '$secondary500',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '._text': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    [`.${darkTheme} &`]: {
      backgroundColor: '$secondary250',
      '._icon': {
        color: '$foreground',
      },
      '&:hover': {
        backgroundColor: '$secondary500',
      },
    },
  },
});
