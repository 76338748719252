import { Button, Divider, styled, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

const BackButton = styled(Button, {
  width: '280px',
  '@md': {
    width: '228px',
  },
});

const NotFound = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { isMobile } = useWindowDimensions();

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate({ pathname: '/' });
  };

  return (
    <div className="flex flex-col items-center justify-center py-[75px] md:py-[110px]">
      <img
        src={isDarkMode ? '/imgs/general/not-found-dark.png' : '/imgs/general/not-found-light.png'}
        className="h-[194px] w-[244px] md:h-[460px] md:w-[578px]"
      />
      <Divider size={10} />
      <Divider size={40} />
      <Typography variant={isMobile ? 'title' : 'display'} size="medium">
        {t('Page Not Found')}
      </Typography>
      <Divider size={10} />
      <Typography
        variant={isMobile ? 'body' : 'headline'}
        size={isMobile ? 'medium' : 'xsmall'}
        color={isDarkMode ? '$neutral900' : '$neutral700'}
        style={{ fontWeight: 400 }}>
        {t('Sorry, we couldn’t find that page.')}
      </Typography>
      {isMobile ? (
        <Divider size={20} />
      ) : (
        <>
          <Divider size={10} />
          <Divider size={40} />
        </>
      )}
      <BackButton
        id="not-found-page-back-to-home-btn"
        variant="contained"
        type="primary"
        size={isMobile ? 'medium' : 'large'}
        onClick={handleBackClick}>
        {t('Back To Home')}
      </BackButton>
    </div>
  );
};

export default NotFound;
