import { Typography } from '@rango-dev/ui';
import { PropTypes } from './Info.types';
import { Container, Description } from './Info.styles';

export function Info(props: PropTypes) {
  const { title, description } = props;

  return (
    <Container>
      <Typography variant="title" size="medium">
        {title}
      </Typography>
      <Description variant="body" size="medium" color="neutral700">
        {description}
      </Description>
    </Container>
  );
}
