import { SettingOverlayProps, SettingStates } from './Settings.types';
import { ChevronLeftIcon, CloseIcon, Divider, IconButton, Typography } from '@rango-dev/ui';
import { LanguagesList } from './Settings.Languages';
import { ThemeList } from './Settings.Theme';
import { ExperimentalFeatures } from './Settings.ExperimentalFeatures';

export const SettingOverly = (props: SettingOverlayProps) => {
  const { selectedItem, onBack, onClose } = props;
  return selectedItem ? (
    <div>
      <div className="hidden items-center justify-center sm:flex">
        <div className="absolute left-4" onClick={onBack}>
          <IconButton onClick={onBack} variant="ghost" id="setting-overly-back-btn">
            <ChevronLeftIcon color="black" size={16} />
          </IconButton>
        </div>
        <Typography variant="title" size="medium" className="capitalize">
          {selectedItem.title}
        </Typography>

        <div className="absolute right-4 flex items-center">
          <IconButton onClick={onClose} variant="ghost" id="setting-overly-close-btn">
            <CloseIcon color="black" size={16} />
          </IconButton>
        </div>
      </div>
      <Divider size={24} />
      {selectedItem.name === SettingStates.LANGUAGE && <LanguagesList />}
      {selectedItem.name === SettingStates.THEME && <ThemeList />}
      {selectedItem.name === SettingStates.EXPERIMENTAL_FEATURES && <ExperimentalFeatures />}
    </div>
  ) : null;
};
