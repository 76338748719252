import { FunctionComponent, useEffect } from 'react';
import { PropTypes } from './SimpleNotification.types';
import { useToast } from '@rango-dev/ui';

const AUTO_HIDE_DURATION = 3_000;

export const SimpleNotification: FunctionComponent<PropTypes> = ({
  message,
  open,
  setOpen,
  vertical = 'top',
  horizontal = 'center',
  severity,
  autoHideDuration,
  style = {
    top: '3rem',
    zIndex: 99999999,
  },
  ...props
}) => {
  const { addToast, removeToast } = useToast();
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      addToast({
        id: message,
        onClose: handleClose,
        position: `${horizontal}-${vertical}`,
        containerStyle: { top: '3rem', zIndex: 99999999 },
        autoHideDuration: autoHideDuration || AUTO_HIDE_DURATION,
        title: message,
        type: severity,
        ...props,
      });
    } else {
      removeToast(message, `${horizontal}-${vertical}`);
    }
  }, [open]);

  return null;
};
