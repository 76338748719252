import { ChainItemContainer, ChainItemImageContainer } from './ChainItem.styles';
import { ChainItemProps } from './ChainItem.types';
import { Image, Typography } from '@rango-dev/ui';

export function ChainItem(props: ChainItemProps) {
  const { blockchain, selected, handleClick } = props;

  return (
    <ChainItemContainer bordered={selected} onClick={handleClick}>
      <ChainItemImageContainer>
        <Image src={blockchain.logo} size={26} />
      </ChainItemImageContainer>
      <Typography variant="body" size="xsmall">
        {blockchain.displayName}
      </Typography>
    </ChainItemContainer>
  );
}
