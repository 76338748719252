import { darkTheme, styled } from '@rango-dev/ui';

export const PaginationContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$10',
});

export const PaginationButton = styled('button', {
  width: '$40',
  height: '$40',
  borderRadius: '$xs',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$neutral100',
  [`.${darkTheme} &`]: {
    background: '$neutral500',
  },
  '& svg': {
    color: '$neutral700',
    [`.${darkTheme} &`]: {
      color: '$neutral800',
    },
  },
  '&:disabled': {
    background: 'transparent',
    borderWidth: '1px',
    borderColor: '$neutral300',
    [`.${darkTheme} &`]: {
      borderColor: '$neutral400',
      background: 'transparent',
    },
    '& svg': {
      color: '$neutral600',
      [`.${darkTheme} &`]: {
        color: '$neutral800',
      },
    },
  },
});

export const PaginationStatus = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: '$15',
  paddingRight: '$15',
  background: '$neutral100',
  minWidth: '110px',
  '& span': {
    color: '$neutral600',
  },
  [`.${darkTheme} &`]: {
    background: '$neutral300',
    '& span': {
      color: '$neutral800',
    },
  },
  height: '$40',
  borderRadius: '$xs',
});
