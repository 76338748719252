import { Button, Divider, Typography } from '@rango-dev/ui';
import { EmptyStateContainer } from './WalletDetails.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyStateProps } from './WalletDetails.types';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { setHideEmptyWallets } from '../../state/WalletDetailsSlice';

export function EmptyState(props: EmptyStateProps) {
  const { emptyWalletsCount } = props;
  const { hideEmptyWallets } = useAppSelector((state) => state.walletDetails);
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  return (
    <EmptyStateContainer>
      <div className="image-container">
        <img
          src={isDarkMode ? '/imgs/common/dark-empty-state.png' : '/imgs/common/empty-state.png'}
          title="empty state"
          alt="empty state"
        />
      </div>
      <Divider size={'10'} />
      <div className="text-container">
        <Typography variant="title" size="medium">
          {t('No results found')}
        </Typography>
        <Divider size={'4'} />
        {hideEmptyWallets && emptyWalletsCount > 0 ? (
          <>
            <Typography className="description" variant="body" size="medium" color="$neutral700">
              {t(
                'No wallet to show, You have only {{emptyWalletsCount}} empty wallets. Select more chains / wallets or enable showing empty wallets to view your balance.',
                { emptyWalletsCount },
              )}
            </Typography>
            <Divider size={'36'} />
            <Button
              id="wallet-details-modal-show-empty-wallet-btn"
              onClick={() => dispatch(setHideEmptyWallets(false))}
              size="large"
              type="secondary"
              variant="outlined">
              {t('Show empty wallets')}
            </Button>
          </>
        ) : (
          <Typography className="description" variant="body" size="medium" color="$neutral700">
            {t('Select more chains or wallets to view your balance')}
          </Typography>
        )}
      </div>
    </EmptyStateContainer>
  );
}
