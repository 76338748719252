import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { detectInstallLink } from '@rango-dev/wallets-shared';
import { LogoContainer, Spinner, ImageContainer, WalletItemContainer } from './WalletItem.styles';
import { Image, Typography, WalletState } from '@rango-dev/ui';
import { WalletItemProps } from './WalletItem.types';

export const WalletItem: FunctionComponent<WalletItemProps> = ({
  wallet,
  handleClick,
  disabled,
}) => {
  const { title, image, link } = wallet;
  const { t } = useTranslation();

  const connected = wallet.state === WalletState.CONNECTED;
  const connecting = wallet.state === WalletState.CONNECTING;
  const extensionAvailable = wallet.state !== WalletState.NOT_INSTALLED;

  const ParentComp = (props: { children: JSX.Element }) => {
    const cls = `flex justify-start text-center relative ${
      disabled ? 'filter grayscale pointer-events-none' : ''
    }`;
    return !extensionAvailable ? (
      <a href={detectInstallLink(link)} target="_blank" rel="noreferrer" className={cls}>
        {props.children}
      </a>
    ) : (
      <div onClick={() => handleClick(wallet)} className={cls}>
        {props.children}
      </div>
    );
  };

  const WalletButtonIcon = useMemo(
    () => (
      <ParentComp>
        <WalletItemContainer bordered={connecting} className="w-full md:px-2 md:py-4">
          <LogoContainer className="mx-auto mb-2 rounded-full">
            <ImageContainer>
              <Image alt={title} src={image} size={35} />
            </ImageContainer>
            {connecting && <Spinner />}
          </LogoContainer>
          <Typography variant="label" size="medium">
            {title}
          </Typography>
          {extensionAvailable && !connected && !connecting && (
            <Typography variant="body" size="xsmall" color="neutral600">
              {t('Disconnected')}
            </Typography>
          )}
          {extensionAvailable && connected && (
            <Typography variant="body" size="xsmall" color="success500">
              <b>{t('Connected')}</b>
            </Typography>
          )}
          {connecting && (
            <Typography variant="body" size="xsmall" color="info500">
              <b>{t('Connecting...')}</b>
            </Typography>
          )}
          {!extensionAvailable && (
            <Typography variant="body" size="xsmall" color="info500">
              <b>{t('Install')}</b>
            </Typography>
          )}
        </WalletItemContainer>
      </ParentComp>
    ),
    [connected, extensionAvailable, connecting, disabled],
  );

  return WalletButtonIcon;
};
