import { Button, darkTheme, Skeleton, styled, Typography } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ProfileId = styled(Typography, {
  maxWidth: '60%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '@sm': {
    maxWidth: '40vw',
  },
  [`.${darkTheme} &`]: {
    color: '$neutral900',
  },
});

export const LogOutButton = styled(Button, {
  '&:disabled': {
    [`.${darkTheme} &`]: {
      color: '$neutral700',
    },
    '& svg': {
      color: '$neutral700',
      [`.${darkTheme} &`]: {
        color: '$foreground',
      },
    },
  },
  '.logout-button-content': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '$5',
  },
});

export const IdSkeleton = styled(Skeleton, {
  height: '$20 !important',
  '@sm': {
    height: '$26 !important',
    borderRadius: '$md !important',
  },
});
