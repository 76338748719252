import { useLocation } from 'react-router-dom';
import { Socials } from '../SocialLinks';
import { Logo } from '../Logo';
import { PropTypes } from './SideMenu.types';
import { NavLink } from '../NavLinks/NavLink';
import { Modal } from '../Modal';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, Typography } from '@rango-dev/ui';
import { Icon, MoreLinks, MoreTitle } from './SideMenu.styles';
import { useState } from 'react';
import { WIDGET_BASE_ROUTE } from '../../constants/navigation';

export const SideMenu = (props: PropTypes) => {
  const { open, onClose, links } = props;
  const { pathname } = useLocation();
  const [openMore, setOpenMore] = useState(false);
  const mainLinks = links.filter((link) => !link.isAdditionalLink);
  const additionalLinks = links.filter((link) => link.isAdditionalLink);
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      hasCloseIcon={false}
      anchor="right"
      styles={{
        container: {
          width: '290px',
          height: '100%',
        },
      }}
      onClose={onClose}
      footer={
        <div className="my-8 flex flex-row justify-center gap-2.5">
          <Socials />
        </div>
      }>
      <div className="flex">
        <div className="flex w-full items-center justify-end pb-4 pr-3 sm:pt-2.5 md:pr-6">
          <div className="flex w-full items-center justify-between pt-0.5">
            <Logo />
          </div>
        </div>
      </div>
      <ul className="flex flex-col items-start">
        {mainLinks.map((link, index) => {
          const isActive =
            pathname.includes(link.location) ||
            (pathname === '/' && link.location === WIDGET_BASE_ROUTE);
          return (
            <div className="py-4" key={index}>
              <NavLink
                id={link.id}
                location={link.location}
                title={link.title}
                key={link.title}
                isActive={isActive}
              />
            </div>
          );
        })}
        <MoreTitle className="py-4" onClick={() => setOpenMore(!openMore)}>
          <Typography size="medium" variant="title">
            {t('More')}
          </Typography>
          <Icon open={openMore}>
            <ChevronDownIcon size={12} />
          </Icon>
        </MoreTitle>

        <MoreLinks open={openMore}>
          {additionalLinks.map((link, index) => (
            <div className="py-4" key={index}>
              <NavLink
                id={link.id}
                location={link.location}
                title={link.title}
                key={link.title}
                isActive={false}
              />
            </div>
          ))}
        </MoreLinks>
      </ul>
    </Modal>
  );
};
