import { ChevronLeftIcon, CloseIcon, IconButton, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { StyledModalHeader } from './Settings.styles';
import { SettingsModalPanelProps } from './Settings.types';
import { SettingOverly } from './Settings.Overlay';
import { Modal } from '../Modal';
import { SettingsItem } from './Settings.Item';
import { SettingTitles } from '../../constants/theme';

export const SettingsMobilePanel = (props: SettingsModalPanelProps) => {
  const { open, onClose, onItemClick, selected, setSelected, items } = props;
  const { t } = useTranslation();
  const selectedItem = items.find((item) => item.name === selected);
  return (
    <Modal
      open={!!open}
      onClose={onClose}
      styles={{
        container: {
          maxHeight: 500,
        },
        content: {
          position: 'unset',
        },
      }}
      header={
        <StyledModalHeader selected={!!selected}>
          <IconButton
            onClick={onClose}
            variant="ghost"
            id="settings-mobile-panel-close-btn"
            style={{
              position: 'absolute',
              right: '15px',
            }}>
            <CloseIcon color="black" size={16} />
          </IconButton>

          <div>
            <Typography variant="title" size="medium" className="capitalize">
              {!selected ? t('Settings') : t(SettingTitles[selected])}
            </Typography>
          </div>

          {selected && (
            <IconButton
              onClick={() => setSelected(null)}
              variant="ghost"
              id="settings-mobile-panel-back-btn"
              style={{
                position: 'absolute',
                left: '15px',
              }}>
              <ChevronLeftIcon color="black" size={16} />
            </IconButton>
          )}
        </StyledModalHeader>
      }>
      {!selected && (
        <div className="grid grid-cols-1 gap-2.5 py-2 sm:grid-cols-2">
          {items.map((item, index) => (
            <SettingsItem key={index} {...item} onClick={onItemClick} />
          ))}
        </div>
      )}
      {selected && <SettingOverly onBack={() => setSelected(null)} selectedItem={selectedItem} />}
    </Modal>
  );
};
