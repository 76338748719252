import { darkTheme, styled, Tooltip } from '@rango-dev/ui';
import { ComponentProps } from 'react';

export const tooltipStyles: ComponentProps<typeof Tooltip>['styles'] = {
  content: {
    borderRadius: '$xs',
    padding: '$10 $15',
    width: '190px',
    textAlign: 'left',
  },
};

export const IconContainer = styled('div', {
  [`.${darkTheme} &`]: {
    '& svg': {
      color: '$neutral900',
    },
  },
});
