import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../hooks/reduxHooks';
import { DEFAULT_LANGUAGE } from '../../constants/i18n';

export const Logo = () => {
  const { isDarkMode, language } = useAppSelector((state) => state.settings);

  useEffect(() => {
    // Preload dark mode image
    const darkImg = new Image();
    darkImg.src = '/imgs/logos/LogoWithText-dark.svg';

    // Preload light mode image
    const lightImg = new Image();
    lightImg.src = '/imgs/logos/LogoWithText.svg';
  }, []);

  return (
    <div className="relative flex flex-row">
      <Link id="header-logo-image-btn" to={language === DEFAULT_LANGUAGE ? '/' : `/${language}`}>
        <div>
          <img
            src={`/imgs/logos/LogoWithText${isDarkMode ? '-dark' : ''}.svg`}
            alt="Logo"
            width="125px"
            height="47px"
            className="hidden sm:inline-block"
          />
          <img
            src={`/imgs/logos/LogoWithText${isDarkMode ? '-dark' : ''}.svg`}
            alt="Logo"
            width="76px"
            height="28px"
            className="sm:hidden"
          />
        </div>
      </Link>
    </div>
  );
};
