import { useState } from 'react';
import { normalizeWith } from '../../utils/generalFunctions/common';
import {
  ExperimentalFeature,
  SwitchExperimentalFeature,
  TextFieldExperimentalFeature,
} from '../../utils/types';
import {
  getExperimentalFeatures,
  isFeatureAvailable,
  updateExperimentalStatus,
} from '../../utils/generalFunctions';
import { RefreshIcon, Switch, TextField, Typography } from '@rango-dev/ui';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { updateWidgetConfig } from '../../state/WidgetSlice';
import { ExperimentalItem, ResetButton, StyledButton } from './Settings.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EXPERIMENTAL_FEATURES_LIST } from '../../utils/Constants';

export const ExperimentalFeatures = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [experimentalFeatures, setExperimentalFeatures] = useState(getExperimentalFeatures());
  const widgetConfig = useAppSelector((state) => state.widget);

  const [textFiledFeaturesValues, setTextFiledFeaturesValues] = useState<
    Record<string, TextFieldExperimentalFeature>
  >(
    normalizeWith(
      experimentalFeatures.filter(
        (feature) => feature.type === 'text-field',
      ) as TextFieldExperimentalFeature[],
      'name',
    ),
  );

  const isResetButtonEnabled = EXPERIMENTAL_FEATURES_LIST.some((item, index) => {
    const experimentalFeature = experimentalFeatures[index];
    if (item.type === 'switch' && experimentalFeature.type === 'switch') {
      return item.enable !== experimentalFeature.enable;
    }
    if (item.type === 'text-field' && experimentalFeature.type === 'text-field') {
      return item.value !== experimentalFeature.value;
    }
  });

  const updateTextFieldValue = (feature: TextFieldExperimentalFeature, value: string) =>
    setTextFiledFeaturesValues((prevState) => ({
      ...prevState,
      [feature.name]: {
        ...prevState[feature.name],
        value,
      },
    }));

  const updateFeature = (feature: ExperimentalFeature) => {
    const updateExperimentalValues = experimentalFeatures.map((exp) =>
      exp.name === feature.name ? feature : exp,
    );
    setExperimentalFeatures(updateExperimentalValues);
    if (feature.type === 'switch') {
      dispatch(
        updateWidgetConfig({
          routing: {
            ...widgetConfig.routing,
            [feature.name]: feature.enable ? 'enabled' : 'disabled',
          },
        }),
      );
    }

    updateExperimentalStatus(feature);
  };

  const applyTextFieldFeatureUpdate = (feature: TextFieldExperimentalFeature) => {
    updateFeature({
      ...feature,
      value: textFiledFeaturesValues[feature.name].value,
    });

    if (feature.needsReloadTheApp) {
      setTimeout(() => window.location.reload(), 0);
    }
  };

  const applyTextFieldFeaturesUpdate = () => {
    EXPERIMENTAL_FEATURES_LIST.forEach((item) => updateFeature(item));
    setTimeout(() => window.location.reload(), 0);
  };

  const applySwitchFieldFeatureUpdate = (feature: SwitchExperimentalFeature) => {
    updateFeature({
      ...feature,
      enable: !feature.enable,
    });

    setTimeout(() => window.location.reload(), 500);
  };

  return (
    <>
      <ResetButton
        disabled={!isResetButtonEnabled}
        onClick={applyTextFieldFeaturesUpdate}
        variant="ghost"
        style={{ position: 'absolute' }}
        id="setting-overly-reset-btn">
        <RefreshIcon color={isResetButtonEnabled ? 'black' : 'gray'} size={20} />
      </ResetButton>
      <ul>
        {experimentalFeatures.map((feature, index) => {
          const hasDivider = index !== experimentalFeatures.length - 1;
          return (
            isFeatureAvailable(feature.name) && (
              <React.Fragment key={index}>
                {feature.type === 'switch' && (
                  <>
                    <ExperimentalItem
                      role="button"
                      value={feature.name}
                      onClick={() => applySwitchFieldFeatureUpdate(feature)}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') applySwitchFieldFeatureUpdate(feature);
                      }}
                      hasDivider={hasDivider}>
                      <Typography variant="title" size="xmedium" className="text-sm sm:text-base">
                        {t(feature.displayName)}
                      </Typography>
                      <Switch checked={feature.enable ?? false} />
                    </ExperimentalItem>
                  </>
                )}

                {feature.type === 'text-field' && (
                  <ExperimentalItem hasDivider={hasDivider}>
                    <div className="flex w-full flex-col">
                      <Typography
                        variant="title"
                        size="xmedium"
                        align="left"
                        className="pb-2 text-sm sm:text-base">
                        {t(feature.displayName)}
                      </Typography>
                      <div className="flex items-center justify-between gap-x-2.5">
                        <TextField
                          variant="contained"
                          value={textFiledFeaturesValues[feature.name].value}
                          onChange={(event) => updateTextFieldValue(feature, event.target.value)}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') applyTextFieldFeatureUpdate(feature);
                          }}
                        />
                        <StyledButton
                          variant="contained"
                          type="primary"
                          size="medium"
                          onClick={() => applyTextFieldFeatureUpdate(feature)}>
                          {t('Apply')}
                        </StyledButton>
                      </div>
                    </div>
                  </ExperimentalItem>
                )}
              </React.Fragment>
            )
          );
        })}
      </ul>
    </>
  );
};
