import { Button, darkTheme, styled } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '$30 35px',
  alignItems: 'start',
  borderBottom: '0.5px solid $neutral300',
  '& .title': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
});

export const ProfileWalletsButton = styled(Button, {
  [`.${darkTheme} &`]: {
    color: '$secondary250',
  },
  '&:disabled': {
    [`.${darkTheme} &`]: {
      color: '$neutral700',
    },
    '& svg': {
      color: '$neutral700',
      [`.${darkTheme} &`]: {
        color: '$foreground',
      },
    },
  },
  '& .profile-wallets-button-content': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '$5',
  },
  '& .profile-wallets-icon': {
    position: 'relative',
  },
});

export const ConnectedIcon = styled('div', {
  position: 'absolute',
  background: '$secondary500',
  width: '$6',
  height: '$6',
  borderRadius: '$lg',
  right: '$2',
  border: '1px solid $surface100',
});
