import { useTranslation } from 'react-i18next';
import { CardContainer } from './ProfileUserDataCard.styles';
import { ProtocolCardHeader } from './PorfileUserDataProtocolCard.styles';
import { Divider, Skeleton, Typography } from '@rango-dev/ui';
import { CardFieldItem } from './ProfileUserDataCard';
import { Image } from '@rango-dev/ui';
import { numberToString } from '../../../utils/Numbers';
import BigNumber from 'bignumber.js';
import { ProfileUserDataProtocolCardProps } from './PorfileUserDataProtocolCard.types';
import { ReactElement } from 'react';
import { useAppSelector } from '../../../hooks/reduxHooks';

export function ProfileUserDataProtocolCard(props: ProfileUserDataProtocolCardProps): ReactElement {
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { t } = useTranslation();

  if ('loading' in props) {
    return (
      <CardContainer>
        <ProtocolCardHeader>
          <Skeleton variant="circular" width={44} height={44} />
          <Divider size={4} />
          <Skeleton variant="rounded" width={110} height={28} />
        </ProtocolCardHeader>
        <ul>
          {Array.from({ length: 4 }).map((_, index) => (
            <CardFieldItem key={index} loading={true} hasDivider={false} />
          ))}
        </ul>
      </CardContainer>
    );
  }

  const { data, type } = props;
  const fields = [
    {
      id: 'txCount',
      label: type === 'dex' ? t('Swap Count') : t('Bridge Count'),
      value: data.txCount,
      description:
        type === 'bridge'
          ? t(
              "Bridge Count indicates the total count of bridge transactions you've completed using a specific bridge.",
            )
          : t(
              "Swap Count indicates the total count of swap transactions you've completed using a specific swap.",
            ),
    },
    {
      id: 'txCountEffective',
      label: type === 'dex' ? t('Effective Swap Count') : t('Effective Bridge Count'),
      value: data.txCountEffective,
      description:
        type === 'bridge'
          ? t(
              "Effective Bridge Count represents the total count of bridge transactions you've completed across the chains listed in your profile and can effect on your score.",
            )
          : t(
              "Effective Swap Count represents the total count of swap transactions you've completed in the chains listed in your profile and can effect on your score.",
            ),
    },
    {
      id: 'totalVolume',
      label: t('Total Volume'),
      value: '$' + numberToString(new BigNumber(data.volume)),
      description:
        type === 'bridge'
          ? t(
              'Total Volume represents the cumulative volume of your transactions using a specific bridge.',
            )
          : t(
              'Total Volume represents the cumulative volume of your transactions using a specific swap.',
            ),
    },
    {
      id: 'effectiveVolume',
      label: t('Effective Volume'),
      value: '$' + numberToString(new BigNumber(data.volumeEffective)),
      description:
        type === 'bridge'
          ? t(
              'Total Effective Volume represents the bridge transaction volume across the chains listed in your profile and can effect on your score.',
            )
          : t(
              'Total Effective Volume represents the swap transaction volume in the chains listed in your profile and can effect on your score.',
            ),
    },
  ];

  return (
    <CardContainer>
      <ProtocolCardHeader>
        <Image src={data.swapper.logo} size={44} type="circular" />
        <Divider size={4} />
        <Typography variant="title" size="large">
          {data.swapper.title}
        </Typography>
      </ProtocolCardHeader>
      <ul>
        {fields.map((field) => (
          <CardFieldItem
            key={field.id}
            label={field.label}
            value={field.value}
            description={field.description}
            hasDivider={false}
          />
        ))}
      </ul>
    </CardContainer>
  );
}
