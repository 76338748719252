import { combineReducers, Slice } from '@reduxjs/toolkit';
import { WalletReducer, walletSlice } from './WalletSlice';
import { MetaReducer, metaSlice } from './MetaSlice';
import { StatusReducer, statusSlice } from './StatusSlice';
import { ProfileReducer, profileSlice } from './ProfileSlice';
import { WidgetReducer, widgetSlice } from './WidgetSlice';
import { SettingsReducer, settingsSlice } from './SettingsSlice';
import { WalletDetailsReducer } from './WalletDetailsSlice';

type ActionsFromSlice<T extends Slice> = ReturnType<T['actions'][keyof T['actions']]>;

export type RootActions =
  | ActionsFromSlice<typeof metaSlice>
  | ActionsFromSlice<typeof walletSlice>
  | ActionsFromSlice<typeof statusSlice>
  | ActionsFromSlice<typeof profileSlice>
  | ActionsFromSlice<typeof widgetSlice>
  | ActionsFromSlice<typeof settingsSlice>;

const rootReducer = combineReducers({
  meta: MetaReducer,
  wallet: WalletReducer,
  status: StatusReducer,
  profile: ProfileReducer,
  widget: WidgetReducer,
  settings: SettingsReducer,
  walletDetails: WalletDetailsReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
