export const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const DEFAULT_API_KEY =
  typeof process !== 'undefined' && import.meta.env.REACT_APP_API_KEY
    ? import.meta.env.REACT_APP_API_KEY
    : '60ed7770-07b8-4501-94a9-42114cb8e06f';

export const DEFAULT_BASE_URL =
  typeof process !== 'undefined' && import.meta.env.REACT_APP_API_BASE_URL
    ? import.meta.env.REACT_APP_API_BASE_URL
    : 'https://api.rango.exchange';

export const RANGO_COOKIE_HEADER = 'X-Rango-Id';
