import React from 'react';
import { useTranslation } from 'react-i18next';
import { numberToString } from '../utils/Numbers';
import BigNumber from 'bignumber.js';
import { useAppSelector } from '../hooks/reduxHooks';
import { NeutralText } from './Text';

export const Analytics = (): JSX.Element => {
  const status = useAppSelector((state) => state.status.status);
  const { t } = useTranslation();
  if (!status) return <React.Fragment />;

  return (
    <div className="ml-6 flex w-fit flex-row rounded-md bg-secondary bg-opacity-20 px-4 py-2 text-left md:bg-opacity-20">
      <div className="my-auto mr-2 h-full w-6">
        <img src="/imgs/logos/whatshot.svg" />
      </div>
      <div className="flex flex-col">
        <NeutralText variant="label" size="medium" className="pt-0.5">
          {t('Total trade volume')}:&nbsp;
        </NeutralText>
        <NeutralText variant="title" size="xmedium">
          <b>{numberToString(new BigNumber(status.totalSwapInUsd))}$</b>
        </NeutralText>
      </div>
    </div>
  );
};
