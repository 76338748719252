import { useState } from 'react';
import './App.css';
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks';
import { evmBasedChainsNamesSelector } from './state/MetaSlice';
import { addBlockchains, disconnectWallet } from './state/WalletSlice';
import { prepareAccountsForRedux, walletAndSupportedChainsNames } from './utils/wallets/general';
import App from './App';
import { Networks, WalletEvents } from './utils/Constants';
import { splitWalletNetwork } from '@rango-dev/wallets-shared';
import { WidgetProvider } from '@rango-dev/widget-embedded';
import { useWindowDimensions } from './hooks/useWindowDimensions';
import { getActiveThemeClassName } from './styles/themeUtility';
import useThemeAutoSwitch from './hooks/useThemeAutoSwitch';
import { backgroundStyles } from './components/Layout/Layout.styles';
import { useSubscribeToEvents } from './hooks/useSubscribeToEvents';
import { setExcludedBlockchains, setExcludedWallets } from './state/WalletDetailsSlice';
import { GTMEvents } from './constants/events';
import { useSyncStoreWithLocalStorage } from './hooks/useSyncStoreWithLocalStorage';

function Bootstrap(): JSX.Element {
  const dispatch = useAppDispatch();
  const evmBasedChainsNames = useAppSelector(evmBasedChainsNamesSelector);
  const [lastConnectedWalletWithNetwork, setLastConnectedWalletWithNetwork] = useState<string>('');
  const widgetConfig = useAppSelector((state) => state.widget);
  const { isDarkMode, theme } = useAppSelector((state) => state.settings);
  const { isMobile } = useWindowDimensions();
  useThemeAutoSwitch(theme);
  useSubscribeToEvents();
  useSyncStoreWithLocalStorage();

  return (
    <div className={`App ${getActiveThemeClassName(isDarkMode)} ${backgroundStyles()}`}>
      <WidgetProvider
        config={{
          ...widgetConfig,
          theme: {
            ...widgetConfig.theme,
            mode: isDarkMode ? 'dark' : 'light',
          },
          features: {
            ...widgetConfig.features,
            notification: isMobile ? 'visible' : 'hidden',
          },
        }}
        onUpdateState={(type, name, value, state, info) => {
          // Handling initiated wallet events for Google Tag Manager
          if (name === WalletEvents.CONNECTING && value) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: GTMEvents.WALLET_INITIATED,
              type,
            });
          }

          // If any updates has been triggered,
          // We push the update to redux for compatibility with the current architecture.
          if (name === WalletEvents.ACCOUNTS) {
            if (value) {
              const supportedChainNames: Networks[] | null = walletAndSupportedChainsNames(
                info.supportedBlockchains,
              );
              const data = prepareAccountsForRedux(
                type,
                value,
                state.network as Networks,
                evmBasedChainsNames,
                supportedChainNames,
                info.isContractWallet,
              );
              dispatch(addBlockchains(data));
            } else {
              // If value is null, it means the wallet state has been reset.
              dispatch(disconnectWallet({ walletType: type }));
              if (type === splitWalletNetwork(lastConnectedWalletWithNetwork)?.[0]) {
                setLastConnectedWalletWithNetwork('');
              }
            }

            // reset wallet details filters
            dispatch(setExcludedBlockchains([]));
            dispatch(setExcludedWallets([]));
          }

          // Update `connectedWallets`
          if (name === WalletEvents.ACCOUNTS && state.connected) {
            const key = `${type}-${state.network}-${value}`;
            // sample metamask-ETH-ETH:address
            // console.log({ key, value });
            if (state.connected) {
              setLastConnectedWalletWithNetwork(key);
            }
          }

          if (name === WalletEvents.NETWORK && state.network) {
            const key = `${type}-${state.network}`;
            // console.log({ key, value }, 2);
            // sample metamask-ETH
            setLastConnectedWalletWithNetwork(key);
          }
        }}>
        <App />
      </WidgetProvider>
    </div>
  );
}

export default Bootstrap;
