import { Fragment, ReactElement } from 'react';
import { Container } from './Campaigns.styles';
import { CampaignCarousel } from '../CampaignsCarousel';
import { DiscordIcon, Divider, XIcon } from '@rango-dev/ui';

export function Campaigns(): ReactElement {
  const socialLinks: { link: string; icon: ReactElement }[] = [
    {
      link: 'https://x.com/RangoExchange',
      icon: <XIcon color="secondary" size={24} />,
    },
    {
      link: 'https://discord.com/invite/q3EngGyTrZ',
      icon: <DiscordIcon color="secondary" size={24} />,
    },
  ];
  return (
    <Container>
      <CampaignCarousel />
      <Divider direction="vertical" size={24} />
      <div className="social-links">
        {socialLinks.map(({ link, icon }, index) => {
          return (
            <Fragment key={index}>
              <a className="link" href={link} target="_blank" rel="nofollow noreferrer">
                {icon}
              </a>
              {index !== socialLinks.length - 1 && <Divider direction="horizontal" size={24} />}
            </Fragment>
          );
        })}
      </div>
    </Container>
  );
}
