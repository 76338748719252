import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileWallet } from '../ProfileWallet/ProfileWallet';
import {
  AddedWallets,
  profileWalletsStyles,
  StyledTabs,
  tabsStyles,
} from './ProfileWallets.styles';
import { PropTypes } from './ProfileWallets.types';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ConnectedWallets } from './ConnectedWallets';

export function ProfileWallets(props: PropTypes) {
  const { accounts } = props;
  const isUserLoggedIn = useAppSelector((state) => state.profile.isLoggedIn);
  const { t } = useTranslation();
  const tabs = {
    addedWallets: { id: 'added-wallets', title: t('Linked Wallets') },
    connectedWallets: { id: 'connected-wallets', title: t('Unlinked Wallets') },
  };

  const [selectedTab, setSelectedTab] = useState(tabs.addedWallets.id);
  const showConnectedWallets = !isUserLoggedIn || selectedTab === tabs.connectedWallets.id;

  const addedWallets = (
    <>
      <AddedWallets css={{ ...profileWalletsStyles }}>
        {accounts.map((account, index) => {
          const { walletType } = account;
          if (walletType) {
            return null;
          }
          return <ProfileWallet key={index} account={account} />;
        })}
      </AddedWallets>
    </>
  );

  return (
    <>
      <StyledTabs
        type="bordered"
        items={Object.values(tabs)}
        value={selectedTab}
        className={tabsStyles()}
        onChange={(item) => {
          if (typeof item.id === 'string') {
            setSelectedTab(item.id);
          }
        }}
      />
      {showConnectedWallets ? (
        <>
          <ConnectedWallets styles={{ ...profileWalletsStyles }} accounts={accounts} />
        </>
      ) : (
        <>{addedWallets}</>
      )}
    </>
  );
}
