/** @type {import('tailwindcss').Config} */
export default {
  corePlugins: {
    // https://github.com/tailwindlabs/tailwindcss/issues/6602
    preflight: false,
  },
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      backgroundColor: {
        secondary: '#469BF5',
      },
      width: {
        7.5: '1.875rem',
        32: '8rem',
        40: '10rem',
        88: '22rem',
        128: '32rem',
        148: '37rem',
        172: '43rem',
        200: '50rem',
      },
      height: {
        7.5: '1.875rem',
        15: '3.75rem',
        56: '14rem',
        60: '15rem',
        64: '16rem',
        78: '19.5rem',
        96: '24rem',
        100: '25rem',
        128: '32rem',
        148: '37rem',
        160: '40rem',
      },
      maxWidth: {
        5: '1.25rem',
        6: '1.5rem',
      },
      minWidth: {
        40: '10rem',
        44: '11rem',
      },
      maxHeight: {
        7.5: '1.875rem',
      },
      minHeight: {
        40: '10rem',
        80: '20rem',
      },
      inset: {
        '-22': '-5.5rem',
      },
      colors: {
        primary: '#1C3CF1',
        neutral: '#727272',
        primaryColor: '#409ef2',
        successColor: '#04e762',
        background: '#1c1c1d',
      },
    },
  },
  plugins: [],
};
