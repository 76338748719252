import { Divider, Tabs, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import React, { ReactElement, useEffect, useState } from 'react';
import { DailyChartTabs, ModalPropTypes } from './DailyChart.type';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { Description } from '../../LogIn/LogIn.styles';
import { LegendBox, Circle, Container, modalStyles, TabsContainer } from './DailyChart.styles';
import { barChartColors, createChartDataTabs } from './DailyChart.helper';
import { BarChart, ChartOptionsType, prepareBarChartData } from '@rango-dev/charts';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { capitalizeFirstLetter, getContainer } from '../../../../utils/generalFunctions/common';
import { Modal } from '../../../Modal';
import { useAppSelector } from '../../../../hooks/reduxHooks';
const RECENT_DAILY_LIMIT = 30;
export function DailyChartModal(props: ModalPropTypes): ReactElement {
  const { open, onClose, dailyData, type } = props;
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();
  const chartDataTabs = createChartDataTabs(dailyData);
  const [selectedTab, setSelectedTab] = useState<DailyChartTabs>(chartDataTabs[0].id);
  const { isDarkMode } = useAppSelector((state) => state.settings);

  const chartOption: ChartOptionsType = {
    dailyData: dailyData.map((item) => ({
      date: item.date,
      bucket: capitalizeFirstLetter(item.bucket),
      value: (item as any)[selectedTab],
    })),
    barChartColors,
  };

  const { buckets, chartData, colorBucketMap } = prepareBarChartData(chartOption);
  const displayedItems = chartData.slice(Math.max(chartData.length - RECENT_DAILY_LIMIT, 0));

  useEffect(() => {
    if (open) setSelectedTab(chartDataTabs[0].id);
  }, [open]);

  return (
    <Modal
      anchor={isMobile ? 'bottom' : 'center'}
      styles={modalStyles}
      open={open}
      onClose={onClose}
      dismissible>
      <Container>
        <div className="modal-chart-header">
          <Typography
            variant={isMobile ? 'title' : 'headline'}
            size={isMobile ? 'medium' : 'small'}>
            {t('Daily {{type}} Progress Chart', { type: type[0].toUpperCase() + type.slice(1) })}
          </Typography>
          <Description variant="body" size={isMobile ? 'small' : 'medium'} color="neutral700">
            {t('Swap volume and number from your dApps')}
          </Description>
        </div>

        <Divider size={24} direction="vertical" />
        <TabsContainer>
          <Tabs
            container={getContainer()}
            items={chartDataTabs}
            value={selectedTab}
            onChange={(item) => setSelectedTab(item.id as DailyChartTabs)}
            borderRadius="medium"
            type={isMobile ? 'bordered' : 'secondary'}
            scrollable={isMobile}
            scrollButtons={false}
          />
        </TabsContainer>

        <Divider size={24} direction="vertical" />
        <div className="chart-content">
          <div className="chart-section">
            <ParentSize>
              {({ width, height }) => (
                <BarChart
                  width={width}
                  height={height}
                  isDarkTheme={isDarkMode}
                  data={displayedItems}
                  buckets={buckets}
                  colorBucketMap={colorBucketMap}
                  getLabel={(value) =>
                    selectedTab === DailyChartTabs.NumOfSwaps ||
                    selectedTab === DailyChartTabs.NumOfEffectiveSwaps
                      ? value
                      : `$${value}`
                  }
                />
              )}
            </ParentSize>
          </div>

          {isMobile && <Divider size={16} direction="vertical" />}
          <LegendBox>
            {chartData?.length &&
              Array.from(colorBucketMap).map((mapItem) => {
                const [bucketItem, bucketColor] = mapItem;
                return (
                  <React.Fragment key={bucketItem}>
                    <div className="item-row">
                      <Circle style={{ backgroundColor: bucketColor }} />
                      <Divider size={4} direction="horizontal" />
                      <Typography size={isMobile ? 'xsmall' : 'medium'} variant="body">
                        {bucketItem}
                      </Typography>
                    </div>
                  </React.Fragment>
                );
              })}
          </LegendBox>
        </div>
      </Container>
    </Modal>
  );
}
