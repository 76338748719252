import { ColorsIcon, LanguageIcon, RouteIcon } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/reduxHooks';
import { SettingItemsType, SettingStates, SettingsPanelProps } from './Settings.types';
import { useEffect, useState } from 'react';
import { SettingOverly } from './Settings.Overlay';
import { SettingsMobilePanel } from './Settings.MobilePanel';
import { isAnyFeatureAvailableOnMode } from '../../utils/generalFunctions/getExperimentals';
import { SettingsItem } from './Settings.Item';
import { SettingContainer } from './Settings.styles';
import { Languages } from '../../constants/i18n';
import { ThemeTitles } from '../../constants/theme';

export const SettingsPanel = (props: SettingsPanelProps) => {
  const { setOpenModal, setOpenPopover, openModal, isMobile } = props;
  const { language, theme } = useAppSelector((state) => state.settings);
  const [selected, setSelected] = useState<SettingStates | null>(null);
  const { t } = useTranslation();

  const SETTING_ITEMS: SettingItemsType[] = [
    {
      title: t('Language'),
      id: 'header-settings-language-item',
      name: SettingStates.LANGUAGE,
      showValue: true,
      value: Languages.find((lng) => lng.value === (language || 'en'))?.title || '',
      icon: <LanguageIcon size={24} color="secondary" />,
    },
    {
      title: t('Theme'),
      id: 'header-settings-theme-item',
      name: SettingStates.THEME,
      showValue: true,
      value: theme ? t(ThemeTitles[theme]) : undefined,
      icon: <ColorsIcon size={24} color="secondary" />,
    },
  ];
  if (isAnyFeatureAvailableOnMode()) {
    SETTING_ITEMS.push({
      title: t('Experimental Features'),
      id: 'header-settings-experimental-features-item',
      name: SettingStates.EXPERIMENTAL_FEATURES,
      icon: <RouteIcon size={24} color="secondary" />,
    });
  }

  const handleItemClick = (item: SettingStates) => {
    setSelected(item);
  };

  const handleCloseModal = () => {
    setOpenModal?.(false);
  };

  useEffect(() => {
    if (openModal) setSelected(null);
  }, [openModal]);

  return !isMobile ? (
    <SettingContainer>
      {!selected && (
        <div className="grid grid-cols-1 gap-2.5 sm:grid-cols-2">
          {SETTING_ITEMS.map((item, index) => (
            <SettingsItem key={index} onClick={handleItemClick} {...item} />
          ))}
        </div>
      )}
      {selected && (
        <SettingOverly
          onClose={() => setOpenPopover?.(false)}
          onBack={() => setSelected(null)}
          selectedItem={SETTING_ITEMS.find((item) => item.name === selected)}
        />
      )}
    </SettingContainer>
  ) : (
    <SettingsMobilePanel
      onItemClick={handleItemClick}
      onClose={handleCloseModal}
      selected={selected}
      open={openModal}
      setSelected={setSelected}
      items={SETTING_ITEMS}
    />
  );
};
