import { darkTheme, styled } from '@rango-dev/ui';
export const ModalContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$25',
  padding: '$25',
  height: '100%',
});

export const WalletListContainer = styled('div', {
  width: '100%',
  overflow: 'auto',
  display: 'grid',
  gap: '$15',
  gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  '@md': {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
  },
});

export const SearchWalletContainer = styled('div', {
  padding: '3px',
  backgroundColor: '$neutral100',
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral300',
  },
  borderRadius: '$md',
  width: 'calc(50% - 5px)',
  '@md': {
    width: 'auto',
    flexGrow: 1,
  },
});

export const NoWalletFoundContainer = styled('div', {
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const NoResultImage = styled('img', {
  width: '135px',
  '@md': {
    width: '190px',
  },
});
