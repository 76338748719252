import { Blockchain, WalletType, Networks, ALL_BLOCKCHAINS, Wallet, ZERO } from '../../Constants';
import { ethers } from 'ethers';
import { readAccountAddress } from '@rango-dev/widget-embedded';
import { BlockchainMeta } from '../../../api/models/MetaModels';
import BigNumber from 'bignumber.js';

export function prepareAccountsForRedux(
  wallet: WalletType,
  accounts: string[],
  connectedNetwork: Networks | null,
  evmBasedChains: string[],
  supportedChainNames: Networks[] | null,
  isContractWallet: boolean,
): Blockchain[] {
  const result = {} as { [type in Networks]: Blockchain };

  function addAccount(network: Networks, address: string) {
    const isConnected = network === connectedNetwork;
    const newAccount = {
      address,
      balances: null,
      loading: true,
      walletType: wallet,
      isConnected,
      error: false,
      explorerUrl: null,
    };

    if (!!result[network]) {
      result[network].accounts.push(newAccount);
    } else {
      result[network] = {
        name: network,
        accounts: [newAccount],
      };
    }
  }

  const supportedChains = supportedChainNames || [];

  accounts.forEach((account) => {
    const { address, network } = readAccountAddress(account);

    const hasLimitation = supportedChains.length > 0;
    const isSupported = supportedChains.includes(network as Networks);
    const isUnknown = network === Networks.Unknown;
    const notSupportedNetworkByWallet = hasLimitation && !isSupported && !isUnknown;

    // Here we check given `network` is not supported by wallet
    // And also the network is known.
    if (notSupportedNetworkByWallet) return;

    // In some cases we can handle unknown network by checking its address
    // pattern and act on it.
    // Example: showing our evm compatible netwrok when the uknown network is evem.
    // Otherwise, we stop executing this function.
    const isUknownAndEvmBased = network === Networks.Unknown && ethers.isAddress(address);
    if (isUnknown && !isUknownAndEvmBased) return;

    const isEvmBasedChain = evmBasedChains.includes(network);

    // If it's an evm network, we will add the address to all the evm chains.
    if (isEvmBasedChain || isUknownAndEvmBased) {
      if (isContractWallet) {
        addAccount(network as Networks, address.toLowerCase());
        return;
      }

      // all evm chains are not supported in wallets, so we are adding
      // only to those that are supported by wallet.
      const evmChainsSupportedByWallet = supportedChains.filter((chain) =>
        evmBasedChains.includes(chain),
      );

      evmChainsSupportedByWallet.forEach((network) => {
        // EVM addresses are not case sensetive.
        // Some wallets like Binance-chain return some letters in uppercase which produces bugs in our wallet state.
        addAccount(network, address.toLowerCase());
      });
    } else {
      addAccount(network as Networks, address);
    }
  });

  return Object.values(result);
}

export function walletAndSupportedChainsNames(
  supportedChains: BlockchainMeta[],
): Networks[] | null {
  if (!supportedChains) return null;
  let walletAndSupportedChainsNames: Networks[] = [];
  walletAndSupportedChainsNames = supportedChains.map(
    (blockchainMeta) => blockchainMeta.name as Networks,
  );

  return walletAndSupportedChainsNames;
}

export const calculateWalletUsdValue = (
  wallet: Wallet | null,
  selectedBlockchain: Networks[],
  selectedWallet: string[],
): number => {
  const uniqueAccountAddresses = new Set<string>();
  const modifiedWalletBlockchains = wallet?.blockchains?.map((blockchain) => {
    const modifiedWalletBlockchain: Blockchain = { name: blockchain.name, accounts: [] };
    blockchain.accounts.forEach((account) => {
      if (!uniqueAccountAddresses.has(account.address)) {
        uniqueAccountAddresses.add(account.address);
      }
    });
    uniqueAccountAddresses.forEach((accountAddress) => {
      const lastConnectedAccountWithSameAddress = [...blockchain.accounts]
        .reverse()
        .find((b) => b.address === accountAddress);

      if (
        !!lastConnectedAccountWithSameAddress &&
        selectedWallet.includes(lastConnectedAccountWithSameAddress.walletType)
      )
        modifiedWalletBlockchain.accounts.push(lastConnectedAccountWithSameAddress);
    });
    return modifiedWalletBlockchain;
  });

  const filteredModifiedWalletBlockchains = modifiedWalletBlockchains?.filter((blockchain) =>
    selectedBlockchain.includes(blockchain.name as Networks),
  );

  const usdValue =
    filteredModifiedWalletBlockchains
      ?.flatMap((b) => b.accounts)
      ?.flatMap((a) => a?.balances)
      ?.map((b) => new BigNumber(b?.amount || ZERO).multipliedBy(b?.usdPrice || 0))
      ?.reduce((a, b) => a.plus(b), ZERO) || ZERO;
  return usdValue.toNumber();
};

export const hasSomeLoadingAccount = (wallet: Wallet | null): boolean =>
  (wallet?.blockchains?.filter((b) =>
    b.accounts.flatMap((it) => it.loading).reduce((x, y) => x || y),
  )?.length || 0) > 0;
