import { ListItemButton, Radio, RadioRoot, Typography } from '@rango-dev/ui';
import { LanguageType } from '../../utils/types';
import { useAppSelector } from '../../hooks/reduxHooks';
import { DEFAULT_LANGUAGE, Languages } from '../../constants/i18n';
import { useLocation, useNavigate } from 'react-router-dom';
import { setLocaleCookie } from '../../utils/generalFunctions/common';

export const LanguagesList = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { language: currentLanguage } = useAppSelector((state) => state.settings);

  const handleChangeLanguage = (newLocale: LanguageType['value']) => {
    if (!newLocale) return;

    setLocaleCookie(newLocale);
    // redirect to the new locale path
    if (currentLanguage === DEFAULT_LANGUAGE) {
      navigate({
        pathname: '/' + newLocale + pathname,
        search: search,
      });
    } else {
      navigate({
        pathname: pathname.replace(`/${currentLanguage}`, `/${newLocale}`),
        search: search,
      });
    }
  };

  return (
    <RadioRoot value={currentLanguage}>
      <ul>
        {Languages.map(({ value = 'en', title, Icon }, index) => (
          <ListItemButton
            key={value}
            hasDivider={index !== Languages.length - 1}
            title={
              <Typography variant="title" size="xmedium">
                {title}
              </Typography>
            }
            onClick={() => handleChangeLanguage(value)}
            start={<Icon />}
            end={<Radio value={value} />}
            id={value}
          />
        ))}
      </ul>
    </RadioRoot>
  );
};
