import { rangoDarkColors } from '@rango-dev/ui';
import { t } from 'i18next';

export const RANGO_CONTAINER_ID = 'rango-container';
export const WIDGET_CONTAINER_ID = 'rango-swap-box';
export const EXPANDED_PANEL_ID = 'rango-expanded-box';

export const CUSTOM_WIDGET_THEME = {
  dark: { ...rangoDarkColors },
};

export const SettingTitles = {
  language: 'Language',
  theme: 'Theme',
  'experimental features': 'Experimental Features',
};

export const ThemeTitles = {
  dark: 'Dark',
  light: 'Light',
  auto: 'Auto',
};
