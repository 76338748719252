import { Divider, LogoutIcon } from '@rango-dev/ui';
import { IdSkeleton, LogOutButton, ProfileId, Container } from './LogOut.styles';
import { useTranslation } from 'react-i18next';
import { SimpleNotification } from '../../SimpleNotification';
import { LogOutModal } from '../LogOutModal';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { logOutUser } from '../../../state/ProfileSlice';
import { logOut } from '../../../api/Profile';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

export function LogOut() {
  const { data: profileSummary, loading } = useAppSelector((state) => state.profile.profileSummary);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();

  const [showLogOutModal, setShowLogOutModal] = useState(false);
  const [logOutInProgress, setLogOutInProgress] = useState(false);
  const [showLogOutError, setShowLogOutError] = useState(false);

  const onLogOut = async () => {
    setLogOutInProgress(true);
    try {
      await logOut();
      dispatch(logOutUser());
    } catch (error) {
      setShowLogOutError(true);
    } finally {
      setLogOutInProgress(false);
    }
  };
  return (
    <Container className="id">
      <SimpleNotification
        message={t('An error occurred during logout. Please try again.')}
        open={showLogOutError}
        setOpen={setShowLogOutError}
        severity="error"
      />
      <LogOutModal
        open={showLogOutModal}
        logOutInProgress={logOutInProgress}
        onClose={() => setShowLogOutModal(false)}
        onLogOut={onLogOut}
      />
      {loading ? (
        <IdSkeleton variant="rounded" width={119} height={26} />
      ) : (
        <ProfileId
          color="neutral700"
          {...(isMobile
            ? { variant: 'body', size: 'medium' }
            : { variant: 'title', size: 'medium' })}>
          {t('ID')}: {profileSummary?.id}
        </ProfileId>
      )}
      <Divider direction="horizontal" size="10" />
      <LogOutButton
        variant="ghost"
        type="error"
        size="xsmall"
        disabled={loading}
        onClick={() => setShowLogOutModal(true)}>
        <div className="logout-button-content">
          {t('Logout')}
          <LogoutIcon size={16} color={loading ? 'gray' : 'error'} />
        </div>
      </LogOutButton>
    </Container>
  );
}
