import { ChainDailyData, SwapperDailyData } from '../../../../types';
import { ProfileUserDataTabs } from '../ProfileUserData.types';

export type PropTypes = {
  loading: boolean;
  dailyData?: ChainDailyData[] | SwapperDailyData[];
  type: ProfileUserDataTabs;
};

export type ModalPropTypes = {
  open: boolean;
  onClose: () => void;
  dailyData: ChainDailyData[] | SwapperDailyData[];
  type: ProfileUserDataTabs;
};

export enum DailyChartTabs {
  Volume = 'volume',
  NumOfSwaps = 'count',
  NumOfEffectiveSwaps = 'countEffective',
  EffectiveVolume = 'volumeEffective',
}
