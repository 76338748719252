import { Button, IconButton, ModalHeader, darkColors, darkTheme, styled } from '@rango-dev/ui';

export const SettingContainer = styled('div', {
  overflowY: 'auto',
  maxHeight: '500px',
  position: 'relative',
  padding: '$16',
  width: '384px',
});

export const SettingSection = styled('div', {
  height: '106px',
  borderRadius: '$xm',
  backgroundColor: '$neutral200',
  padding: '$15',
  cursor: 'pointer',
  [`.${darkTheme} &`]: {
    backgroundColor: '$neutral500',
  },
  '@sm': {
    width: '170px',
    padding: '$10',
  },
  '&:hover': {
    backgroundColor: '$info100',
    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral300',
    },
  },
});

export const Step = styled('div', {
  display: 'flex',
  gap: '15px',
  justifyContent: 'space-between',
  height: '100%',
  flexDirection: 'column',
});

export const Footer = styled('div', {
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '5px',
  textAlign: 'left',
  '& ._title': {
    color: '$neutral600',
    [`.${darkTheme} &`]: {
      color: darkColors.neutral700,
    },
  },
  '@sm': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '50%',
  },
  variants: {
    showValue: {
      false: {
        justifyContent: 'flex-end',
      },
    },
  },
});

export const StyledModalHeader = styled(ModalHeader, {
  backgroundColor: '$background',
  padding: '$20',
  borderTopRightRadius: '$primary',
  borderTopLeftRadius: '$primary',
  variants: {
    selected: {
      true: {
        justifyContent: 'center',
      },
      false: {
        justifyContent: 'flex-start',
      },
    },
  },
});

export const ExperimentalItem = styled('li', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$10 0',
  '& ._text-field': {
    borderRadius: '$sm',
    height: '44px',
    flexBasis: '70%',
    backgroundColor: '$neutral200',
    [`.${darkTheme} &`]: {
      backgroundColor: '$neutral500',
    },
    '@sm': {
      width: '258px',
    },
  },
  variants: {
    hasDivider: {
      true: {
        borderBottom: '1px solid',
        borderColor: '$neutral500',
        [`.${darkTheme} &`]: {
          borderColor: '$neutral400',
        },
      },
    },
  },
});

export const StyledButton = styled(Button, {
  height: '32px',
  width: '83px',
});

export const ResetButton = styled(IconButton, {
  top: 12,
  right: 48,
  '@sm': {
    top: 11,
  },
});
