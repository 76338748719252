import { styled } from '@rango-dev/ui';

export const Container = styled('div', {
  minWidth: 250,
  padding: '35px',
  background: '$background',
  borderRadius: '$md',
  '& .social-links': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '@media (min-width:1280px)': {
    maxWidth: 434,
  },
});
