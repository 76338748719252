import {
  AutoThemeIcon,
  DarkModeIcon,
  LightModeIcon,
  ListItemButton,
  Radio,
  RadioRoot,
  Typography,
} from '@rango-dev/ui';
import { changeTheme } from '../../state/SettingsSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { WidgetTheme } from '@rango-dev/widget-embedded';
import { useTranslation } from 'react-i18next';

export const ThemeList = () => {
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector((state) => state.settings);
  const { t } = useTranslation();
  const themes = [
    {
      value: 'light',
      title: t('Light'),
      icon: <LightModeIcon size={16} color="gray" />,
    },
    {
      value: 'dark',
      title: t('Dark'),
      icon: <DarkModeIcon size={16} color="gray" />,
    },
    {
      value: 'auto',
      title: t('Auto'),
      icon: <AutoThemeIcon size={16} color="gray" />,
    },
  ];

  return (
    <RadioRoot value={theme}>
      <ul>
        {themes.map(({ value = 'light', icon, title }, index) => (
          <ListItemButton
            key={value}
            hasDivider={index !== themes.length - 1}
            title={
              <Typography variant="title" size="xmedium" className="capitalize">
                {title}
              </Typography>
            }
            onClick={() => dispatch(changeTheme(value as WidgetTheme['mode']))}
            start={icon}
            end={<Radio value={value} />}
            id={value}
          />
        ))}
      </ul>
    </RadioRoot>
  );
};
