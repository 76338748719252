import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileDetails, ProfileAccount, ProfileSummary } from '../types';
import { getProfileDetails, getProfileSummary } from '../api/Profile';
import { useWallets } from '@rango-dev/widget-embedded';
import { store } from './Store';
import {
  getLoginMessage,
  getMergeAccountMessage,
  logIn,
  logOut,
  mergeAccount,
} from '../api/Profile';
import { getErrorMessage, throwIfAborted, UserUnauthorizedError } from '../utils/Errors';
import axios from 'axios';
import { shouldIgnoreError } from './helpers';

type ProfileAuth = { numberOfRetries: number; accountMerged: boolean } & (
  | {
      loading: true;
      error: null;
      account: ProfileAccount;
      accountMerged: false;
    }
  | { loading: false; error: string | null; account: null }
);

export type ProfileState = {
  isLoggedIn: boolean;
  profileAuth: ProfileAuth;
  profileSummary: {
    loading: boolean;
    error: string | null;
    data: ProfileSummary | null;
  };
  profileDetails: {
    loading: boolean;
    error: string | null;
    data: ProfileDetails | null;
  };
};

export const profileSliceInitialState: ProfileState = {
  isLoggedIn: false,
  profileAuth: {
    loading: false,
    error: null,
    account: null,
    numberOfRetries: 0,
    accountMerged: false,
  },
  profileSummary: { loading: false, error: null, data: null },
  profileDetails: { loading: false, error: null, data: null },
};

type SignMessageParams = {
  account: ProfileAccount;
  getSigners: ReturnType<typeof useWallets>['getSigners'];
};

export const singMessage = createAsyncThunk<
  ProfileSummary | null,
  SignMessageParams,
  { state: ReturnType<typeof store.getState>; rejectValue: string }
>('profile/singMessage', async (params, { rejectWithValue, getState, dispatch, signal }) => {
  const { account, getSigners } = params;
  const {
    meta: { blockchains },
    profile: { profileAuth, isLoggedIn },
  } = getState();
  const { numberOfRetries } = profileAuth;
  throwIfAborted(signal);
  const signer = await getSigners(account.walletType);
  const blockchainMeta = blockchains.find((blockchain) => blockchain.name === account.blockchain);
  if (!blockchainMeta) {
    return rejectWithValue('Something went wrong. Please try again.');
  }
  const requestSignMessage = !isLoggedIn ? getLoginMessage : getMergeAccountMessage;
  try {
    throwIfAborted(signal);
    const { message, messageId } = await requestSignMessage(
      {
        address: account.address,
        blockchain: account.blockchain,
      },
      signal,
    );
    throwIfAborted(signal);
    const signature = await signer
      .getSigner(blockchainMeta.type)
      .signMessage(message, account.address, blockchainMeta.chainId);

    const requestAuth = !isLoggedIn ? logIn : mergeAccount;
    throwIfAborted(signal);
    const { profile } = await requestAuth({ messageId, signature }, signal);
    return profile;
  } catch (error) {
    if (signal.aborted || axios.isCancel(error)) {
      return null;
    }

    const shouldLogOutAndRetry =
      !isLoggedIn && error instanceof UserUnauthorizedError && numberOfRetries === 0;

    if (shouldLogOutAndRetry) {
      await logOut(signal);
      // Use a timeout to dispatch signMessage after incrementing the numberOfRetries.
      setTimeout(() => dispatch(singMessage(params)), 0);
      return null;
    } else {
      return rejectWithValue(getErrorMessage(error));
    }
  }
});

export const fetchProfileSummary = createAsyncThunk<
  ProfileSummary | null,
  undefined,
  { state: ReturnType<typeof store.getState>; rejectValue: string }
>('profile/fetchProfileSummary', async (_, { rejectWithValue, signal }) => {
  try {
    const profileSummary = await getProfileSummary(signal);
    return profileSummary.profile;
  } catch (error) {
    if (shouldIgnoreError(error)) {
      return null;
    }
    return rejectWithValue(getErrorMessage(error));
  }
});

export const fetchProfileDetails = createAsyncThunk<
  ProfileDetails | null,
  undefined,
  { state: ReturnType<typeof store.getState>; rejectValue: string }
>('profile/fetchProfileDetails', async (_, { rejectWithValue, signal }) => {
  try {
    const profileDetails = await getProfileDetails(signal);
    return profileDetails.profile;
  } catch (error) {
    if (shouldIgnoreError(error)) {
      return null;
    }
    return rejectWithValue(getErrorMessage(error));
  }
});

export const profileSlice = createSlice({
  name: 'profile',
  initialState: profileSliceInitialState,
  reducers: {
    setProfileSummary: (state, action: PayloadAction<ProfileSummary | null>) => {
      state.profileSummary.data = action.payload;
    },
    logInUser: (state) => {
      state.isLoggedIn = true;
    },
    logOutUser: (state) => {
      const { profileAuth, profileSummary, profileDetails } = profileSliceInitialState;
      state.isLoggedIn = false;
      state.profileAuth = profileAuth;
      state.profileSummary = profileSummary;
      state.profileDetails = profileDetails;
    },
    clearAuthError: (state) => {
      state.profileAuth.error = null;
    },
    resetMergeStatus: (state) => {
      state.profileAuth.accountMerged = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(singMessage.pending, (state, action) => {
        state.profileAuth.loading = true;
        state.profileAuth.error = null;
        state.profileAuth.account = action.meta.arg.account;
        state.profileAuth.numberOfRetries = 0;
      })
      .addCase(singMessage.rejected, (state, action) => {
        state.profileAuth.loading = false;
        state.profileAuth.account = null;
        state.profileAuth.numberOfRetries = 0;
        state.profileAuth.error = action.payload ?? null;
      })
      .addCase(singMessage.fulfilled, (state, action) => {
        state.profileAuth.loading = false;
        state.profileAuth.error = null;
        state.profileAuth.account = null;
        if (action.payload) {
          if (state.isLoggedIn) {
            state.profileAuth.accountMerged = true;
          } else {
            state.isLoggedIn = true;
          }
          state.profileSummary.data = action.payload;
          state.profileAuth.numberOfRetries = 0;
        } else {
          state.profileAuth.numberOfRetries += 1;
        }
      })
      .addCase(fetchProfileSummary.pending, (state) => {
        state.profileSummary.loading = true;
        state.profileSummary.error = null;
      })
      .addCase(fetchProfileSummary.fulfilled, (state, action) => {
        state.profileSummary.loading = false;
        state.profileSummary.error = null;
        state.profileSummary.data = action.payload;
      })
      .addCase(fetchProfileSummary.rejected, (state, action) => {
        state.profileSummary.loading = false;
        state.profileSummary.error = action.payload ?? null;
      })
      .addCase(fetchProfileDetails.pending, (state) => {
        state.profileDetails.loading = true;
        state.profileDetails.error = null;
      })
      .addCase(fetchProfileDetails.fulfilled, (state, action) => {
        state.profileDetails.loading = false;
        state.profileDetails.error = null;
        state.profileDetails.data = action.payload;
      })
      .addCase(fetchProfileDetails.rejected, (state, action) => {
        state.profileDetails.loading = false;
        state.profileDetails.error = action.payload ?? null;
      });
  },
});

export const { logInUser, logOutUser, setProfileSummary, clearAuthError, resetMergeStatus } =
  profileSlice.actions;

export const ProfileReducer = profileSlice.reducer;
