import { Divider, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { Container } from './ProfileUserDataEmptyState.styles';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

export function ProfileUserDataEmptyState(props: { error: boolean }) {
  const { t } = useTranslation();
  const { isDarkMode } = useAppSelector((state) => state.settings);
  const { error } = props;
  const { isMobile } = useWindowDimensions();
  const lightImage = error
    ? '/imgs/common/no-data-light.svg'
    : '/imgs/common/empty-transactions-state-light.svg';
  const darkImage = error
    ? '/imgs/common/no-data-dark.svg'
    : '/imgs/common/empty-transactions-state-dark.svg';

  return (
    <Container>
      <img
        src={isDarkMode ? darkImage : lightImage}
        alt="No data found."
        width={isMobile ? 213 : 285}
        height={isMobile ? 174 : 228}
      />
      <Divider size={20} />

      {error ? (
        <>
          <Typography variant="title" size={isMobile ? 'xmedium' : 'medium'} color="$error500">
            {t('An unexpected error occurred')}
          </Typography>
          <Divider size={isMobile ? 10 : 4} />
          <Typography variant="body" size="medium" className="text-description">
            {t('Please try refreshing the page')}
          </Typography>
        </>
      ) : (
        <Typography variant="body" size="medium" className="text-description">
          {t('There is no data here right now. Please swap first to see the details.')}
        </Typography>
      )}
    </Container>
  );
}
