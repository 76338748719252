import { darkTheme, styled } from '@rango-dev/ui';

export const StyledSvg = styled('svg', {
  fill: 'none',
  stroke: '$secondary',
  [`.${darkTheme} &`]: {
    stroke: '$secondary250',
  },
  variants: {
    disabled: {
      true: {
        stroke: '$neutral700',
        [`.${darkTheme} &`]: {
          stroke: '$neutral900',
        },
      },
    },
  },
});
