import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Blockchain, Wallet, WalletType } from '../utils/Constants';
import { getLogo, getUsdPrice, WalletBalance } from '../utils/Blockchains';
import { TokenMeta } from '../api/models/MetaModels';

// Define a type for the slice state
export type RawAccounts = {
  blockchains: { name: string; accounts: { address: string; walletType: WalletType }[] }[];
} | null;

type WalletState = {
  rawAccounts: RawAccounts;
  wallet: Wallet | null;
};

type SetAccountBalanceAction = {
  blockchain: string;
  accountAddress: string;
  walletType: WalletType;
  balances?: WalletBalance[] | null;
  loading: boolean;
  error: boolean;
  explorerUrl: string | null;
  isConnected: boolean;
};

// Define the initial state using that type
const initialState: WalletState = {
  rawAccounts: null,
  wallet: null,
};

function updateAccountsVersion(state: WalletState) {
  const wallet = state.wallet;
  const rawAccounts: RawAccounts =
    !!wallet && wallet.blockchains.length > 0
      ? {
          blockchains: wallet.blockchains.map((b) => ({
            name: b.name,
            accounts: b.accounts.map((a) => ({
              address: a.address,
              walletType: a.walletType,
            })),
          })),
        }
      : null;

  if (JSON.stringify(state.rawAccounts) !== JSON.stringify(rawAccounts))
    state.rawAccounts = rawAccounts;
}

function addBlockchain(state: WalletState, action: Blockchain) {
  const accounts = action?.accounts?.length > 0 ? [action.accounts[0]] : [];
  const newAction = { ...action, accounts };
  const existingBlockchain = state.wallet?.blockchains.find((b) => b.name === action.name) || null;
  if (existingBlockchain === null) state.wallet?.blockchains.push(newAction);
  else {
    // only change state if accounts changed
    const existingAccountsAddresses = existingBlockchain.accounts.map((a) => a.address).sort();
    const newAccountsAddresses = accounts.map((a) => a.address).sort();
    const existingWalletType = existingBlockchain.accounts
      .map((a) => a.walletType)
      .find(() => true);
    const updatedWalletType = accounts.map((a) => a.walletType).find(() => true);
    const equals = (a: string[], b: string[]) => JSON.stringify(a) === JSON.stringify(b);
    const isAccountsEqualsAndWalletTypsDiffrent =
      equals(existingAccountsAddresses, newAccountsAddresses) &&
      existingWalletType !== updatedWalletType;
    if (
      !equals(existingAccountsAddresses, newAccountsAddresses) ||
      isAccountsEqualsAndWalletTypsDiffrent
    ) {
      // remove all previous records for this wallet type
      existingBlockchain.accounts = existingBlockchain.accounts.filter(
        (it: { address: string; walletType: WalletType }) => it.walletType !== updatedWalletType,
      );
      accounts.forEach((a) => {
        existingBlockchain.accounts.push(a);
      });
    } else {
      // changing accounts connection status
      for (const account of existingBlockchain.accounts) {
        const relatedAccount = accounts.filter((a) => a.address === account.address);
        if (relatedAccount && relatedAccount.length === 1) {
          if (account.isConnected !== relatedAccount[0].isConnected) {
            account.isConnected = relatedAccount[0].isConnected;
          }
        }
      }
    }
  }
}

export const walletSlice = createSlice({
  name: 'wallet',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setWallet: (state, action: PayloadAction<Wallet | null>) => {
      state.wallet = action.payload;
      updateAccountsVersion(state);
    },
    setAccountBalance: (state, action: PayloadAction<SetAccountBalanceAction>) => {
      const { blockchain, accountAddress, balances, loading, error, explorerUrl, walletType } =
        action.payload;
      const b = state.wallet?.blockchains?.find((b) => b.name === blockchain) || null;
      if (b === null) return;
      const account =
        b.accounts?.find((a) => a.address === accountAddress && a.walletType === walletType) ||
        null;
      if (account === null) return;
      if (typeof balances !== 'undefined') account.balances = balances;
      account.loading = loading;
      account.error = error;
      account.explorerUrl = explorerUrl;
      updateAccountsVersion(state);
    },
    addBlockchains: (state, action: PayloadAction<Blockchain[]>) => {
      if (state.wallet === null) {
        state.wallet = { blockchains: [] };
      }
      for (let i = 0; i < action.payload.length; i++) {
        const b = action.payload[i];
        addBlockchain(state, b);
      }
      updateAccountsVersion(state);
    },
    updateLogosAndUsdPrices: (state, action: PayloadAction<{ allTokens: TokenMeta[] }>) => {
      state.wallet?.blockchains?.forEach((b) =>
        b?.accounts?.forEach((a) => {
          a?.balances?.forEach((balance) => {
            if (balance.logo === null) {
              balance.logo = getLogo(
                b.name,
                balance.ticker,
                balance.address,
                action.payload.allTokens,
              );
              balance.usdPrice = getUsdPrice(
                b.name,
                balance.symbol,
                balance.address,
                action.payload.allTokens,
              );
            }
          });
        }),
      );
    },
    disconnectWallet: (state, data: PayloadAction<{ walletType: WalletType }>) => {
      if (state.wallet !== null) {
        state.wallet.blockchains?.forEach(
          (b) => (b.accounts = b.accounts.filter((a) => a.walletType !== data.payload.walletType)),
        );
        state.wallet.blockchains = state.wallet.blockchains.filter((b) => b.accounts.length > 0);
        if (state.wallet.blockchains.length === 0) state.wallet = null;
      }
      updateAccountsVersion(state);
    },
  },
});

export const {
  setWallet,
  setAccountBalance,
  addBlockchains,
  updateLogosAndUsdPrices,
  disconnectWallet,
} = walletSlice.actions;

export const WalletReducer = walletSlice.reducer;
