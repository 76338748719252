import { useAppSelector } from '../reduxHooks';
import { ProfileAccount } from '../../types';
import {
  WALLETS_WITHOUT_SIGN_MESSAGE_METHOD,
  BLOCKCHAINS_THAT_SUPPORT_PROFILE,
} from './useProfile.constants';
import { Params, UseProfile } from './useProfile.types';
import { hashAccount } from './useProfile.helpers';

export function useProfile(params?: Params): UseProfile {
  const connectedWallets = useAppSelector((state) => state.wallet.wallet);

  const hashedAddedAccounts = new Set<string>(params?.addedAccounts?.map(hashAccount));

  const compatibleAccounts: ProfileAccount[] | undefined = connectedWallets?.blockchains.flatMap(
    ({ name: blockchain, accounts }) =>
      BLOCKCHAINS_THAT_SUPPORT_PROFILE.includes(blockchain)
        ? accounts
            .filter(
              ({ address, walletType }) =>
                !WALLETS_WITHOUT_SIGN_MESSAGE_METHOD.includes(walletType) &&
                !hashedAddedAccounts.has(hashAccount({ blockchain, address })),
            )
            .map(({ address, walletType }) => ({ address, blockchain, walletType }))
        : [],
  );

  return {
    compatibleAccounts: compatibleAccounts ?? [],
    readyToSign: !!compatibleAccounts?.length,
  };
}
