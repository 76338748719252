import { styled } from '@rango-dev/ui';

export const ProgressCardContainer = styled('div', {
  width: '100%',
  background: '$background',
  padding: '$20',
  borderRadius: '0 0 $primary $primary',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const ProfileSummaryItemsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$10',
});

export const InfoContainer = styled('div', { alignSelf: 'start' });
