import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { IconButton, TooltipPropTypes, Tooltip as UiTooltip } from '@rango-dev/ui';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { hasTouchScreen } from '../../../utils/generalFunctions/common';

export function Tooltip(props: PropsWithChildren<TooltipPropTypes>) {
  const isTouchDevice = hasTouchScreen();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  useClickOutside(buttonRef, () => setOpen(false));

  const handleClickTrigger = () => setOpen((prev) => !prev);

  useEffect(() => {
    const handler = () => {
      setOpen(false);
    };
    window.addEventListener('touchmove', handler);

    return () => window.removeEventListener('touchmove', handler);
  }, []);

  if (isTouchDevice) {
    return (
      // eslint-disable-next-line jsx-id-attribute-enforcement/missing-ids
      <IconButton variant="ghost" ref={buttonRef} onClick={handleClickTrigger}>
        <UiTooltip {...props} align="center" sideOffset={8} open={open} />
      </IconButton>
    );
  }

  return <UiTooltip {...props} />;
}
