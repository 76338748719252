import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import * as Sentry from '@sentry/browser';
import { init, Level } from '@rango-dev/logging-subscriber';
import { layer as consoleLayer } from '@rango-dev/logging-console';
import { layer as sentryLayer } from '@rango-dev/logging-sentry';

import { store } from './state/Store';
import Bootstrap from './Bootstrap';
import './data/i18n';
import { SENTRY_DSN } from './constants/sentry';
import { IS_DEV } from './constants/api';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Note: Some of properties like release and app version is setting from `vite.config`
Sentry.init({ dsn: SENTRY_DSN, normalizeDepth: 6 });

if (IS_DEV) {
  init([consoleLayer()], {
    baseLevel: Level.Info,
  });
} else {
  init([sentryLayer(Sentry)], {
    baseLevel: Level.Warn,
  });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <StrictMode>
    <Provider store={store}>
      <Bootstrap />
    </Provider>
  </StrictMode>,
);
