import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, StyledButton } from './ShareProgress.styles';
import { PropTypes } from './ShareProgress.types';
import { ShareProgressModal } from './ShareProgressModal';
export function ShareProgress(props: PropTypes): ReactElement {
  const { t } = useTranslation();
  const [showShareProgressModal, setShowShareProgressModal] = useState(false);
  return (
    <Container>
      <StyledButton
        id="profile-page-summary-share-progress-btn"
        className="share-profile"
        variant="contained"
        type="secondary"
        fullWidth
        size="medium"
        onClick={() => setShowShareProgressModal(true)}
        disabled={props.loading}>
        {t('Share Progress on Social Media')}
      </StyledButton>
      <ShareProgressModal
        onClose={() => setShowShareProgressModal(false)}
        open={showShareProgressModal}
      />
    </Container>
  );
}
