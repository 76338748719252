import { WalletBalance } from './Blockchains';
import { BigNumber } from 'bignumber.js';
import {
  Networks,
  WalletTypes,
  PendingSwapNetworkStatus,
  WalletEvents,
  MainEvents,
  StepEventType,
  StepExecutionEventStatus,
  EventSeverity,
} from '@rango-dev/widget-embedded';
import type {
  WalletType,
  WalletInfo,
  WalletState,
  PendingSwap,
  PendingSwapStep,
  PendingSwapWithQueueID,
  ConnectedWallet,
  RouteExecutionEvents,
  Route,
  Step,
} from '@rango-dev/widget-embedded';
import { BlockchainMeta } from '../api/models/MetaModels';
import { ExperimentalFeature, ExperimentalFeatureName } from './types';
import i18n from '../data/i18n';
import { DEFAULT_API_KEY, DEFAULT_BASE_URL } from '../constants/api';
import { getExperimentalFeature } from './generalFunctions/getExperimentals';
export {
  Networks,
  WalletTypes,
  PendingSwapNetworkStatus,
  WalletEvents,
  MainEvents,
  StepEventType,
  StepExecutionEventStatus,
  EventSeverity,
};
export type {
  WalletType,
  WalletInfo,
  WalletState,
  PendingSwap,
  PendingSwapStep,
  PendingSwapWithQueueID,
  ConnectedWallet,
  RouteExecutionEvents,
  Route,
  Step,
};

export const AFFILIATE_REF = 'affiliateRef';
export const ZERO = new BigNumber(0);
export type NetworkWithFilters = Networks | 'OTHER' | 'ALL';

export const ALL_BLOCKCHAINS = 'ALL';
export const OTHER_BLOCKCHAINS = 'OTHER';

export const getBlockchainFullName = (
  name: NetworkWithFilters,
  allBlockchains: BlockchainMeta[],
): string | null => {
  if (name === 'ALL' || name === 'OTHER') {
    return null;
  }
  return allBlockchains.find((chain) => chain.name === name)?.displayName || null;
};

export const getBlockchainDisplayName = (
  name: Networks | 'ALL' | 'OTHER',
  allBlockchains: BlockchainMeta[],
): string => {
  if (name === ALL_BLOCKCHAINS || name === OTHER_BLOCKCHAINS) return name;

  return allBlockchains.find((chain) => chain.name === name)?.shortName || name;
};

export const getBlockchainColor = (
  name: Networks | 'ALL' | 'OTHER',
  allBlockchains: BlockchainMeta[],
): string => {
  if (name === ALL_BLOCKCHAINS) return '#2299dd';
  if (name === OTHER_BLOCKCHAINS) return '#000';
  return allBlockchains.find((chain) => chain.name === name)?.color || '#000';
};

export const getBlockchainLogo = (name: string, allBlockchains: BlockchainMeta[]): string => {
  return allBlockchains.find((chain) => chain.name === name)?.logo || '/imgs/coins/unknown.png';
};

const NATIVE = 'Native';

export const getTokenNetworkDesc = (
  blockchain: string,
  symbol: string,
  allBlockchains: BlockchainMeta[],
): string => {
  if (
    ([Networks.BTC, Networks.LTC, Networks.BCH, Networks.THORCHAIN] as string[]).includes(
      blockchain,
    )
  )
    return NATIVE;
  else if (blockchain === Networks.ETHEREUM) return symbol === 'ETH' ? NATIVE : `ERC20`;
  else if (blockchain === Networks.BINANCE) return 'Binance Chain';
  else if (blockchain === Networks.BSC) return 'BSC';

  return allBlockchains.find((chain) => chain.name === blockchain)?.displayName || 'Unsupported';
};

export const getConciseAddress = (address: string | null, maxChars = 6): string | null => {
  if (address === undefined || address === null) return null;
  if (address.length < 2 * maxChars + 3) return address;
  return address.substr(0, maxChars) + '...' + address.substr(address.length - maxChars);
};
export type Account = {
  balances: WalletBalance[] | null;
  address: string;
  loading: boolean;
  walletType: WalletType;
  error: boolean;
  explorerUrl: string | null;
  isConnected: boolean;
};
export type Blockchain = { name: string; accounts: Account[] };
export type Wallet = { blockchains: Blockchain[] };

export const getConnectedWalletTypes = (wallet: Wallet | null): Set<string> =>
  new Set(
    wallet?.blockchains?.flatMap((b) => b.accounts.map((a) => a.walletType.toString())) || [],
  );

export const EXPERIMENTAL_FEATURES = 'experimentalFeatures';
export const EXPERIMENTAL_FEATURES_LIST: ExperimentalFeature[] = [
  {
    name: ExperimentalFeatureName.BASE_URL,
    displayName: i18n.t('Base URL'),
    type: 'text-field',
    availableOn: ['next'],
    value: DEFAULT_BASE_URL,
    needsReloadTheApp: true,
  },
  {
    name: ExperimentalFeatureName.API_KEY,
    displayName: i18n.t('Api Key'),
    type: 'text-field',
    availableOn: ['next'],
    value: DEFAULT_API_KEY,
    needsReloadTheApp: true,
  },
  {
    name: ExperimentalFeatureName.AFFILIATE_PERCENT,
    displayName: i18n.t('Affiliate Percent'),
    type: 'text-field',
    availableOn: ['next'],
    value: '',
    needsReloadTheApp: true,
  },
  {
    name: ExperimentalFeatureName.AFFILIATE_TOKEN,
    displayName: i18n.t('Affiliate Token'),
    type: 'text-field',
    availableOn: ['next'],
    value: '',
    needsReloadTheApp: true,
  },
  {
    name: ExperimentalFeatureName.MAX_LENGTH,
    displayName: i18n.t('Max Length'),
    type: 'text-field',
    availableOn: ['next'],
    value: '',
    needsReloadTheApp: true,
  },
  {
    name: ExperimentalFeatureName.BEST_ROUTE,
    displayName: i18n.t('Best Route'),
    type: 'switch',
    availableOn: ['next'],
    enable: false,
  },
  {
    name: ExperimentalFeatureName.AVOID_NATIVE_FEE,
    displayName: i18n.t('Avoid Native Fee'),
    type: 'switch',
    availableOn: ['next'],
    enable: false,
  },
  {
    name: ExperimentalFeatureName.CENTRALIZED_SWAPPERS,
    displayName: i18n.t('Centralized Swappers'),
    type: 'switch',
    availableOn: ['next'],
    enable: true,
  },
];

export const BASE_URL =
  getExperimentalFeature(ExperimentalFeatureName.BASE_URL) || DEFAULT_BASE_URL;
export const API_KEY = getExperimentalFeature(ExperimentalFeatureName.API_KEY) || DEFAULT_API_KEY;
export const RANGO_DAPP_ID_QUERY = `apiKey=${API_KEY}`;
