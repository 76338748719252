import { StyledSvg } from './StepSeparator.styles';
import { PropTypes } from './StepSeparator.types';
export function StepSeparator(props: PropTypes) {
  const { disabled } = props;
  return (
    <StyledSvg
      width="101"
      height="3"
      viewBox="0 0 101 3"
      xmlns="http://www.w3.org/2000/svg"
      disabled={disabled}>
      <line
        x1="1"
        y1="1.25"
        x2="100"
        y2="1.25"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="0.1 8"
      />
    </StyledSvg>
  );
}
