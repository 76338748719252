import { Typography } from '@rango-dev/ui';

import { Footer, SettingSection, Step } from './Settings.styles';
import { SettingItemProps } from './Settings.types';

export const SettingsItem = (props: SettingItemProps) => {
  const { onClick, icon, showValue, value, title, name, id } = props;
  return (
    <SettingSection id={id} onClick={() => onClick(name)}>
      <Step>
        {icon}
        <Footer showValue={!!showValue}>
          <Typography variant="title" size="small" className="capitalize">
            {showValue ? value : title}
          </Typography>
          {showValue && (
            <Typography variant="label" size="medium" className="_title capitalize">
              {title}
            </Typography>
          )}
        </Footer>
      </Step>
    </SettingSection>
  );
};
