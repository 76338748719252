import { Networks } from '../utils/Constants';
import { WalletDetailItemType } from '../components/WalletDetails/WalletDetails.types';
import { sortedBlockchainSelector } from '../state/MetaSlice';
import { useAppSelector } from './reduxHooks';

/**
 * useWalletDetailsItem generate detailed wallet items for each blockchain.
 *
 * This hook fetches the wallet data from the Redux store, sorts the blockchains
 * according to a predefined order, and creates an array of wallet detail items.
 * Each wallet detail item corresponds to a unique address within a blockchain
 * and contains information such as wallet type and account details.
 */

export const useWalletDetailsItem = () => {
  const wallet = useAppSelector((state) => state.wallet.wallet);
  const originalBlockchains = wallet?.blockchains || [];
  const sortedBlockchain = useAppSelector(sortedBlockchainSelector);
  const sortedBlockchainNames = sortedBlockchain.map((blockchain) => blockchain.name);

  const walletDetailItems: WalletDetailItemType[] = originalBlockchains
    .slice()
    ?.sort((a, b) => sortedBlockchainNames.indexOf(a.name) - sortedBlockchainNames.indexOf(b.name))
    .flatMap((blockchainItem) => {
      const { accounts, name } = blockchainItem;
      const uniqueAddress = Array.from(new Set(accounts.map((accountItem) => accountItem.address)));

      const result: WalletDetailItemType[] = [];

      // making wallet detail item per each unique address
      uniqueAddress.forEach((addressItem) => {
        const validAccounts = accounts.filter((accountItem) => accountItem.address === addressItem);
        if (validAccounts.length) {
          const walletType = validAccounts.map((accountItem) => accountItem.walletType);
          const account = validAccounts[0];

          const walletDetailItem: WalletDetailItemType = {
            walletType,
            account,
            name: name as Networks,
          };

          result.push(walletDetailItem);
        }
      });

      return result;
    });

  return { walletDetailItems };
};
