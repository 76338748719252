import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RangoStatus } from '../api/models/StatusResponse';

export type StatusState = {
  status: RangoStatus | null;
};

const initialState: StatusState = { status: null };

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    loadStatus: (state, action: PayloadAction<RangoStatus>) => {
      state.status = action.payload;
    },
  },
});

export const { loadStatus } = statusSlice.actions;

export const StatusReducer = statusSlice.reducer;
