import React from 'react';
import { useAppSelector } from '../hooks/reduxHooks';
import { Analytics } from './Analytics';

const ServerStatus = (): JSX.Element => {
  const status = useAppSelector((state) => state.status.status);

  if (!status) return <React.Fragment />;

  return (
    <div className="hidden w-full justify-center 2xl:flex">
      <div className="flex w-128 flex-col md:left-2 md:top-0 md:mb-0 xl:absolute xl:w-80">
        <Analytics />
      </div>
    </div>
  );
};

export default ServerStatus;
