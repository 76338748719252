import { darkTheme, styled, Typography } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  textAlign: 'left',
});

export const Description = styled(Typography, {
  [`.${darkTheme} &`]: {
    color: '$neutral900',
  },
});
