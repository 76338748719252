import { setAccountBalance } from "../../state/WalletSlice";
import { getLogo } from "../../utils/Blockchains";
import { Dispatch } from '@reduxjs/toolkit';
import { TokenMeta } from '../../api/models/MetaModels';
import type { Wallet, ConnectedWallet, WalletType } from '../../utils/Constants';


export const setAccountBalanceForRequestedWallets = (
  result: ConnectedWallet[],
  wallet: Wallet | null,
  connectedWalletsTypes: string[],
  requestedWallets: WalletType[],
  allTokens: TokenMeta[],
  dispatch: Dispatch,
) =>
  result.forEach((w) => {
    const walletAcccountsWithSameBlockchainAndSameAddress = wallet?.blockchains
      .find((blockchain) => blockchain.name === w.chain)
      ?.accounts.filter((account) => account.address === w.address);
    walletAcccountsWithSameBlockchainAndSameAddress?.forEach((account) => {
      if (
        //get wallet details for one wallet
        (requestedWallets.length === 1 &&
          w.address === account.address &&
          connectedWalletsTypes.indexOf(requestedWallets[0]) >=
            connectedWalletsTypes.indexOf(account.walletType)) ||
        //reload all wallets
        requestedWallets.length > 1
      ) {
        dispatch(
          setAccountBalance({
            blockchain: w.chain,
            accountAddress: w.address,
            walletType: account.walletType,
            balances:
              w.balances?.map((b) => ({
                chain: w.chain,
                symbol: b.symbol,
                ticker: b.ticker,
                address: b.address || null,
                rawAmount: b.rawAmount,
                decimal: b.decimal,
                amount: b.amount,
                logo: getLogo(w.chain, b.symbol, b.address, allTokens),
                usdPrice: b.usdPrice,
              })) || [],
            loading: w.loading,
            error: w.error,
            explorerUrl: w.explorerUrl,
            isConnected: true,
          }),
        );
      }
    });
  });
