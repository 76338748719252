import { styled } from '@rango-dev/ui';

export const Container = styled('div', {
  borderRadius: '$primary',
  background: '$background',
  display: 'flex',
  flexDirection: 'column',
  '& .summary-content': {
    padding: '$30 35px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    gap: '$25',
    '@sm': {
      flexDirection: 'column',
      alignItems: 'center',
    },
    '@lg': {
      flexDirection: 'row',
      alignItems: 'start',
    },
  },
  '& .summary-items': {
    flex: 1,
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 387px))',
    '@sm': {
      display: 'grid',
      gap: '$20',
      paddingTop: '$5',
    },
    '@md': {
      gridTemplateColumns: 'repeat(auto-fill,minmax(300px, 387px))',
    },
    '@lg': {
      paddingTop: '39px',
      gridTemplateColumns: 'repeat(auto-fill,minmax(300px, 1fr))',
    },
    '@media (min-width:1920px)': {
      gridTemplateColumns: '1fr 1fr',
    },
  },
});
