import { ReactElement } from 'react';
import { ProfileSummaryItem } from '../ProfileSummaryItem/ProfileSummaryItem';
import { useTranslation } from 'react-i18next';
import {
  getIconLocation,
  getItems,
  getItemsWithEffectiveValue,
} from './ProfileSummaryItems.helpers';
import { SummaryItems } from './ProfileSummaryItems.types';
import { ProfileSummaryItemWithEffectiveValue } from '../ProfileSummaryItemWithEffectiveValue';
import { useAppSelector } from '../../../hooks/reduxHooks';

export function ProfileSummaryItems(): ReactElement {
  const { data: profileSummary, loading } = useAppSelector((state) => state.profile.profileSummary);
  const { t } = useTranslation();

  const items = profileSummary ? getItems(profileSummary) : null;

  const itemsWithEffectiveValue = profileSummary
    ? getItemsWithEffectiveValue(profileSummary)
    : null;

  const summaryItems: {
    [key in keyof SummaryItems]: { icon: string; title: string };
  } = {
    interactedBlockchains: {
      icon: 'interacted-chains.svg',
      title: t('Interacted Chains'),
    },
    referralVolume: {
      icon: 'referral-volume.svg',
      title: t('Referral Volume'),
    },
    invitedFriends: {
      icon: 'invited-friends.svg',
      title: t('Invited Friends'),
    },
    referralIncome: {
      icon: 'referral-income.svg',
      title: t('Referral Income'),
    },
  };

  const summaryItemsWithEffectiveValue = {
    volume: {
      icon: 'total-volume.svg',
      total: {
        title: t('Total Volume'),
        description: t(
          'Total Volume represents cumulative volume of your transactions across all chains supported by Rango.',
        ),
      },
      effective: {
        title: t('Effective Volume'),
        description: t(
          'Effective Volume represents your transaction volume across the chains listed in profile and can effect on your score.',
        ),
      },
    },
    txCount: {
      icon: 'tx-count.svg',
      total: {
        title: t('Transaction Count'),
        description: t(
          "Transaction Count reflects the total count of swaps & bridges you've executed across all chains supported by Rango.",
        ),
      },
      effective: {
        title: t('Effective Transaction Count'),
        description: t(
          'Effective Transaction Count represents the total number of transactions across the chains listed in your profile and can effect on your score.',
        ),
      },
    },
  };

  return (
    <>
      {Object.keys(summaryItemsWithEffectiveValue).map((key, index) => {
        const itemKey = key as keyof typeof summaryItemsWithEffectiveValue;
        const item = summaryItemsWithEffectiveValue[itemKey];
        return (
          <ProfileSummaryItemWithEffectiveValue
            key={index}
            icon={getIconLocation(item.icon)}
            {...(loading || !itemsWithEffectiveValue
              ? { loading: true, total: item.total, effective: item.effective }
              : {
                  loading: false,
                  total: { ...item.total, value: itemsWithEffectiveValue[itemKey].total },
                  effective: {
                    ...item.effective,
                    value: itemsWithEffectiveValue[itemKey].effective,
                  },
                })}
          />
        );
      })}
      {Object.keys(summaryItems).map((key, index) => {
        const itemKey = key as keyof typeof summaryItems;
        const item = summaryItems[itemKey];
        return (
          <ProfileSummaryItem
            key={index}
            icon={getIconLocation(item.icon)}
            title={item.title}
            {...(loading || !items ? { loading: true } : { value: items[itemKey], loading: false })}
          />
        );
      })}
    </>
  );
}
