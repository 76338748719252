import { WalletTypes } from '../utils/Constants';
import { useEffect, useRef } from 'react';
import { MetaState } from '../state/MetaSlice';
import { useWallets } from '@rango-dev/widget-embedded';

type ForceAutoConnectParams = {
  isActiveTab: boolean;
  loadingStatus: MetaState['loadingStatus'];
  walletType: string | null;
};

export function useForceAutoConnect(params: ForceAutoConnectParams): void {
  const { isActiveTab, loadingStatus, walletType } = params;
  const { connect, state } = useWallets();
  const initiated = useRef(false);

  const wallet = Object.values(WalletTypes).find((w) => w.toLocaleLowerCase() === walletType);
  const walletState = wallet ? state(wallet) : null;

  useEffect(() => {
    const shouldTryConnect =
      isActiveTab &&
      loadingStatus === 'success' &&
      walletState &&
      walletType &&
      walletState.installed &&
      !walletState.connecting &&
      !walletState.connected;

    if (shouldTryConnect && !initiated.current) {
      initiated.current = true;
      connect(walletType)
        .then()
        .catch((error: any) => {
          console.error(error);
        });
    }
  }, [walletState, isActiveTab, loadingStatus, walletType]);
}
