import { ReactElement } from 'react';
import { PropTypes } from './ProfileWalletsModal.types';
import { Divider, Typography } from '@rango-dev/ui';
import { useTranslation } from 'react-i18next';
import { modalStyles } from './ProfileWalletsModal.styles';
import { Modal } from '../../Modal';
import { Description } from '../LogIn/LogIn.styles';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { ProfileWallets } from '../ProfileWallets';
import { ConnectedWallets } from '../ProfileWallets/ConnectedWallets';

export function ProfileWalletsModal(props: PropTypes): ReactElement {
  const { open, onClose, accounts } = props;
  const isUserLoggedIn = useAppSelector((state) => state.profile.isLoggedIn);
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();

  return (
    <Modal
      open={open}
      onClose={onClose}
      dismissible
      anchor={isMobile ? 'bottom' : 'center'}
      styles={modalStyles}>
      <>
        <Typography
          {...(isMobile
            ? { variant: 'title', size: 'medium' }
            : { variant: 'headline', size: 'small' })}>
          {isUserLoggedIn ? t('Your Profile Wallets') : t('Login with wallet')}
        </Typography>
        <Description variant="body" size={isMobile ? 'small' : 'medium'}>
          {isUserLoggedIn
            ? t('Link your wallets to your profile to increase your score.')
            : t('Use your wallet to login into your profile.')}
        </Description>
        <Divider size="24" direction="vertical" />
        {isUserLoggedIn ? (
          <ProfileWallets accounts={accounts} />
        ) : (
          <ConnectedWallets accounts={accounts} />
        )}
      </>
    </Modal>
  );
}
