import {
  AddEthereumChainParameter,
  convertEvmBlockchainMetaToEvmChainInfo,
} from '@rango-dev/wallets-shared';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { BlockchainMeta, SwapperMeta, TokenMeta } from '../api/models/MetaModels';
import { isEvmBlockchain } from 'rango-sdk';
import { RootState } from './RootReducer';

export type MetaState = {
  loadingStatus: 'loading' | 'success' | 'failed';
  tokens: TokenMeta[];
  blockchains: BlockchainMeta[];
  swappers: SwapperMeta[];
};

const initialState: MetaState = {
  tokens: [],
  blockchains: [],
  swappers: [],
  loadingStatus: 'loading',
};

export const metaSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setMeta: (state, action: PayloadAction<MetaState>) => {
      state.blockchains = action.payload.blockchains;
      state.tokens = action.payload.tokens;
      state.swappers = action.payload.swappers;
      state.loadingStatus = action.payload.loadingStatus;
    },
  },
});

export const MetaReducer = metaSlice.reducer;
export const { setMeta } = metaSlice.actions;

export type EvmNetworksChainInfo = { [key: string]: AddEthereumChainParameter };

const allBlockchainsSelector = (state: RootState) => state.meta.blockchains;

export const evmBasedChainsSelector = createSelector(
  allBlockchainsSelector,
  (blockchains: BlockchainMeta[]) => blockchains.filter(isEvmBlockchain),
);

export const evmBasedChainsNamesSelector = createSelector(
  allBlockchainsSelector,
  (blockchains: BlockchainMeta[]) =>
    blockchains.filter(isEvmBlockchain).map((blockchainMeta) => blockchainMeta.name),
);

export const sortedBlockchainSelector = createSelector(
  allBlockchainsSelector,
  (blockchains: BlockchainMeta[]) => [...blockchains].sort((a, b) => a.sort - b.sort),
);

export const evmNetworksChainInfoSelector = createSelector(
  evmBasedChainsSelector,
  convertEvmBlockchainMetaToEvmChainInfo,
);
