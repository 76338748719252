import { RANGO_COOKIE_HEADER } from '../constants/api';
import { BASE_URL, RANGO_DAPP_ID_QUERY } from '../utils/Constants';
import { getCookieId } from '../utils/swap/SwapUtility';
import { RangoStatus } from './models/StatusResponse';

export const getServerStatus = async (): Promise<RangoStatus | null> => {
  const response = await fetch(`${BASE_URL}/status?${RANGO_DAPP_ID_QUERY}`, {
    mode: 'cors',
    method: 'GET',
    headers: {
      'content-type': 'application/json;charset=UTF-8',
      [RANGO_COOKIE_HEADER]: getCookieId(),
    },
  });
  const text = await response.text();
  return !!text ? JSON.parse(text) : null;
};
