import { useEffect, useRef, useState } from 'react';
import { getCurrentBreakpoint } from '../styles/themeUtility';

export const useWindowDimensions = () => {
  const prevBreakpoint = useRef(getCurrentBreakpoint());
  const [isMobile, setIsMobile] = useState(getCurrentBreakpoint() === 'sm');
  const [isBreakpointChanged, setIsBreakpointChanged] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const currentBreakpoint = getCurrentBreakpoint();
      setIsMobile(currentBreakpoint === 'sm');
      setIsBreakpointChanged(prevBreakpoint.current !== currentBreakpoint);
      prevBreakpoint.current = currentBreakpoint;
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, isBreakpointChanged]);

  return { isMobile, isBreakpointChanged };
};
