import { CopyIcon, Divider, DoneIcon, IconButton, Tooltip, Typography } from '@rango-dev/ui';
import {
  CopyIconButtonContainer,
  ShareProgressContentStyle,
  ShareProgressItem,
} from './ShareProgress.styles';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { getProgressData, getSocialProgressText } from './ProfileProgress.helpers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getContainer } from '../../../utils/generalFunctions/common';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

export function ShareProgressContent() {
  const profileSummary = useAppSelector((state) => state.profile.profileSummary.data);
  const progressData = getProgressData(profileSummary);

  const userLink = `https://app.rango.exchange`;
  const [copied, setCopied] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensions();

  function copyToClipboard() {
    const socialText = getSocialProgressText(progressData) + userLink;
    if (!copied) {
      navigator.clipboard.writeText(socialText);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }
  }

  return (
    <ShareProgressContentStyle>
      <Typography className="user-link" variant="body" size="medium">
        {userLink}
      </Typography>
      <Typography variant="body" size="medium">
        Visit my profile @RangoExchange
      </Typography>
      <Divider size={'20'} />

      {!progressData.loading &&
        progressData.items.map((dataItem) => {
          const { title, value } = dataItem;
          return (
            <ShareProgressItem key={title}>
              <Divider direction="horizontal" size={4} />
              {value && (
                <Typography variant="body" size="medium">
                  {`${title}: ${value}`}
                </Typography>
              )}
            </ShareProgressItem>
          );
        })}

      <div className="footer-content">
        <Typography className="rango-hashtag" variant="body" size="medium">
          #rango #rango_exchange {isMobile && <br />} @RangoExchange
        </Typography>
        <CopyIconButtonContainer>
          {copied ? (
            <Tooltip
              content={t('Copied to clipboard')}
              container={getContainer()}
              sideOffset={8}
              side="bottom">
              <IconButton type="secondary" id="profile-share-progress-modal-copy-icon-btn">
                <DoneIcon color="white" size={24} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              content={t('Copy to clipboard')}
              container={getContainer()}
              sideOffset={8}
              side="bottom">
              <IconButton
                type="secondary"
                id="profile-share-progress-modal-copy-icon-btn"
                onClick={copyToClipboard}>
                <CopyIcon color="white" size={24} />
              </IconButton>
            </Tooltip>
          )}
        </CopyIconButtonContainer>
      </div>
    </ShareProgressContentStyle>
  );
}
