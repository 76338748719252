import { styled } from '@rango-dev/ui';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$10',
  width: '100%',
  maxWidth: '283px',
  '@sm': {
    maxWidth: '387px',
    gap: '$15',
  },
});

export const Description = styled('div', {
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'start',
});

export const Content = styled('div', {
  flex: 1,
});
