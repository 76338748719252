import { Checkbox, Divider, Image, ListItemButton, Typography } from '@rango-dev/ui';
import { CheckList, FilterItemContainer } from './WalletDetailsFilter.styles';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import {
  getBlockchainDisplayName,
  getBlockchainFullName,
  getBlockchainLogo,
} from '../../../utils/Constants';
import { containsText } from '../../../utils/generalFunctions/common';
import { FilterProps } from './WalletDetailsFilter.types';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useWalletDetails } from '../../../hooks/useWalletDetails';
import { WalletDetailFilterHeader } from './WalletDetailFilterHeader';
import { WalletDetailFilterAction } from './WalletDetailFilterAction';

export function BlockchainFilter(props: FilterProps) {
  const { onClose } = props;
  const { t } = useTranslation();
  const [searchedFor, setSearchedFor] = useState<string>('');
  const { toggleExcludedAllBlockchain, handleExcludedBlockchain, validBlockchains } =
    useWalletDetails();
  const allBlockchains = useAppSelector((state) => state.meta.blockchains);
  const { excludedBlockchains } = useAppSelector((state) => state.walletDetails);

  const blockchainList = useMemo(() => {
    return validBlockchains.map((blockchainItem) => {
      const logo = getBlockchainLogo(blockchainItem, allBlockchains);
      const displayName = getBlockchainDisplayName(blockchainItem, allBlockchains);
      const fullName = getBlockchainFullName(blockchainItem, allBlockchains);
      return { logo, displayName, name: blockchainItem, fullName };
    });
  }, [validBlockchains]);

  let filteredList = blockchainList;
  if (searchedFor) {
    filteredList = blockchainList.filter(
      (blockchainItem) =>
        containsText(blockchainItem.name, searchedFor) ||
        containsText(blockchainItem.displayName, searchedFor) ||
        (blockchainItem.fullName && containsText(blockchainItem.fullName, searchedFor)),
    );
  }

  const items = filteredList.map((blockchainItem) => {
    const { name, logo, displayName } = blockchainItem;
    return {
      start: <Image src={logo} size={20} type="circular" />,
      end: <Checkbox checked={!excludedBlockchains?.includes(name)} />,
      onClick: () => handleExcludedBlockchain(name),
      title: (
        <Typography variant="body" size="medium">
          {displayName}
        </Typography>
      ),
      id: name,
    };
  });

  return (
    <FilterItemContainer>
      <WalletDetailFilterHeader
        title={t('Your Chains')}
        handleToggleAll={toggleExcludedAllBlockchain}
        excludedItems={excludedBlockchains}
        searchHandler={setSearchedFor}
        searchedFor={searchedFor}
      />
      <Divider size="10" />
      <div className="filter-body">
        <CheckList>
          {items.map((item) => {
            return <ListItemButton key={item.id} {...item} />;
          })}
        </CheckList>

        <WalletDetailFilterAction onClose={onClose} />
      </div>
    </FilterItemContainer>
  );
}
